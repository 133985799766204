import RouteIndex from "./allRoutes/indexRoute";
import { OrganizationProvider } from "./context/OrganizationContext";
import { ResponseProvider } from "./context/ResponseContext";
import { UserProvider } from "./context/UserContext";

const App = () => {
  return (
    <>

      <OrganizationProvider>
        <UserProvider>
          <ResponseProvider>
          <RouteIndex />
          </ResponseProvider>
        </UserProvider>
      </OrganizationProvider>

    </>
  );
};

export default App;
