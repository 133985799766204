import { useState } from "react";
import InputForm from "../../../components/InputForm/InputForm";
import { Image } from "../../../components/Image/Image";
import eye from "../../../Images/eye.svg";
import closeeye from "../../../Images/eyeclose.svg";

import { orgAdminUploader } from "../../../services/uploaderApi";
import { showNotification } from "../../../components/Toastify/Toast";

const CreateOrganisation = ({
  organization,
  errors,
  isEditMode,
  handleOrganisationChange,
  showPassword,
  handleShowPassword,
}) => {
  const [preview, setPreview] = useState(null);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const files = event.dataTransfer.files;
    if (files.length && files[0].type.startsWith("image/")) {
      handleFile(files[0]);
    } else {
      alert("Please drop a valid image file.");
    }
  };

  const handleFileInput = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      handleFile(file);
    } else {
      alert("Please select a valid image file.");
    }
  };
  const handleFieldChange = (field, value) => {
    handleOrganisationChange(field, value);

    if (field === "firstName" || field === "lastName") {
      const updatedContactName = `${
        field === "firstName" ? value : organization.firstName || ""
      } ${field === "lastName" ? value : organization.lastName || ""}`.trim();
      handleOrganisationChange("contactName", updatedContactName);
    }
  };
  const handleFile = async (file) => {
    try {
      const res = await orgAdminUploader(organization?._id, file);
      if (res.status === "Success") {
        showNotification("uploaded successfully", "success");
      }
    } catch (e) {
      showNotification("Error Occurred", "error");
    }
  };
  const showError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="text-red-500 text-[14px] pl-8 pt-2">{errors[keyName]}</p>
    ) : null;
  };

  const printLabel = (text) => {
    return (
      <>
        <span>{text}</span>
        <span className="text-red-500">*</span>
      </>
    );
  };
  return (
    <>
      <div className="py-[5px]">
        <InputForm
          value={organization.registeredName || ""}
          handleChange={(e) =>
            handleOrganisationChange("registeredName", e.target.value)
          }
          label={printLabel("Organisation Name")}
          placeholder="Enter Organization Name"
          isValid={true}
        />
        {showError("registeredName")}
      </div>
      {!isEditMode && (
        <>
          <div className="py-[5px]">
            <InputForm
              value={organization.firstName || ""}
              handleChange={(e) =>
                handleFieldChange("firstName", e.target.value)
              }
              label={printLabel("First Name")}
              placeholder="Enter First Name"
              isValid={true}
            />
            {showError("firstName")}
          </div>
          <div className="py-[5px]">
            <InputForm
              value={organization.lastName || ""}
              handleChange={(e) =>
                handleFieldChange("lastName", e.target.value)
              }
              label={printLabel("Last Name")}
              placeholder="Enter Last Name"
              isValid={true}
            />
            {showError("lastName")}
          </div>
          <div className="py-[5px]">
            <InputForm
              value={organization.contactName || ""}
              handleChange={(e) =>
                handleOrganisationChange("contactName", e.target.value)
              }
              label={printLabel("Contact Name")}
              placeholder="Enter Contact Name"
              isValid={true}
            />
            {showError("contactName")}
          </div>
          <div className="py-[5px]">
            <InputForm
              value={organization.contactEmail || ""}
              handleChange={(e) =>
                handleOrganisationChange("contactEmail", e.target.value)
              }
              label={printLabel("Contact Email")}
              placeholder="Enter Contact Email"
              isValid={true}
            />
            {showError("contactEmail")}
          </div>
        </>
      )}
      <div className="py-[5px]">
        <InputForm
          value={organization.address || ""}
          handleChange={(e) =>
            handleOrganisationChange("address", e.target.value)
          }
          label={printLabel("Address")}
          placeholder="Enter Addresss"
          isValid={true}
        />
        {showError("address")}
      </div>
      <div className="py-[5px]">
        <InputForm
          value={organization.city || ""}
          handleChange={(e) => handleOrganisationChange("city", e.target.value)}
          label={printLabel("City")}
          placeholder="Enter city"
          isValid={true}
        />
        {showError("city")}
      </div>
      <div className="py-[5px]">
        <InputForm
          value={organization.country || ""}
          handleChange={(e) =>
            handleOrganisationChange("country", e.target.value)
          }
          label={printLabel("Country")}
          placeholder="Enter country"
          isValid={true}
        />
        {showError("country")}
      </div>
      <div className="py-[5px]">
        <InputForm
          value={organization.organizationCode || ""}
          handleChange={(e) =>
            handleOrganisationChange("organizationCode", e.target.value)
          }
          label={printLabel("Organization Code")}
          placeholder="Enter Organization Code"
          isValid={true}
        />
        {showError("organizationCode")}
      </div>
      <div className="py-[5px]">
        <InputForm
          value={organization.taxId || ""}
          handleChange={(e) =>
            handleOrganisationChange("taxId", e.target.value)
          }
          label={printLabel("Tax Id")}
          placeholder="Enter Tax Id"
          isValid={true}
        />
        {showError("taxId")}
      </div>
      <p className="block mx-8 mt-2 text-sm font-medium text-gray-700">
        {printLabel("Contact Phone")}
      </p>
      <div className="flex flex-row gap-4 px-8">
        <div style={{ width: "120px", display: "flex", alignItems: "center" }}>
          <div className="flex items-center h-10 border rounded-lg focus:outline-none focus:ring-1">
            <span className="text-gray-700 pointer-events-none text-[18px] ml-1 mr-1">
              +
            </span>

            <input
              value={`${
                organization.countryCode
                  ? organization.countryCode.replace("+", "")
                  : ""
              }`}
              onChange={(e) =>
                handleOrganisationChange("countryCode", e.target.value)
              }
              placeholder="Code"
              isValid={true}
              maxLength={4}
              className="w-[80%] py-1 text-gray-700 placeholder-gray-400 outline-none"
            />
          </div>
        </div>

        <InputForm
          value={organization.contactPhone || ""}
          handleChange={(e) =>
            handleOrganisationChange("contactPhone", e.target.value)
          }
          placeholder="Enter Phone Number"
          isValid={true}
        />
      </div>
      {showError("countryCode")}
      {showError("contactPhone")}
      {!isEditMode && (
        <>
          <div className="py-[5px]">
            <InputForm
              value={organization.employeeId || ""}
              handleChange={(e) =>
                handleOrganisationChange("employeeId", e.target.value)
              }
              label={printLabel("Employee Id")}
              placeholder="Enter Employee Id"
              isValid={true}
            />
            {showError("employeeId")}
          </div>
          <div className="py-[5px]">
            <InputForm
              value={organization.username || ""}
              handleChange={(e) =>
                handleOrganisationChange("username", e.target.value)
              }
              label={printLabel("Username")}
              placeholder="Enter Username"
              isValid={true}
            />
            {showError("username")}
          </div>

          <div className="py-[5px]">
            <div style={{ position: "relative" }}>
              <InputForm
                value={organization.password || ""}
                handleChange={(e) =>
                  handleOrganisationChange("password", e.target.value)
                }
                label={printLabel("Password")}
                placeholder="Enter Password"
                isValid={true}
                type={showPassword ? "text" : "password"}
              />
              <div
                style={{
                  position: "absolute",
                  right: "40px",
                  marginTop: "13px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => handleShowPassword()}
              >
                <img
                  src={showPassword ? eye : closeeye}
                  alt="toggle visibility"
                />
              </div>
            </div>
            {showError("password")}
          </div>
        </>
      )}
      {isEditMode && (
        <div className="flex flex-col items-center justify-center w-[85%] p-4 my-4 mx-auto text-center transition border-2 border-gray-400 border-dashed cursor-pointer hover:bg-gray-50">
          {/* Preview Image */}
          {preview ? (
            <>
              <div
                style={{
                  position: "relative",
                }}
              >
                <img
                  src={preview}
                  alt="Preview"
                  className="h-auto mt-4 border rounded-lg shadow w-80"
                />
                <span
                  className="text-[18px]"
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "10px",
                  }}
                  onClick={() => setPreview("")}
                >
                  x
                </span>
              </div>
            </>
          ) : (
            <>
              {/* Drag-and-Drop Zone */}
              <div onDragOver={handleDragOver} onDrop={handleDrop}>
                Drag and drop an image here
              </div>
              <p className="mt-4">or</p>
              {/* File Input */}
              <label className="inline-block px-4 py-2 mt-2 text-white bg-gray-500 rounded cursor-pointer hover:bg-gray-600">
                Choose an image
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileInput}
                />
              </label>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CreateOrganisation;
