export const HOME_PAGE_TITLE = "Home";
export const CASELIST_TITLE = "Caselist";
export const SETTINGS_TITLE = "Settings";
export const CONTACT_US_TITLE = "Contact Us";
export const CASES_TITLE = "Cases";
export const RESET_PASSWORD_TITLE = "Reset Password";
export const FORGOT_PASSWORD_TITLE = "Forgot Password";
export const SIGNIN_TITLE = "Sign In";
export const CASE_UPLOAD_TITLE = "Case Upload";

export const ADMIN_ORG_LIST_TITLE = "Organizations";

export const ADMIN_SIGNIN_TITLE = "Admin Sign In";
export const ADMIN_RESET_PASSWORD_TITLE = "Admin Reset Password";
export const ADMIN_FORGOT_PASSWORD_TITLE = "Admin Forgot Password";
export const ADMIN_ORG_DETAIL_TITLE = "Admin Organization Detail";
