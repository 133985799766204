import React, { useState } from "react";
import Icon from "../Icon/Icon";
import { createDisputeCaseSignedRequest } from "../../services/coreService";
import { showNotification } from "../Toastify/Toast";

const Timeline = ({ events = [], caseId, organizationId }) => {
    const [signedUrls, setSignedUrls] = useState({});

    const downloadSignedUrl = async (documentUrl, doc) => {
        try {
            const response = await createDisputeCaseSignedRequest(
                documentUrl,
                caseId,
                organizationId
            );
            if (response.status === "Success") {
                const signedUrl = response.signed_url;
                fetch(`${signedUrl}`)
                    .then((response) => response.blob())
                    .then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = doc?.documentName;
                        a.click();
                    });
                setSignedUrls((prev) => ({ ...prev, [doc.documentUrl]: signedUrl }));
            }
            return null;
        } catch (error) {
            console.error("Error fetching signed URL:", error);
            showNotification("Error fetching signed URL", "error");
            return null;
        }
    };

    const handleLinkClick = async (e, doc) => {

        e.preventDefault();
        await downloadSignedUrl(doc.documentUrl, doc);

    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="flex flex-col mt-[16px] mb-10">
            {events?.map((event, index) => (
                <div key={index} className="flex space-x-4">
                    <div className="flex flex-col items-center">
                        <Icon type="EllipseIcon" className="w-[9px] h-[9px]" />
                        {index < events.length - 1 && (
                            <div className="w-[1px] h-[100%]  bg-[#4976F4]"></div>
                        )}
                    </div>

                    <div className="flex flex-col pb-[16px]">
                        <p className="text-[#AFAFAF] text-xs font-normal leading-[1.4] mb-[2px]">
                            {formatDate(event.timelineDate)}
                        </p>

                        <p style={{fontWeight : 1000}} className="text-black font-inter font-bold text-base font-medium leading-[1.5]">
                            {event.title}
                        </p>

                        <p className="text-black text-base font-normal leading-[1.5]">
                            {event.description}
                        </p>

                        {event?.docs?.map((doc, index) => (
                            <div key={index} className="flex items-center space-x-1">
                                <Icon
                                    type="FileTextIcon"
                                    className="h-[16px] w-[16px] text-blue-500"
                                />
                                <a
                                    href={signedUrls[doc.documentUrl] || "#"}
                                    onClick={(e) => handleLinkClick(e, doc)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-[#4976F4] text-sm font-normal leading-[1.5] underline decoration-solid decoration-skip-ink-none"
                                >
                                    {doc.documentName}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Timeline;
