import React, { useState } from "react";
import Label from "../../../components/Label/Label";
import Logo from "../../../Images/mainlogo.png"
import { bannerText } from "../../../constants/bannerConstants";
import Button from "../../../components/Button/Button";
import BannerComponent from "../../../components/Banner/Banner";
import { attemptAdminForgotPasword } from "../../../services/coreService";
import Loader from "../../../components/Loaders/Loader";
import { showNotification } from "../../../components/Toastify/Toast";


const AdminForgotPasswordPage = () => {

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!email) {
            showNotification("Please do enter Email ID", "error");
            return;
        }

        setLoading(true);

        const requestBody = {
            contactEmail: email
        };


        const response = await attemptAdminForgotPasword(requestBody);

        setLoading(false);
        setEmail("");



    }

    return (
        <div className="min-h-screen flex">

            <BannerComponent />

            <div className="w-1/2 bg-[#f5f5f5f5] flex flex-col items-center p-8 ">
                {loading && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <Loader />
                    </div>
                )}
                <div className="flex items-center space-x-4 mt-[62px]">
                    <img
                        src={Logo}
                        alt={bannerText.logoAlt}
                        className="h-[70px] w-full"
                    />


                </div>

                <div className="mt-1 text-center ">
                    <h3 className="text-[28px] font-medium leading-[120%]" style={{ color: 'var(--Color-Tokens-Content-Dark-Primary, #000)' }}>
                        Forgot Password
                    </h3>

                </div>
                <form className="w-full max-w-md mt-6 space-y-4 flex flex-col items-center" onSubmit={handleSubmit}>

                    <div className="w-full">
                        <Label
                            htmlFor="email"
                            className="block text-[14px] font-normal font-[400] leading-[150%]"
                            style={{ color: 'var(--Color-Tokens-Content-Dark-Primary, #000)' }}
                            text="Email Address"
                        />

                        <input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            className="h-[42px] mt-2 w-full p-3 text-[14px] font-normal leading-[150%] text-[#AFAFAF] border border-gray-300 rounded"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>


                    <Button
                        text="Send Reset Link"
                        type="submit"
                        onClick={() => console.log("Signing In")}
                        className="flex items-center justify-center gap-[8px] w-full py-[9px] px-[18px] align-self-stretch border border-[#FFFFFF33] bg-[#000] text-white rounded-[4px] shadow-[0px_10px_12px_0px_rgba(255,255,255,0.26)_inset,0px_0px_0px_1px_#000,0px_0px_0px_4px_rgba(0,0,0,0.04)]"
                    />
                </form>

            </div>
        </div>
    );
}

export default AdminForgotPasswordPage;