import { Divider } from "../../../../components/Divider/Divider";
import { useEffect, useState } from "react";
import Button from "../../../../components/Button/Button";
import { useUser } from "../../../../context/UserContext";
import {
  confirmRemedialActionByAgent,
  createRemedialAction,
  createsubclaim,
  deleteRemedialAction,
  deleteSubclaims,
  escalateCase,
  fetchCaseClaimValidate,
  fetchClaimList,
  fetchMonetaryvalidate,
  fetchremedialactions,
  generateResponse,
  updateRemedialAction,
  updatesubclaiminfo,
} from "../../../../services/coreService";
import { AGENT_ID } from "../../../../constants/enum";
import { showNotification } from "../../../../components/Toastify/Toast";
import storageService from "../../../../services/storageService";
import Loader from "../../../../components/Loaders/Loader";
import SubClaims from "./SubClaims";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch";
import megaphone from "../../../../Images/Megaphone.svg";
import rightarrow from "../../../../Images/RightArrow.svg";
import ClaimResponse from "./ClaimResponse";
import { useResponse } from "../../../../context/ResponseContext";
import { getCaseInfo } from "../../../../services/coreService";
import { DISPUTE_CASE_STATUS_RESPONSE_GENERATION_COMPLETE } from "../../../../constants/disputeCaseStatusConstants";
import { useTabContext } from "../../../../components/Tabs/TabContext";
import EditRemedialAction from "./EditRemedialAction";
import EditMonetaryValue from "./EditMonetaryValue";
import EditValidity from "./EditValidity";
import EditClaim from "./EditClaim";
import EscalateDisputeCase from "./EscalateDisputeCase";

const Claims = ({
  resetCaseResponseState,
  fetchCaseResponse,
  caseId,
  organizationId,
  setRemedialData,
  caseStatus,
  isAIResponseGenerated,
  setIsAIResponseGeneratedLoader,
  setIsRegenerateLoader,
}) => {
  const { agent } = useUser();
  const [isEditModalSaveLoader, setEditModalSaveLoaderState] = useState(false);
  const [isremedialmodal, setRemedialModal] = useState(false);
  const [iseditvaliditymodal, setEditValidityModal] = useState(false);
  const [issubclaimmodal, setSubClaimModal] = useState(false);
  const [iseditmonetarymodal, setEditMonetaryModal] = useState(false);
  const [isescalatemodal, setEscalateModal] = useState(false);
  const [isshowclaim, setIsShowClaim] = useState(true);
  const [editclaim, seteditclaim] = useState(true);
  const [caseid, setCaseId] = useState({});
  const [claimlist, setClaimList] = useState([]);
  const [caseresponse, setCaseResponse] = useState([]);
  const [remedialtitle, setRemedialTitle] = useState("");
  const [remedialjustification, setRemedialJustification] = useState("");
  const [editremedialaction, setEditRemedialAction] = useState([]);
  const [selectvalidity, setSelectedvalidity] = useState("");
  const [validityChangeReason, setValidityChangeReason] = useState("");
  const [selectedEscalateCase, setSelectedEscalateCase] = useState("");
  const [monetarytitle, setMonetaryTitle] = useState("");
  const [monetaryeditreason, setMonetaryEditReason] = useState("");
  const [subclaimtitle, setSubClaimTitle] = useState("");
  const [editClaimDetails, setEditClaimDetails] = useState({});
  const [editmonetaryDetails, setEditMonetaryDetails] = useState({});
  const [remedialactionlist, setRemedialActionList] = useState([]);
  const [remedialactionDetails, setRemedialActionDetails] = useState({});
  const [caseValidateState, setCaseValidateState] = useState({});
  const [monetaryValueState, setMonetaryValueState] = useState({});
  const [remedialValueState, setRemediaValueState] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(
    claimlist?.map((_, index) => index) || []
  );
  const [remedialclaimid, setremedialclaimid] = useState(null);
  const [claimcaseid, setClaimCaseId] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [count, setCount] = useState(0);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [escalatejustification, setEscalateJustification] = useState("");
  const [escalateloading, setEscalateLoading] = useState(false);
  const [dummyclaimid, setdummyclaimid] = useState("");
  const [additionalRemediallist, setAdditionalRemedialList] = useState([]);
  const [decisionValue, setDecisionValue] = useState(0);
  const {
    isConfirmedByAgentForDecision,
    updateAiresponseGenerated,
    updateConfirmedByAgentForDecision,
    updateGlobalCaseStatus,
    updateTotalMonetaryValue,
    totalmonetaryValue,
    globalCaseStatus,
    fetchTotalMonetaryForAgentConfirm,
  } = useResponse();

  const PAGE_SIZE = 50;
  const agentId = storageService.getItem(AGENT_ID);
  const totalPages = Math.ceil(count / PAGE_SIZE);

  const [isuserconfirmationloader, setIsuserConfirmationLoader] = useState({});
  const [isremovesubclaimloder, setIsRemoveSubclaimLoader] = useState(false);
  const [isremoveremedialloder, setIsRemoveRemedialLoader] = useState(false);
  const [iscreateremedialloader, setCreateRemedialLoader] = useState(false);
  const [iscreatesubclaimloader, setCreatesubclaimLoader] = useState(false);

  const { changeTab } = useTabContext();

  useEffect(() => {
    fetchClaims();
    handleGetTotalMonetaryValue();
    if (caseId) {
      fetchRemedialActions(caseId);
    }
  }, [organizationId, caseId, caseStatus, pageNum]);

  useEffect(() => {
    if (claimlist?.length > 0) {
      setSelectedClaim(claimlist.map((_, index) => index));
    }
  }, [claimlist]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.innerHeight + document.documentElement.scrollTop;
      const bottomPosition = document.documentElement.offsetHeight;

      if (
        scrollPosition >= bottomPosition - 50 &&
        !isFetchingMore &&
        claimlist.length < count &&
        pageNum < totalPages
      ) {
        loadMoreCaseClaim();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetchingMore, claimlist.length, count]);

  const handlRemedialModalOpen = (action) => {
    if (action) {
      setRemedialTitle(action?.title || "");
      setRemedialJustification(action?.description || "");
      setRemedialActionDetails(action);
    } else {
      setRemedialTitle("");
      setRemedialJustification("");
    }
    setRemedialModal(true);
  };

  const handlcreateRemedialModalOpen = (id) => {
    setremedialclaimid(id);
    setRemedialActionDetails(null);
    setRemedialModal(true);
  };

  const handleEsclateModalOpen = () => {
    setEscalateModal(true);
  };

  const handleEsclateModalClose = () => {
    setEscalateModal(false);
  };

  const handlRemedialModalClose = () => {
    setRemedialActionDetails({});
    setRemedialTitle("");
    setRemedialJustification("");
    setRemedialModal(false);
  };

  const handleSubClaimModalOpen = (claim) => {
    setEditClaimDetails(claim);
    setSubClaimTitle(claim?.title || "");
    setSubClaimModal(true);
  };

  const handlSubClaimModalClose = () => {
    setEditClaimDetails({});
    setSubClaimModal(false);
  };

  const handlEditMonetaryModalOpen = (claim) => {
    setEditMonetaryDetails(claim);
    setMonetaryTitle(
      claim?.editMonetaryValue
        ? claim?.editMonetaryValue
        : claim?.monetaryValue || 0
    );
    setMonetaryEditReason(
      claim?.editMonetaryValueReason
        ? claim?.editMonetaryValueReason
        : claim?.monetaryValueReason || ""
    );
    setEditMonetaryModal(true);
  };

  const handlEditMonetaryModalClose = () => {
    setEditMonetaryDetails({});
    setEditMonetaryModal(false);
  };

  const getClaimValidityValue = (claim) => {
    return claim?.editValidity
      ? claim.editValidity
      : claim?.validity
        ? claim?.validity
        : "Invalid";
  };

  const handlEditValidityModalOpen = (claim) => {
    setEditMonetaryDetails(claim);
    setSelectedvalidity(
      getClaimValidityValue(claim) === "Valid" ? "validate" : "invalidate"
    );
    setValidityChangeReason(
      claim?.editValidityText
        ? claim?.editValidityText
        : claim?.validityText || ""
    );
    setEditValidityModal(true);
  };

  const handlEditValidityModalClose = () => {
    setEditValidityModal(false);
  };

  const handleshowClaim = (index) => {
    if (selectedClaim.some((item) => item === index)) {
      setSelectedClaim(selectedClaim.filter((item) => item !== index));
    } else {
      let array = [...selectedClaim];
      array.push(index);
      setSelectedClaim(array);
    }
  };

  const decisionColor = (val) => {
    if (val === "make_offer") {
      return "#A8DEB4";
    } else if (val === "submit_defence") {
      return "#AF4B4B";
    }
  };

  const handleRemedialValidation = (action) => {
    let isValid = true;
    if (!!!action.title || action.title === "") {
      showNotification("Please Enter Title", "error");
      isValid = false;
    } else if (!!!action.description || action.description === "") {
      showNotification("Please Enter Justification", "error");
      isValid = false;
    }

    return isValid;
  };

  const handleEditMonetaryValueValidation = (action) => {
    let isValid = true;
    if (!!!action.monetaryValue || action.monetaryValue === "") {
      showNotification("Please Enter Value", "error");
      isValid = false;
    } else if (!!!action.editReason || action.editReason === "") {
      showNotification("Please Enter Justification", "error");
      isValid = false;
    }

    return isValid;
  };

  const handleSubClaimValidation = (action) => {
    let isValid = true;
    if (!!!action.subclaimtitle || action.subclaimtitle === "") {
      showNotification("Please Enter Title", "error");
      isValid = false;
    }

    return isValid;
  };

  const handleCaseClaimValidate = async (claimId) => {
    setIsuserConfirmationLoader((prevState) => ({
      ...prevState,
      [claimId]: { ...prevState[claimId], isValidityConfirmedByAgent: true },
    }));
    let newValidate = { action: "toggle_validity_confirmation" };
    let response = await fetchValidate(claimId, newValidate);
    if (response?.status === "Success") {
      let newList = claimlist.map((item) => {
        if (item._id === response.data.caseClaim._id) {
          return response.data.caseClaim;
        }
        return item;
      });
      setClaimList(newList);
      const { message } = response.data;

      await fetchTotalMonetaryForAgentConfirm(organizationId, caseId);
      showNotification(message, "success");
      setIsuserConfirmationLoader((prevState) => ({
        ...prevState,
        [claimId]: { ...prevState[claimId], isValidityConfirmedByAgent: false },
      }));
    }
  };

  const handleMonetaryConformation = async (claim, claimId, isChecked) => {
    setIsuserConfirmationLoader((prevState) => ({
      ...prevState,
      [claimId]: {
        ...prevState[claimId],
        isMonetaryValidityConfirmedByAgent: true,
      },
    }));
    let response;
    if (isChecked) {
      let newValidate = { action: "toggle_money_confirmation" };
      response = await fetchMonetaryValidate(claimId, newValidate);
    } else {
      let newValidate = { action: "validate" };
      response = await fetchMonetaryValidate(claimId, newValidate);
    }
    if (response.status === "Success") {
      let newList = claimlist.map((item) => {
        if (item._id === response.data.caseClaim._id) {
          return response.data.caseClaim;
        }
        return item;
      });
      setClaimList(newList);

      await fetchTotalMonetaryForAgentConfirm(organizationId, caseId);
    }
    setIsuserConfirmationLoader((prevState) => ({
      ...prevState,
      [claimId]: {
        ...prevState[claimId],
        isMonetaryValidityConfirmedByAgent: false,
      },
    }));
  };

  const handleRemedialConformation = async (claim, claimId) => {
    setIsuserConfirmationLoader((prevState) => ({
      ...prevState,
      [claimId]: {
        ...prevState[claimId],
        isRemedialActionConfirmedByAgent: true,
      },
    }));
    let response = await confirmRemedialActionByAgent(organizationId, claimId);

    if (response.status === "Success") {
      let newList = claimlist.map((item) => {
        if (item._id === response.data.caseClaim._id) {
          return response.data.caseClaim;
        }
        return item;
      });
      setClaimList(newList);
      await fetchTotalMonetaryForAgentConfirm(organizationId, caseId);
    }
    setIsuserConfirmationLoader((prevState) => ({
      ...prevState,
      [claimId]: {
        ...prevState[claimId],
        isRemedialActionConfirmedByAgent: false,
      },
    }));
  };

  const fetchClaims = async () => {
    const payload = {
      pageNum: pageNum,
      pageSize: PAGE_SIZE,
      filters: {},
    };
    setLoading(true);
    try {
      setIsFetchingMore(true);

      const res = await fetchClaimList(organizationId, caseId, payload);

      if (res?.status === "Success") {
        const { caseClaims, totalCount } = res.data;
        setCount(totalCount);
        let newList = [...claimlist, ...caseClaims];
        let claims = [];
        let index = {};
        newList.forEach((item) => {
          if (!index[item._id]) {
            claims.push(item);
            index[item._id] = 1;
          }
        });
        setClaimList(claims);
        setdummyclaimid(claims[0]?._id);
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch case list:", error);
    } finally {
      setIsFetchingMore(false);
    }
  };

  const loadMoreCaseClaim = () => {
    if (claimlist.length < count) {
      setPageNum((prevPageNum) => prevPageNum + 1);
    }
  };

  const fetchRemedialActions = async (caseId) => {
    try {
      const res = await fetchremedialactions(organizationId, caseId);
      if (res?.status === "Success") {
        const { remedialAction } = res.data;
        setRemedialData(remedialAction);

        const groupedActions = remedialAction.reduce(
          (acc, action) => {
            const caseClaimId = action.caseClaimId;

            if (!acc.remedialActionList[caseClaimId]) {
              acc.remedialActionList[caseClaimId] = [];
            }
            acc.remedialActionList[caseClaimId].push(action);

            if (action.remedialActionType === "additional") {
              acc.additionalRemedialList.push(action);
            }

            return acc;
          },
          { remedialActionList: {}, additionalRemedialList: [] }
        );

        setRemedialActionList((prev) => ({
          ...prev,
          ...groupedActions.remedialActionList,
        }));
        setAdditionalRemedialList(groupedActions.additionalRemedialList);
        setClaimCaseId(caseId);
      }
    } catch (error) {
      showNotification("error", "Error fetching remedial actions");
    }
  };

  const handleClaimValidate = async (
    claimId,
    validate,
    validityChangeReason
  ) => {
    if (validityChangeReason) {
      setEditModalSaveLoaderState(true);
      const payload = {
        action: validate,
        editValidityText: validityChangeReason,
      };
      const res = await fetchCaseClaimValidate(
        organizationId,
        claimId,
        payload
      );
      setEditModalSaveLoaderState(false);
      if (res?.status === "Success") {
        let newList = claimlist.map((item) => {
          if (item._id === res.data.caseClaim._id) {
            return res.data.caseClaim;
          }
          return item;
        });
        setClaimList(newList);
        const { message } = res.data;
        handlEditValidityModalClose();
        showNotification(message, "success");
        await fetchTotalMonetaryForAgentConfirm(organizationId, caseId);
      } else {
        const errorMessage =
          res?.data?.message || "action key can not be empty";
        showNotification(errorMessage, "error");
      }
    } else {
      showNotification("Please provide justification!", "error");
    }
  };

  const fetchMonetaryValidate = async (claimId, validate) => {
    const payload = validate;
    const res = await fetchMonetaryvalidate(organizationId, claimId, payload);
    return res;
  };

  const fetchValidate = async (claimId, validateState) => {
    const payload = {
      ...validateState,
    };
    const res = await fetchCaseClaimValidate(organizationId, claimId, payload);

    return res;
  };

  const createRemedialaction = async (id) => {
    setCreateRemedialLoader(true);
    try {
      const payload = {
        remedialActionType: "claimBased",
        assignedAgent: agentId,
        caseId: claimcaseid,
        title: remedialtitle,
        description: remedialjustification,
        createdBy: agentId,
        isEdited: false,
        editTitle: "",
        editDescription: "",
      };

      if (handleRemedialValidation(payload)) {
        let res;

        if (remedialactionDetails) {
          res = await updateRemedialAction(organizationId, id, payload);
        } else {
          payload.isAiGenerated = false;
          res = await createRemedialAction(organizationId, id, payload);
        }

        if (res?.status === "Success") {
          const { remedialAction } = res.data;

          setEditRemedialAction(remedialAction);
          fetchRemedialActions(claimcaseid);
          handlRemedialModalClose();
          setRemedialTitle("");
          setRemedialJustification("");

          await fetchTotalMonetaryForAgentConfirm(organizationId, caseId);

          let claimId = res?.data?.caseClaim?._id;

          setClaimList((prevClaimsList) =>
            prevClaimsList.map((claim) =>
              claim._id === claimId //give here is check checkIsConfirmedByAgentForDecision?.isRemedialActionConfirmedByAgent
                ? { ...claim, isRemedialActionConfirmedByAgent: false }
                : claim
            )
          );
        }
      }
    } catch (error) {
      showNotification("An error occurred in remedial action", "error");
    }
    setCreateRemedialLoader(false);
  };

  const editMonetaryValue = async (claimId) => {
    try {
      const payload = {
        action: "invalidate",
        monetaryValue: monetarytitle,
        editReason: monetaryeditreason,
      };

      if (handleEditMonetaryValueValidation(payload)) {
        setEditModalSaveLoaderState(true);
        const res = await fetchMonetaryvalidate(
          organizationId,
          claimId,
          payload
        );
        setEditModalSaveLoaderState(false);
        if (res?.status === "Success") {
          let newList = claimlist.map((item) => {
            if (item._id === claimId) {
              return res.data.caseClaim;
            }
            return item;
          });
          setClaimList(newList);
          handleGetTotalMonetaryValue();
          handlEditMonetaryModalClose();
          setMonetaryTitle("");
          setMonetaryEditReason("");

          await fetchTotalMonetaryForAgentConfirm(organizationId, caseId);
        } else {
          showNotification("Failed to edit monetary value");
        }
      }
    } catch (error) {
      showNotification(
        "An error occurred while editing monetary value",
        "error"
      );
    }
  };

  const handleSubClaim = async (claimId) => {
    setCreatesubclaimLoader(true);
    try {
      const action = { subclaimtitle };
      const isValid = handleSubClaimValidation(action);

      if (!isValid) {
        return;
      }
      const payload = editClaimDetails
        ? {
          caseClaim: {
            caseId: caseId,
            title: subclaimtitle,
          },
        }
        : {
          caseClaim: {
            caseId: caseId,
            title: subclaimtitle,
            validity: "Invalid",
            monetaryValue: 0,
          },
        };

      let res;
      if (editClaimDetails) {
        res = await updatesubclaiminfo(organizationId, claimId, payload);
      } else {
        payload.caseClaim.isAiGenerated = false;
        res = await createsubclaim(organizationId, agentId, payload);
      }

      if (res?.status === "Success") {
        handlSubClaimModalClose();
        setSubClaimTitle("");
        if (editClaimDetails) {
          let newList = claimlist.map((item) => {
            if (item._id === claimId) {
              return res.data.caseClaim;
            }
            return item;
          });
          setClaimList(newList);
        } else {
          fetchClaims();
        }

        await fetchTotalMonetaryForAgentConfirm(organizationId, caseId);
      } else {
        showNotification(
          `Failed to ${editClaimDetails ? "edit" : "create"} sub claims`,
          "error"
        );
      }
    } catch (error) {
      showNotification("An error occurred in sub claims", "error");
    }
    setCreatesubclaimLoader(false);
  };

  const removeSubclaim = async (id) => {
    setIsRemoveSubclaimLoader(true);
    try {
      let res = await deleteSubclaims(organizationId, id);
      if (res?.status === "Success") {
        setClaimList((prevClaimList) =>
          prevClaimList.filter((item) => item._id !== id)
        );
        handlSubClaimModalClose();
        fetchRemedialActions(caseId);
        await fetchTotalMonetaryForAgentConfirm(organizationId, caseId);
      }
    } catch (error) {
      showNotification("Failed to remove subclaim", "error");
    }
    setIsRemoveSubclaimLoader(false);
  };

  const removeRemedialaction = async (remedialid, claimId) => {
    setIsRemoveRemedialLoader(true);
    try {
      const response = await deleteRemedialAction(organizationId, remedialid);

      if (response?.status === "Success") {
        setRemedialActionList((prev) => {
          const updatedList = { ...prev };
          Object.keys(updatedList).forEach((key) => {
            updatedList[key] = updatedList[key].filter(
              (action) => action._id !== remedialid
            );
          });
          return updatedList;
        });

        await fetchTotalMonetaryForAgentConfirm(organizationId, caseId);
        handlRemedialModalClose();

        setClaimList((prevClaimsList) =>
          prevClaimsList.map((claim) =>
            claim._id === claimId
              ? { ...claim, isRemedialActionConfirmedByAgent: false }
              : claim
          )
        );
      }
    } catch (error) {
      showNotification("Failed to remove remedial action", "error");
    }
    setIsRemoveRemedialLoader(false);
  };

  const handleEsclateCase = async () => {
    setEscalateLoading(true);

    try {
      const payload = {
        justification: escalatejustification,
        department: selectedEscalateCase,
      };
      let res;
      res = await escalateCase(organizationId, caseId, payload);

      if (res?.status === "Success") {
        showNotification(res?.data?.message, "success");
      }
    } catch (error) {
      showNotification("An error occurred in remedial action", "error");
    }
    setEscalateLoading(false);

    setEscalateJustification("");
    setSelectedEscalateCase("");
    handleEsclateModalClose();
  };

  const exponentialBackoff = async (
    callback,
    delay = 2000,
    constantLimit = 10
  ) => {
    let retries = 0;
    while (true) {
      const response = await callback();
      if (
        response?.disputeCase?.status ===
        DISPUTE_CASE_STATUS_RESPONSE_GENERATION_COMPLETE
      ) {
        return response;
      }

      retries++;
      const waitTime =
        retries <= constantLimit
          ? delay
          : delay * Math.pow(2, retries - constantLimit);
      await new Promise((resolve) => setTimeout(resolve, waitTime));
    }
  };

  const fetchCaseInfo = async () => {
    try {
      const data = await getCaseInfo(organizationId, caseId);
      updateAiresponseGenerated(data?.disputeCase?.isAIResponseGenerated);
      updateGlobalCaseStatus(data?.disputeCase?.status);
      if (
        data.disputeCase.status ===
        DISPUTE_CASE_STATUS_RESPONSE_GENERATION_COMPLETE
      ) {
        fetchCaseResponse();
      }
      return data;
    } catch (error) {
      console.error("Error fetching case info:", error);
    }
  };

  const handleGenerateResponse = async () => {
    setIsAIResponseGeneratedLoader(true);
    resetCaseResponseState();
    setIsRegenerateLoader(true);
    try {
      let res;
      res = await generateResponse(organizationId, caseId);
      if (res?.status === "Success") {
        showNotification("Generate Response Successfully", "success");
        changeTab("Response");
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await delay(5000);
        await exponentialBackoff(fetchCaseInfo);
      }
    } catch (error) {
      showNotification("An error occurred in Generate Response", "error");
    }
    setIsAIResponseGeneratedLoader(false);
  };

  const handleGetTotalMonetaryValue = async () => {
    try {
      await fetchTotalMonetaryForAgentConfirm(organizationId, caseId);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="px-[26px] pt-[16px]">
          <div>
            <span className="text-[22px] ml-4 text-black">Subclaims</span>
            {claimlist?.map((claim, index) => (
              <div data-testid="claimlist">
                <SubClaims
                  key={claim._id}
                  index={index}
                  handleshowClaim={() => handleshowClaim(index)}
                  handlRemedialModalOpen={handlRemedialModalOpen}
                  selectedClaim={selectedClaim}
                  claim={claim}
                  claidId={claim._id}
                  remedialactionlist={remedialactionlist[claim?._id] || []}
                  handleCaseClaimValidate={handleCaseClaimValidate}
                  handleMonetaryConformation={handleMonetaryConformation}
                  handleRemedialConformation={handleRemedialConformation}
                  handlEditMonetaryModalOpen={handlEditMonetaryModalOpen}
                  handlEditValidityModalOpen={handlEditValidityModalOpen}
                  handleSubClaimModalOpen={handleSubClaimModalOpen}
                  caseValidateState={caseValidateState}
                  monetaryValueState={monetaryValueState}
                  selectedIndex={selectedIndex}
                  handlcreateRemedialModalOpen={handlcreateRemedialModalOpen}
                  fetchRemedialActions={fetchRemedialActions}
                  setCaseId={setCaseId}
                  isuserconfirmationloader={isuserconfirmationloader}
                />
              </div>
            ))}
          </div>
          <PermissionSwitch
            shouldHaveRoles={[
              AGENT_ROLE_SUPERADMIN,
              AGENT_ROLE_ADMIN,
              AGENT_ROLE_CONTRIBUTER,
            ]}
            hasRole={agent?.role}
            yes={
              <div
                className="py-6"
                onClick={() => handleSubClaimModalOpen()}
                data-testid="subclaim"
              >
                <span className="text-[18px] ml-4 font-normal cursor-pointer">
                  + Add a Subclaim
                </span>
              </div>
            }
            no={
              <div className="py-6" disabled={true}>
                <span className="text-[18px] ml-4 font-normal cursor-pointer">
                  + Add a Subclaim
                </span>
              </div>
            }
          />

          <Divider />

          {isFetchingMore && (
            <div className="flex justify-center py-4">
              <Loader />
            </div>
          )}

          <div className="mt-6">
            <ClaimResponse
              DecisionColor={decisionColor}
              caseId={caseId}
              organizationId={organizationId}
              agentId={agentId}
              totalmonetaryValue={totalmonetaryValue}
              additionalRemediallist={additionalRemediallist}
              fetchRemedialActions={fetchRemedialActions}
              claimcaseid={claimcaseid}
              setRemedialActionList={setRemedialActionList}
              setAdditionalRemedialList={setAdditionalRemedialList}
              handleGetTotalMonetaryValue={handleGetTotalMonetaryValue}
            />
          </div>

          <div className="flex flex-row justify-end gap-3 mt-8 mb-5">
            <Button
              icon={megaphone}
              className="bg-[#AF4B4B] px-4 py-1 text-white "
              text={"Escalate"}
              onClick={handleEsclateModalOpen}
              righticon={true}
            />

            {isAIResponseGenerated ? (
              <Loader />
            ) : (
              <Button
                icon={rightarrow}
                className={`${isConfirmedByAgentForDecision
                  ? "bg-[#5ccb5c] cursor-pointer"
                  : "bg-[#A8DEB4] pointer-events-none cursor-not-allowed"
                  } px-4 py-2 text-white text-[20px] text-800`}
                text={"Generate Response"}
                onClick={handleGenerateResponse}
                righticon={true}
              />
            )}
          </div>
          <EditRemedialAction
            agent={agent}
            remedialclaimid={remedialclaimid}
            iscreateremedialloader={iscreateremedialloader}
            setRemedialTitle={setRemedialTitle}
            isremedialmodal={isremedialmodal}
            handlRemedialModalClose={handlRemedialModalClose}
            remedialactionDetails={remedialactionDetails}
            remedialtitle={remedialtitle}
            remedialjustification={remedialjustification}
            setRemedialJustification={setRemedialJustification}
            isremoveremedialloder={isremoveremedialloder}
            removeRemedialaction={removeRemedialaction}
            createRemedialaction={createRemedialaction}
          />
          <EditMonetaryValue
            agent={agent}
            iseditmonetarymodal={iseditmonetarymodal}
            handlEditMonetaryModalClose={handlEditMonetaryModalClose}
            monetarytitle={monetarytitle}
            setMonetaryTitle={setMonetaryTitle}
            monetaryeditreason={monetaryeditreason}
            setMonetaryEditReason={setMonetaryEditReason}
            isEditModalSaveLoader={isEditModalSaveLoader}
            editmonetaryDetails={editmonetaryDetails}
            editMonetaryValue={editMonetaryValue}
          />
          <EditValidity
            agent={agent}
            editmonetaryDetails={editmonetaryDetails}
            handleClaimValidate={handleClaimValidate}
            isEditModalSaveLoader={isEditModalSaveLoader}
            setValidityChangeReason={setValidityChangeReason}
            validityChangeReason={validityChangeReason}
            selectvalidity={selectvalidity}
            setSelectedvalidity={setSelectedvalidity}
            iseditvaliditymodal={iseditvaliditymodal}
            handlEditValidityModalClose={handlEditValidityModalClose}
          />
          {issubclaimmodal ? (
            <EditClaim
              agent={agent}
              iscreatesubclaimloader={iscreatesubclaimloader}
              issubclaimmodal={issubclaimmodal}
              handlSubClaimModalClose={handlSubClaimModalClose}
              editClaimDetails={editClaimDetails}
              subclaimtitle={subclaimtitle}
              setSubClaimTitle={setSubClaimTitle}
              isremovesubclaimloder={isremovesubclaimloder}
              removeSubclaim={removeSubclaim}
              handleSubClaim={handleSubClaim}
            />
          ) : null}
          <EscalateDisputeCase
            escalateloading={escalateloading}
            handleEsclateModalClose={handleEsclateModalClose}
            escalatejustification={escalatejustification}
            isescalatemodal={isescalatemodal}
            selectedEscalateCase={selectedEscalateCase}
            setEscalateJustification={setEscalateJustification}
            handleEsclateCase={handleEsclateCase}
            setSelectedEscalateCase={setSelectedEscalateCase}
          />
        </div>
      )}
    </>
  );
};

export default Claims;
