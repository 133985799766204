import { useEffect, useState } from "react";
import SideBar from "../components/SideBar/SideBar";
import AdminSidebar from "../components/AdminSidebar/AdminSideBar";
import Header from "../components/Header/Header";
import { Divider } from "../components/Divider/Divider";
import storageService from "../services/storageService";
import { USER_TYPE } from "../constants/enum";
import { CASELIST_ROUTE, ORG_DASHBOARD_ROUTE } from "../constants/routeEnums";
import { USER_TYPE_ADMIN, USER_TYPE_AGENT } from "../constants/enum";
import { useNavigate } from "react-router-dom";
import { configResponse } from "../services/coreService";
import { useOrganization } from "../context/OrganizationContext";
import { showNotification } from "../components/Toastify/Toast";

const Layouts = ({ children, userTypeFromRoute }) => {

  const { updateFeedbackFormState, updateSettingsRoute, updateRefreshConfig } = useOrganization();

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const userType = storageService.getItem(USER_TYPE);

  const navigate = useNavigate();

  useEffect(() => {
    if (userType !== userTypeFromRoute) {
      redirectToHome();
    }
  }, [userType, userTypeFromRoute]);

  const redirectToHome = () => {
    if (userType === USER_TYPE_AGENT) {
      navigate(CASELIST_ROUTE);
    }
    if (userType === USER_TYPE_ADMIN) {
      navigate(ORG_DASHBOARD_ROUTE);
    }
  };

   const fetchConfig = async () => {
      try {
        const res = await configResponse();
        if (res.status === "Success") {
          updateRefreshConfig(res.data.refreshAvailableBefore);
          updateFeedbackFormState(res.data.isFeedbackFormVisibleToUsers);
          updateSettingsRoute(res.data.settingsRoutesVisible);
        }
      } catch (e) {
        showNotification("Error occured", "error");
      }
    };
  
    useEffect(() => {
      fetchConfig();
    }, []);

  return (
    <div className="flex w-full">
      {/* Sidebar */}
      <div
        className={`min-h-screen bg-gray-100 border-r border-gray-300 transition-all duration-300 ${sidebarCollapsed ? "w-[80px]" : "w-[220px]"
          }`}
      >
        {userType === USER_TYPE_ADMIN ? (
          <div data-testid="admin-sidebar">
            <AdminSidebar setCollapsed={setSidebarCollapsed} data-testid="admin-sidebar" />
          </div>
        ) : (
          <div data-testid="agent-sidebar">
            <SideBar setCollapsed={setSidebarCollapsed} data-testid="agent-sidebar" />
          </div>
        )}
      </div>

      {/* Main Content */}
      <div className="flex-1 w-[70%] flex flex-col">
        {/* Header */}
        <div>
          <Header />
        </div>
        <Divider />

        {/* Content Area */}
        <div className="flex-1 overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default Layouts;
