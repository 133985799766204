import React, { useState } from "react";
import { showNotification } from "../../../../components/Toastify/Toast";
import Loader from "../../../../components/Loaders/Loader";
import Button from "../../../../components/Button/Button";
import { addPlaybookComplaint } from "../../../../services/coreService";
import {
  PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES,
  PLAYBOOK_COMPLAINT_TYPE_BILLING,
  PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE,
  PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY,
  PLAYBOOK_COMPLAINT_TYPE_UNKNOWN,
  PLAYBOOK_COMPLAINT_TYPE_PACKAGE,
} from "../../../../constants/playbookComplaintsTypeConstants";
import { displayComplaintName } from "../../../../utils/displayComplaintName";
import { COMPLAINTTYPESINPUT_MAX_LIMIT } from "../../../../constants/playBookInputLimit";
import { calculateWordCount } from "../../../../utils/countWordsLeft";

const AddComplaintType = ({
  organizationId,
  playbookComplaintsData,
  selectedPlaybook,
  setIsAddComplaintModalOpen,
  setplaybookComplaintsData,
}) => {
  const [selectedComplaintType, setSelectedComplaintType] = useState("");
  const [criteriaToIdentifySubClaims, setCriteriaToIdentifySubClaims] =
    useState("");
  const [criteriaToAssessEvidence, setCriteriaToAssessEvidence] = useState("");
  const [remedialActionsGuidance, setRemedialActionsGuidance] = useState("");
  const [monetaryValueGuidance, setMonetaryValueGuidance] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const filteredComplaintTypes = [
    PLAYBOOK_COMPLAINT_TYPE_BILLING,
    PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES,
    PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE,
    PLAYBOOK_COMPLAINT_TYPE_PACKAGE,
    PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY,
    PLAYBOOK_COMPLAINT_TYPE_UNKNOWN,
  ].filter(
    (type) =>
      !playbookComplaintsData?.some(
        (complaint) => complaint.typeOfComplaint === type
      )
  );

  const subClaimsWordCount = calculateWordCount(
    criteriaToIdentifySubClaims || "",
    COMPLAINTTYPESINPUT_MAX_LIMIT
  );
  const assessEvidenceWordCount = calculateWordCount(
    criteriaToAssessEvidence || "",
    COMPLAINTTYPESINPUT_MAX_LIMIT
  );
  const actionGuidanceWordCount = calculateWordCount(
    remedialActionsGuidance || "",
    COMPLAINTTYPESINPUT_MAX_LIMIT
  );
  const valueGuidanceWordCount = calculateWordCount(
    monetaryValueGuidance || "",
    COMPLAINTTYPESINPUT_MAX_LIMIT
  );

  const handleAddComplaint = async () => {
    if (
      !selectedComplaintType ||
      !criteriaToAssessEvidence ||
      !criteriaToIdentifySubClaims ||
      !remedialActionsGuidance ||
      !monetaryValueGuidance
    ) {
      showNotification("Please Do Enter All Fields", "error");
      return;
    }

    setIsLoading(true);
    const requestBody = {
      complaintTypePlaybook: {
        typeOfComplaint: selectedComplaintType,
        criteriaToIdentifySubClaims,
        criteriaToAssessEvidence,
        remedialActionsGuidance,
        monetaryValueGuidance,
      },
    };

    try {
      const data = await addPlaybookComplaint(
        organizationId,
        selectedPlaybook?._id,
        requestBody
      );
      setplaybookComplaintsData((prevData) => [
        ...prevData,
        data?.data?.complaintTypePlaybook,
      ]);
      setIsAddComplaintModalOpen(false);
    } catch (error) {
      console.error("Error adding complaint:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div className="bg-white rounded-lg shadow-lg w-[600px] p-4 px-6 space-y-3 h-[600px]">
        {/* Header */}
        <div className="flex items-center pb-2 border-b">
          <h2 className="text-xl font-bold text-black">Add Complaint</h2>
        </div>

        {/* Complaint Fields */}
        <div className="p-3 rounded-[4px] border border-[#E4E4E4] text-[#000] text-[14px] leading-[150%] space-y-4 max-h-[450px] overflow-y-auto ">
          {/* Select Complaint Type */}
          <div className="flex flex-col">
            <label
              htmlFor="Type of Complaint"
              className="font-medium text-[#727272]"
            >
              Type of Complaint:
            </label>
            <select
              onChange={(e) => setSelectedComplaintType(e.target.value)}
              className="p-2 mt-1 text-sm border rounded"
              aria-label="Type of Complaint"
            >
              <option value="">Select Complaint Type</option>
              {filteredComplaintTypes.map((type) => (
                <option key={type} value={type}>
                  {displayComplaintName(type)}
                </option>
              ))}
            </select>
          </div>

          {/* Criteria to Identify Subclaims */}
          <div>
            <span className="font-medium text-[#727272]">Criteria to Identify Subclaims:</span>
            {<p className="text-[14px] text-red-500 float-right">
              {subClaimsWordCount > 0 ? `${subClaimsWordCount} words left` : `Word limit reached`}
            </p>
            }
            <textarea
              value={criteriaToIdentifySubClaims}
              onChange={(e) => {
                const wordCount = calculateWordCount(e.target.value, COMPLAINTTYPESINPUT_MAX_LIMIT);
                if (wordCount >= 0) {
                  setCriteriaToIdentifySubClaims(
                    e.target.value,
                  )
                }
              }
              }
              className="w-full mt-1 p-2 border rounded text-sm"
              rows="3"
              data-testid="criteria-subclaims-input"
            />
          </div>

          {/* Criteria to Assess Evidence */}
          <div>
            <span className="font-medium text-[#727272]">Criteria to Assess Evidence:</span>
            {<p className="text-[14px] text-red-500 float-right">
              {assessEvidenceWordCount > 0 ? `${assessEvidenceWordCount} words left` : `Word limit reached`}
            </p>
            }
            <textarea
              value={criteriaToAssessEvidence}
              onChange={(e) => {
                const wordCount = calculateWordCount(e.target.value, COMPLAINTTYPESINPUT_MAX_LIMIT);
                if (wordCount >= 0) {
                  setCriteriaToAssessEvidence(
                    e.target.value,
                  )
                }
              }
              }

              className="w-full mt-1 p-2 border rounded text-sm"
              rows="3"
              data-testid="criteria-assess-evidence-input"
            />
          </div>

          {/* Remedial Actions Guidance */}
          <div>
            <span className="font-medium text-[#727272]">Remedial Actions Guidance:</span>
            {<p className="text-[14px] text-red-500 float-right">
              {actionGuidanceWordCount > 0 ? `${actionGuidanceWordCount} words left` : `Word limit reached`}
            </p>
            }
            <textarea
              value={remedialActionsGuidance}
              onChange={(e) => {
                const wordCount = calculateWordCount(e.target.value, COMPLAINTTYPESINPUT_MAX_LIMIT);
                if (wordCount >= 0) {
                  setRemedialActionsGuidance(
                    e.target.value,
                  )
                }
              }
              }

              className="w-full mt-1 p-2 border rounded text-sm"
              rows="3"
              data-testid="remidial-actions-input"
            />
          </div>

          {/* Monetary Value Guidance */}
          <div>
            <span className="font-medium text-[#727272]">Monetary Value Guidance:</span>
            {<p className="text-[14px] text-red-500 float-right">
              {valueGuidanceWordCount > 0 ? `${valueGuidanceWordCount} words left` : `Word limit reached`}
            </p>
            }
            <textarea
              value={monetaryValueGuidance}
              onChange={(e) => {
                const wordCount = calculateWordCount(e.target.value, COMPLAINTTYPESINPUT_MAX_LIMIT);
                if (wordCount >= 0) {
                  setMonetaryValueGuidance(
                    e.target.value,
                  )
                }
              }
              }

              className="w-full mt-1 p-2 border rounded text-sm"
              rows="3"
              data-testid="monetary-value-input"
            />
          </div>
        </div>

        {/* Footer Buttons */}
        <div className="flex justify-end gap-2 pt-3 border-t">
          <Button
            className="w-full bg-[#f2f2f2] text-black px-4 py-2"
            onClick={() => setIsAddComplaintModalOpen(false)}
            text={"Cancel"}
          />

          {isLoading ? (
            <div className="flex items-center justify-center w-full pt-2" data-testid="loading-indicator"><Loader /></div>
          ) : (
            <Button
              className="w-full bg-[#000000] text-white px-4 py-2"
              text={"Add"}
              onClick={handleAddComplaint}
            />
          )}
        </div>
      </div>
    </div >
  )
}

export default AddComplaintType;
