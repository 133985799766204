import { Link } from "react-router-dom";
import PermissionSegment from "../../../components/PermissionLocks/PermissionSegment";
import { AGENT_ROLE_ADMIN, AGENT_ROLE_SUPERADMIN } from "../../../constants/agentEnums";
import { useUser } from "../../../context/UserContext";

const Rules = () => {
  const { agent } = useUser();

  const defaultcss =
    "font-inter  font-normal leading-[150%] text-[#4976F4] underline decoration-solid decoration-auto decoration-[#4976F4] text-underline-offset-auto";
  return (
    <PermissionSegment
      shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN]}
      hasRole={agent?.role}>

      <div className="mx-10 my-10 text-[20px]">
        If <Link className={defaultcss}>Complaint</Link> mentions{" "}
        <Link className={defaultcss}>Netflix Bundling</Link> then{" "}
        <Link className={defaultcss}>Email Legal</Link> and{" "}
        <Link className={defaultcss}>Add remediation</Link>.
      </div>
    </PermissionSegment>
  );
};

export default Rules;
