import { Image } from "../../../components/Image/Image";
import Label from "../../../components/Label/Label";
import {
  fetchCaseList,
  attemptCreateCase,
  refreshResponse,
} from "../../../services/coreService";
import plusIcon from "../../../Images/Plus.svg";
import { List } from "../../../components/List/List";
import warningIcon from "../../../Images/WarningIcon.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import storageService from "../../../services/storageService";
import { AGENT_ID, ORGANIZATION_ID, TOKEN } from "../../../constants/enum";
import { datedifference } from "../../../utils/datedifference";
import { CASE_ID } from "../../../constants/enum";
import { CASE_UPLOAD_ROUTE, CASES_ROUTE } from "../../../constants/routeEnums";
import Loader from "../../../components/Loaders/Loader";
import Icon from "../../../components/Icon/Icon";
import {
  DISPUTE_CASE_STATUS_UPLOAD_PENDING,
  DISPUTE_CASE_STATUS_FAILED,
  DISPUTE_CASE_STATUS_SENT_FOR_PROCESSING,
  DISPUTE_CASE_STATUS_PROGESS,
  DISPUTE_CASE_STATUS_COMPLETED,
} from "../../../constants/disputeCaseStatusConstants";
import { useUser } from "../../../context/UserContext.js";
import {
  AGENT_ROLE_SUPERADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_ADMIN,
} from "../../../constants/agentEnums.js";
import { showNotification } from "../../../components/Toastify/Toast.js";
import truncate from "lodash/truncate";

const CaseList = () => {
  const navigate = useNavigate();
  const agentId = storageService.getItem(AGENT_ID);
  const organizationId = storageService.getItem(ORGANIZATION_ID);

  const [loading, setLoading] = useState(true);
  const [caseList, setCaseList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [count, setCount] = useState(0);

  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortOption, setSortOption] = useState("");

  const { agent } = useUser();

  const PAGE_SIZE = 50;
  const totalPages = Math.ceil(count / PAGE_SIZE);

  const handleSortChange = (e) => {
    const newSortOption = e?.target?.value;
    setSortOption(newSortOption);
    setPageNum(1);
  };

  const handleStatusFilterChange = async (e) => {
    const newStatus = e.target.value;
    setSelectedStatus(newStatus);
    setPageNum(1);
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (organizationId && agentId) {
      fetchData(organizationId, agentId, pageNum);
    }
  }, [organizationId, agentId, pageNum, selectedStatus, sortOption]);

  const fetchData = async (organizationId, agentId, pageNum) => {
    let payload = {
      pageNum: pageNum,
      pageSize: PAGE_SIZE,
      filters: selectedStatus ? { status: selectedStatus } : {},
      sortBy: sortOption || null,
    };

    try {
      setLoading(true);

      const res = await fetchCaseList(organizationId, agentId, payload);

      if (res?.status === "Success") {
        const { list, count } = res.data;
        setCount(count);
        setCaseList(list);
      }
    } catch (error) {
      console.error("Failed to fetch case list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchMoreCase = () => {
    if (pageNum < totalPages) {
      setPageNum((prevPage) => prevPage + 1);
    }
  };

  const handleCreateCase = async () => {
    const disputeCase = {
      uploadedBy: agentId,
      createdBy: agentId,
    };
    const res = await attemptCreateCase(organizationId, agentId, disputeCase);

    if (res?.status === "Success") {
      const { disputeCase } = res.data;
      storageService.setItem(CASE_ID, disputeCase?._id);
      navigate(CASE_UPLOAD_ROUTE);
    }
  };

  const tagStyles = [
    "bg-[#FBF4EC] text-[#D28E3D]",
    "bg-[#F4EDF7] text-[#954BAF]",
    "bg-[#EDF2FE] text-[#4976F4]",
  ];

  const getCaseItemClass = (item) => {
    let days = Number(datedifference(item.caseDeadlineDate));
    if (days === -10000) {
      return "text-[#727272]";
    }
    return days < 5
      ? "text-[#AF4B4B]"
      : "text-[#727272]";
  };

  const getSubjectText = (item) => {
    if (item.status === DISPUTE_CASE_STATUS_UPLOAD_PENDING) {
      return "Document Upload Pending!";
    }
    if (item.status === DISPUTE_CASE_STATUS_FAILED) {
      return "Document Process Failed. Please Retry!";
    }
    if (item.status === DISPUTE_CASE_STATUS_PROGESS) {
      return `Case is being processed!`;
    }
    if (Number(datedifference(item.caseDeadlineDate)) < 0) {
      return `Case deadline exceeded!`;
    }
    return `${datedifference(item.caseDeadlineDate)} days remaining`;
  };

  /*const getCaseTags = (item, idx) => {
    if (item.status === DISPUTE_CASE_STATUS_UPLOAD_PENDING) {
      return (
        <span
          key={idx}
          className={`flex items-center justify-center gap-2 px-2 py-1 rounded-[36px] opacity-75 font-inter text-[12px] font-medium leading-[140%] ${tagStyles[0]}`}
        >
          {"pending"}
        </span>
      );
    }
    if (item.status === DISPUTE_CASE_STATUS_FAILED) {
      return (
        <span
          key={idx}
          className={`flex items-center justify-center gap-2 px-2 py-1 rounded-[36px] opacity-75 font-inter text-[12px] font-medium leading-[140%] ${tagStyles[0]}`}
        >
          {"failed"}
        </span>
      );
    }
    return item.caseTags.map((tag, idx) => (
      <span
        key={idx}
        className={`flex items-center justify-center gap-2 px-2 py-1 rounded-[36px] opacity-75 font-inter text-[12px] font-medium leading-[140%] ${tagStyles[Math.floor(Math.random() * tagStyles.length)]
          }`}
      >
        {tag}
      </span>
    ));
  };
  */
  const handleNavigate = (item) => {
    const { status, _id } = item;
    if (status === DISPUTE_CASE_STATUS_UPLOAD_PENDING) {
      storageService.setItem(CASE_ID, _id);
      navigate(`${CASE_UPLOAD_ROUTE}`);
    } else {
      navigate(`${CASES_ROUTE.replace(":case_id", _id)}`);
    }
  };

  const renderCaseItem = (item) => {
    return (
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full">
          <span className="flex-1 text-[14px] font-medium">
            {item.caseTitle
              ? truncate(item.caseTitle, { length: 100 })
              : "Untitled"}
          </span>
          <span
            className={`gap-x-2 flex-1 text-[14px] flex items-center gap-1 justify-center font-inter ${getCaseItemClass(
              item
            )}`}
          >
            {Number(datedifference(item.caseDeadlineDate)) < 5 ? (
              <Image
                src={warningIcon}
                alt="warningIcon"
                className={`w-[16px] h-[16px] ${getCaseItemClass(item)}`}
              />
            ) : (
              <Icon type="WarningBWIcon" className="w-[16px] h-[16px]" />
            )}
            {getSubjectText(item)}
          </span>

          <div className="flex justify-end flex-1 gap-x-2">
            {item?.caseType && <span
              className={`flex items-center justify-center gap-2 px-2 py-1 rounded-[36px] opacity-75 font-inter text-[12px] font-medium leading-[140%] ${tagStyles[0]}`}

            >
              {item?.caseType}
            </span>
            }
          </div>
        </div>
        <div className="flex justify-end mt-1">
          <p className="font-inter text-[12px] text-[#727272]">
            {formatDate(item.updatedAt)}
          </p>
        </div>
      </div>
    );
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const range = 2;
    let start = pageNum - range;
    let end = pageNum + range;
    if (start < 1) {
      start = 1;
      end = Math.min(totalPages, start + 4);
    }
    if (end > totalPages) {
      end = totalPages;
      start = Math.max(1, end - 4);
    }
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const loadMoreCases = (page) => {
    if (page !== pageNum) {
      setPageNum(page);
      window.scrollTo(0, 0);
    }
  };

  const handleCreateCaseWithAgentRole = () => {
    if (
      agent &&
      agent.role &&
      [
        AGENT_ROLE_SUPERADMIN,
        AGENT_ROLE_CONTRIBUTER,
        AGENT_ROLE_ADMIN,
      ].includes(agent.role)
    ) {
      handleCreateCase();
    } else {
      showNotification("You don't have permission to create cases!");
    }
  };

  return (
    <>
      <div className="pt-8 pl-10 pr-6">
        <div>
          <div
            className="mb-[10px] cursor-pointer flex justify-center items-center gap-2 self-stretch px-4 py-2  border bg-[#F2F2F2] rounded-[4px]"
            onClick={handleCreateCaseWithAgentRole}
            data-testid="create-case-button"
          >
            <Image src={plusIcon} className="w-[16px] h-[16px]" />
            <Label
              text={"Create Case"}
              className={
                "font-inter text-base font-medium leading-6 text-[#727272] cursor-pointer"
              }
            />
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex items-center gap-2">
              <span className="font-inter text-sm font-medium text-[#727272]">
                Filter By:
              </span>
              <select
                className="px-4 py-1 border bg-[#F2F2F2] rounded-[8px] font-inter text-sm font-small text-[#727272] cursor-pointer"
                value={selectedStatus}
                onChange={handleStatusFilterChange}
              >
                <option value="" className="cursor-pointer">
                  All Status
                </option>
                <option
                  value={DISPUTE_CASE_STATUS_UPLOAD_PENDING}
                  className="cursor-pointer"
                >
                  Upload Pending
                </option>
                <option
                  value={DISPUTE_CASE_STATUS_SENT_FOR_PROCESSING}
                  className="cursor-pointer"
                >
                  Sent to Processing
                </option>
                <option
                  value={DISPUTE_CASE_STATUS_PROGESS}
                  className="cursor-pointer"
                >
                  In Progress
                </option>
                <option
                  value={DISPUTE_CASE_STATUS_FAILED}
                  className="cursor-pointer"
                >
                  Failed
                </option>
                <option
                  value={DISPUTE_CASE_STATUS_COMPLETED}
                  className="cursor-pointer"
                >
                  Completed
                </option>
              </select>
            </div>

            <div className="flex items-center gap-2">
              <span className="font-inter text-sm font-medium text-[#727272]">
                Sort By:
              </span>
              <select
                value={sortOption}
                onChange={handleSortChange}
                className="px-4 py-1 border bg-[#F2F2F2] rounded-[8px] font-inter text-sm font-small text-[#727272] cursor-pointer"
              >
                <option value="">No Sorting</option>
                <option value="lastUpdate">Time Updated</option>
                <option value="deadlinePending">Time Remaining</option>
                <option value="caseStatus">Case Status</option>
              </select>
            </div>
          </div>
          {loading && pageNum === 1 ? (
            <div
              className="flex items-center justify-center h-[80vh]"
              role="status"
              data-testid="spinner"
            >
              <Loader />
            </div>
          ) : (
            <>
              {caseList.length > 0 ? (
                <div>
                  <List
                    items={caseList}
                    navigate={navigate}
                    className={
                      "cursor-pointer mt-[20px] mb-[20px] flex justify-between items-center self-stretch px-[20px] pt-[14px] rounded-[4px] border border-[var(--Color-Tokens-Border-Primary,#E4E4E4)]"
                    }
                    handleClick={handleNavigate}
                    renderItem={(item, i) => (
                      <div className="w-full" key={i}>
                        {renderCaseItem(item)}
                      </div>
                    )}
                  />
                  <div className="flex justify-center mt-6 mb-20">
                    <nav aria-label="Page navigation">
                      <ul className="inline-flex items-center space-x-2">
                        <li>
                          <button
                            onClick={() => loadMoreCases(pageNum - 1)}
                            className={`px-4 py-2 text-sm font-medium rounded-full ${pageNum === 1
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-gray-200 hover:bg-black hover:text-white text-black"
                              }`}
                            disabled={pageNum === 1}
                          >
                            &lt;
                          </button>
                        </li>
                        {getPageNumbers().map((page) => (
                          <li key={page}>
                            <button
                              onClick={() => loadMoreCases(page)}
                              className={`px-4 py-2 text-sm font-medium rounded-full ${page === pageNum
                                ? "bg-black text-white"
                                : "bg-gray-200 hover:bg-black hover:text-white text-black"
                                }`}
                            >
                              {page}
                            </button>
                          </li>
                        ))}
                        <li>
                          <button
                            onClick={() => loadMoreCases(pageNum + 1)}
                            className={`px-4 py-2 text-sm font-medium rounded-full ${pageNum === totalPages
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-gray-200 hover:bg-black hover:text-white text-black"
                              }`}
                            disabled={pageNum === totalPages}
                          >
                            &gt;
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex flex-row justify-center items-center h-full mt-[20%]">
                    <p className="text-[20px] text-black font-semibold">
                      No Cases
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CaseList;
