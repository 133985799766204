import React from 'react';
import { DOCUMENT_AI_COMPLETED, DOCUMENT_AI_FAILED, DOCUMENT_UPLOAD_PENDING, PROCESSING_AI, PROCESSING_AI_COMPLETED, PROCESSING_AI_FAILED } from '../../../../constants/admindashboardConstants';

function DashBoradTable({dashboardstats}) {

  const getStatusHeading = (status) => {
    switch (status) {
      case DOCUMENT_UPLOAD_PENDING:
        return "Document Upload Pending";
      case PROCESSING_AI_FAILED:
        return "Processing AI Failed";
      case PROCESSING_AI:
        return "Processing AI";
      case DOCUMENT_AI_COMPLETED:
        return "Document AI Completed";
      case DOCUMENT_AI_FAILED:
        return "Document AI Failed";
      case PROCESSING_AI_COMPLETED:
        return "Processing AI Completed";
      default:
        return status;
    }
  };

  return (
    <div className="mt-8">
      <table className="w-[75%] border border-gray-300">
        <thead className="bg-gray-100 border-b-2 border-gray-400 shadow-sm">
          <tr>
            <th className="text-[#000] font-normal p-2 text-left min-w-[160px] border-r border-gray-300">Status</th>
            <th className="text-[#000] font-normal p-2 text-center min-w-[160px] border-r border-gray-300">Count</th>
          </tr>
        </thead>
        <tbody>
          {dashboardstats?.map((stat, index) => (
            <tr key={index} className="even:bg-gray-100">
              <td className="p-2 text-left text-[16px] border-r border-gray-300"> 
                {getStatusHeading(stat.status)}
              </td>
              <td className="p-2 text-center text-[16px] border-r border-gray-300">{stat.count ? stat.count : 0}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DashBoradTable;

