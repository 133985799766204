import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [admin, setAdmin] = useState(null);
    const [agent, setAgent] = useState(null);

    const updateAdmin = (newAdmin) => {
        setAdmin(newAdmin);
    };

    const updateAgent = (newAgent) => {
        setAgent(newAgent);
    };

    return (
        <UserContext.Provider value={{ admin, updateAdmin, agent, updateAgent }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};
