import React, { useEffect, useState } from "react";
import {
  CRITERIA_INDENTIFY_SUBCLAIMS,
  CRITERIA_ASSESS_EVIDENCE,
  REMEDIAL_ACTION_GUIDANCE,
  MONETARY_VALUE_GUIDANCE,
} from "../../../../constants/complaintTypesSubkeys";

import { displayComplaintName } from "../../../../utils/displayComplaintName";
import Button from "../../../../components/Button/Button.jsx";
import Loader from "../../../../components/Loaders/Loader.jsx";
import { COMPLAINTTYPESINPUT_MAX_LIMIT } from "../../../../constants/playBookInputLimit.js";
import { updatePlaybookComplaint } from "../../../../services/coreService.js";
import { showNotification } from "../../../../components/Toastify/Toast.js";
import EditPlaybookContent from "./EditPlaybookContent.jsx";


export default function EditComplaintModal({
  selectedComplaint,
  setIsEditModalOpen,
  organizationId,
  selectedPlaybook,
  fetchPlaybook
}) {

  const complaintBookDetails = [
    {
      title: CRITERIA_INDENTIFY_SUBCLAIMS,
      criteria: "criteriaToIdentifySubClaims",
      textLimit: COMPLAINTTYPESINPUT_MAX_LIMIT,

    },
    {
      title: CRITERIA_ASSESS_EVIDENCE,
      criteria: "criteriaToAssessEvidence",
      textLimit: COMPLAINTTYPESINPUT_MAX_LIMIT,

    },
    {
      title: REMEDIAL_ACTION_GUIDANCE,
      criteria: "remedialActionsGuidance",
      textLimit: COMPLAINTTYPESINPUT_MAX_LIMIT,

    },
    {
      title: MONETARY_VALUE_GUIDANCE,
      criteria: "monetaryValueGuidance",
      textLimit: COMPLAINTTYPESINPUT_MAX_LIMIT,

    },
  ];

  const [complaint, setComplaint] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setComplaint({ ...selectedComplaint });
  }, [selectedComplaint]);

  const handleChangeComplaintCriteria = (criteria, value) => {
    setComplaint((prevComplaint) => ({
      ...prevComplaint,
      [criteria]: value,
    }));
  };


  const handleUpdateComplaint = async () => {
    setIsLoading(true);
    try {

      const payload = {
        complaintTypePlaybook: {
          typeOfComplaint: selectedComplaint.typeOfComplaint,
          criteriaToIdentifySubClaims:
            complaint.criteriaToIdentifySubClaims,
          criteriaToAssessEvidence: complaint.criteriaToAssessEvidence,
          remedialActionsGuidance: complaint.remedialActionsGuidance,
          monetaryValueGuidance: complaint.monetaryValueGuidance,
        },
      };

      await updatePlaybookComplaint(
        organizationId,
        selectedComplaint?._id,
        payload
      );
      fetchPlaybook(selectedPlaybook._id);

      setIsEditModalOpen(false);
    } catch (error) {
      console.error(error);
      showNotification("Failed to update complaint", "error");
    }
    setIsLoading(false);
  };


  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30 ">
      <div className="bg-white rounded-lg shadow-lg w-[600px] p-3 px-6 space-y-4 h-[600px]">
        {/* Header */}
        <div className="flex items-center border-b pb-2">
          <span className="text-xl font-bold text-black">
            {displayComplaintName(selectedComplaint?.typeOfComplaint)}
          </span>
        </div>
        {/* Complaint Fields */}
        {selectedComplaint && (
          <div className="p-3 rounded-[4px] border border-[#E4E4E4] text-[#000] text-[14px] leading-[150%] space-y-2 overflow-y-scroll h-[450px]">
            {complaintBookDetails?.map((complaintType) => {

              return (
                <EditPlaybookContent
                  title={complaintType.title}
                  id={complaintType.criteria}
                  value={complaint[complaintType.criteria]}
                  wordLimit={complaintType.textLimit}
                  handleChange={(e) =>
                    handleChangeComplaintCriteria(
                      complaintType.criteria,
                      e.target.value
                    )
                  }
                />



              )
            })}
          </div>
        )}
        {/* Footer Buttons */}
        <div className="flex justify-end gap-2 pt-3 border-t">
          <Button
            className="w-full bg-[#f2f2f2] text-black px-4 py-2"
            onClick={() => setIsEditModalOpen(false)}
            text={"Cancel"}
          />
          {isLoading ? (
            <div className="flex justify-center w-full pt-2 item-center">
              <Loader />
            </div>
          ) : (
            <Button
              className="w-full bg-[#000000] text-white px-4 py-2"
              text={"Save"}
              onClick={handleUpdateComplaint}
            />
          )}
        </div>
      </div>
    </div >
  );
}
