import memberIcon from "../Images/member_icon.svg";
import profileIcon from "../Images/profile_icon.svg";
import playbookIcon from "../Images/playbook_icon.svg";
import rulesIcon from "../Images/rules_icon.svg";
import reportIcon from "../Images/reporticon.png"

export const settingContent = [
  { label: "Profile", value: "profile", icon: profileIcon, shouldHaveRoles : [] },
  { label: "Members",  value: "members", icon: memberIcon,  shouldHaveRoles : [] },
  { label: "Playbooks",  value: "playbook", icon: playbookIcon,  shouldHaveRoles : [] },
  { label: "Rules", value: "rules",  icon: rulesIcon,  shouldHaveRoles : [] },
  { label: "Reports", value: "reports", icon: reportIcon,  shouldHaveRoles : [] },
];

export const playbook = [
  { label: "TeleWave Playbook", value: "TeleWave Playbook" },
];
