import { useState } from "react";
import { Image } from "../Image/Image";
import logo from "../../Images/mainlogo.png";
import fileLogo from "../../Images/FileText.svg";
import Label from "../Label/Label";
import { NavLink } from "react-router-dom";
import settingIcon from "../../Images/Gear.svg";
import collapse from "../../Images/sidebarcollapse.svg";
import { Divider } from "../Divider/Divider";
import { ORG_DASHBOARD_ROUTE, ORGLIST_ROUTE } from "../../constants/routeEnums";

const sidebarLinks = [
  {
    link: ORG_DASHBOARD_ROUTE,
    name: "Dashboard",
  },
];

const SideBar = ({ setCollapsed }) => {
  const [collapsed, setLocalCollapsed] = useState(false);

  const toggleCollapse = () => {
    setLocalCollapsed((prev) => !prev);
    setCollapsed((prev) => !prev);
  };

  return (
    <div
      className={`relative flex flex-col min-h-screen transition-all duration-300 ${collapsed ? "w-[80px]" : "w-[220px]"
        } bg-gray-100 border-r border-gray-300`}
    >
      <div
        className="absolute right-[-20px] top-[35px] transform -translate-y-1/2 flex items-center justify-center w-[30px] h-[30px] bg-white border border-gray-300 cursor-pointer"
        onClick={toggleCollapse}
        aria-label="Toggle Sidebar Collapse"
      >
        <Image
          className={`w-[20px] h-[20px] transform transition-transform ${collapsed ? "rotate-180" : ""
            }`}
          src={collapse}
          alt="Collapse Sidebar"
        />
      </div>

      <div className="flex items-center justify-center h-[70px]">
        <Image
          className={`w-[80%] h-[50px] px-2 mt-3`}
          src={logo}
        />

      </div>
      <Divider />

      {sidebarLinks.map((linkItem) => (
        <div key={linkItem.link} className="flex flex-col px-4 py-3">
          <NavLink
            to={ORG_DASHBOARD_ROUTE}
            className={({ isActive }) =>
              `flex items-center gap-3 p-2 h-9 rounded-[4px] ${isActive ? "bg-[#F2F2F2]" : ""
              }`
            }
          >
            <Image className="w-[20px] h-[20px]" src={fileLogo} />
            {!collapsed && (
              <Label
                className="text-sm font-medium leading-[150%] cursor-pointer"
                htmlFor={linkItem.name.toLowerCase()}
                text={linkItem.name}
              />
            )}
          </NavLink>
        </div>
      ))}

      <Divider />

      <div className="flex flex-col px-4 py-3">
        {!collapsed && (
          <Label
            className="text-xs font-medium leading-[140%] text-[#727272] mb-2"
            htmlFor={"organisation"}
            text={"ORGANISATIONS"}
          />
        )}
        <NavLink
          to={ORGLIST_ROUTE}
          className={({ isActive }) =>
            `flex items-center gap-3 p-2 h-9 rounded-[4px] ${isActive ? "bg-[#F2F2F2]" : ""
            }`
          }
        >
          <Image className="w-[20px] h-[20px]" src={settingIcon} />
          {!collapsed && (
            <Label
              className="text-sm font-medium leading-[150%] cursor-pointer"
              htmlFor={"settings"}
              text={"Organizations"}
            />
          )}
        </NavLink>
      </div>
    </div>
  );
};

export default SideBar;
