// utils/wordCount.js
export const calculateWordCount = (text = "", maxWords) => {
    const countWords = (input) => {
        return input.trim().length === 0 ? 0 : input.trim().split(/\s+/).length;
    };

    const currentWordCount = countWords(text);
    const wordsLeft = maxWords - currentWordCount;

    return wordsLeft;
};
