import React from 'react';

function AgentCaseTable({ agentcaselist }) {
  return (
    <div className="overflow-x-scroll mt-8">
      <table className="" style={{minWidth:'100%', border:'1px solid lightgray'}} role='table'>
        <thead className="bg-gray-100 border-b-2 border-gray-400 shadow-sm">
          <tr className="">
            <th className="text-[#000] font-normal p-2 text-left min-w-[160px] border-r border-gray-300">Agent Name</th>
            <th className="text-[#000] font-normal p-2 text-center min-w-[215px] border-r border-gray-300 ">Document Upload Pending</th>
            <th className="text-[#000] font-normal p-2 text-center min-w-[210px] border-r border-gray-300 ">Sent To AI</th>
            <th className="text-[#000] font-normal p-2 text-center min-w-[155px] border-r border-gray-300 ">Processing AI</th>
            <th className="text-[#000] font-normal p-2 text-center min-w-[210px] border-r border-gray-300 ">Processing AI Completed</th>
            <th className="text-[#000] font-normal p-2 text-center min-w-[190px] border-r border-gray-300 ">Processing AI Failed</th>
          </tr>
        </thead>
        <tbody>
          {agentcaselist?.map((agent, index) => {
            const caseCounts = {
              document_upload_pending: 0,
              sent_to_ai: 0,
              processing_ai: 0,
              processing_ai_complete: 0,
              processing_ai_fail: 0,
            };

            agent?.cases?.forEach((caseItem) => {
              if (caseCounts[caseItem.status] !== undefined) {
                caseCounts[caseItem.status] = parseInt(caseItem.count);
              }
            });

            return (
              <tr key={index} className="even:bg-gray-100">
                <td className="p-2 text-left border-r border-gray-300">{agent.agentName}</td>
                <td className="p-2 text-center border-r border-gray-300">{caseCounts.document_upload_pending}</td>
                <td className="p-2 text-center border-r border-gray-300">{caseCounts.sent_to_ai}</td>
                <td className="p-2 text-center border-r border-gray-300">{caseCounts.processing_ai}</td>
                <td className="p-2 text-center border-r border-gray-300">{caseCounts.processing_ai_complete}</td>
                <td className="p-2 text-center border-r border-gray-300">{caseCounts.processing_ai_fail}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AgentCaseTable;
