import React, { useEffect, useState } from "react";
import { Nav } from "../../../components/Tabs/Nav";
import Tab from "../../../components/Tabs/Tab";
import { Switch } from "../../../components/Switch/Switch";
import { tabs } from "../../../constants/casesConstants";
import CaseResolveDocuments from "./CaseResolveDocuments/CaseResolveDocuments";
import CaseResolveOverview from "./CaseResolveOverview/CaseResolveOverview";
import Claims from "./Claims/Claims";
import sparkle from "../../../Images/Sparkle.svg";
import { Image } from "../../../components/Image/Image";
import CaseResponse from "./CaseResponse/CaseResponse";
import CasesTimeLine from "./CasesTimeline/CasesTimeline";
import { ORGANIZATION_ID, AUTH_USER } from "../../../constants/enum";
import storageService from "../../../services/storageService";
import { useParams, useNavigate } from "react-router-dom";
import { CASE_ID } from "../../../constants/enum";
import Loader from "../../../components/Loaders/Loader";
import refreshIcon from "../../../Images/arrows-rotate-solid.svg";
import { isDaysPassed } from "../../../utils/isDaysPassed";
import {
  configResponse,
  generateResponse,
} from "../../../services/coreService";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_READER,
  AGENT_ROLE_SUPERADMIN,
} from "../../../constants/agentEnums";
import PermissionSegment from "../../../components/PermissionLocks/PermissionSegment";
import PermissionSwitch from "../../../components/PermissionLocks/PermissionSwitch";
import {
  CASE_UPLOAD_ROUTE,
  CASELIST_ROUTE,
} from "../../../constants/routeEnums";
import {
  getCaseInfo,
  getCaseTimelines,
  getCaseResponses,
  refreshResponse,
} from "../../../services/coreService";
import { formatDateToDDMMYYYY } from "../../../utils/dateFormat";
import {
  DISPUTE_CASE_STATUS_COMPLETED,
  DISPUTE_CASE_STATUS_PROGESS,
  DISPUTE_CASE_STATUS_UPLOAD_PENDING,
  DISPUTE_CASE_STATUS_FAILED,
  DISPUTE_CASE_STATUS_RESPONSE_GENERATION_COMPLETE,
} from "../../../constants/disputeCaseStatusConstants";
import { useUser } from "../../../context/UserContext";
import ReadMore from "../../../components/ReadMore/ReadMore";
import { showNotification } from "../../../components/Toastify/Toast";
import { useOrganization } from "../../../context/OrganizationContext";
import Switch2_0 from "../../../components/SwitchV2/SwitchV2";
import { useResponse } from "../../../context/ResponseContext";

const CASE_REFETCH_TIME = 2000;
const DOT_TIMER = 500;

const Cases = () => {
  const { case_id } = useParams();
  let organizationId = storageService.getItem(ORGANIZATION_ID);
  const { updateRefreshConfig } = useOrganization();
  let caseId = case_id;
  const { agent } = useUser();

  const { overview, documents, timeline, claims, response } = tabs;

  const Navigate = useNavigate();
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [isLoadingPage, setLoadingPage] = useState(true);
  const [caseHighlights, setCaseHighlights] = useState([]);
  const [caseHighlightsDate, setCaseHighlightsDate] = useState();
  const [title, setTitle] = useState("");
  const [remedialData, setRemedialData] = useState([]);
  const [caseStatus, setCaseStatus] = useState("");
  const [caseData, setCaseData] = useState(null);
  const [caseTimelineData, setCaseTimelineData] = useState([]);
  const [letterData, setLetterData] = useState("");
  const [letterTitle, setLetterTitle] = useState("");
  const [letterlist, setLetterList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [show, setShow] = useState(false);
  const [aiStatus, setAiStatus] = useState("");
  const [dots, setDots] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [refreshAvailabeleBefore, setRefreshAvailableBefore] = useState(2);

  const handlModalClose = () => setShow(!show);
  const PAGE_SIZE = 50;
  const totalPages = Math.ceil(count / PAGE_SIZE);
  const {
    decisionValue,
    updateAiresponseGenerated,
    updateConfirmedByAgentForDecision,
    isConfirmedByAgentForDecision,
    globalCaseStatus,
    updateGlobalCaseStatus,
  } = useResponse();

  const [isRegenerateLoader, setIsRegenerateLoader] = useState(false);
  const [isAIResponseGenerated, setIsAIResponseGeneratedLoader] =
    useState(false);

  const navItems = [
    { eventKey: "Overview", label: overview },
    { eventKey: "Documents", label: documents },
    { eventKey: "Timeline", label: timeline },
    { eventKey: "Claims", label: claims },
    { eventKey: "Response", label: response },
  ];

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getLatestUpdatedAt = (data) => {
    if (!data || data.length === 0) return null;

    const sortedData = data.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    return formatDateToDDMMYYYY(sortedData[0].updatedAt);
  };

  const fetchCaseInfo = async () => {
    try {
      const data = await getCaseInfo(organizationId, case_id);
      setLoadingPage(false);

      setCaseData(data?.disputeCase);

      setCaseStatus(data?.disputeCase?.status);
      updateGlobalCaseStatus(data?.disputeCase?.status);

      setAiStatus(data?.disputeCase?.subStatus);

      updateAiresponseGenerated(data?.disputeCase?.isAIResponseGenerated);

      let editDate = formatDateToDDMMYYYY(data?.disputeCase?.updatedAt);

      setCaseHighlightsDate(editDate);
      setTitle(
        data?.disputeCase?.caseTitle ? data?.disputeCase?.caseTitle : "No Title"
      );
      setCaseHighlights(data?.disputeCase?.caseHighlights);

      let status = data?.disputeCase?.status;

      if (status === DISPUTE_CASE_STATUS_UPLOAD_PENDING) {
        storageService.setItem(CASE_ID, case_id);
        Navigate(CASE_UPLOAD_ROUTE);
      } else if (!status) {
        Navigate(CASELIST_ROUTE);
      }
      return data;
    } catch (error) {
      console.error("Error fetching case info:", error);
    }
  };

  const exponentialBackoff = async (
    callback,
    delay,
    status,
    constantLimit = 10
  ) => {
    let retries = 0;
    while (true) {
      console.log(status);
      const response = await callback();
      if (response?.disputeCase?.status === status) {
        return response;
      }
      retries++;
      const waitTime =
        retries <= constantLimit
          ? delay
          : delay * Math.pow(2, retries - constantLimit);
      await new Promise((resolve) => setTimeout(resolve, waitTime));
    }
  };

  useEffect(() => {
    fetchCaseInfo();
    if (caseStatus === DISPUTE_CASE_STATUS_PROGESS) {
      const interval = exponentialBackoff(
        fetchCaseInfo,
        CASE_REFETCH_TIME,
        DISPUTE_CASE_STATUS_COMPLETED
      );
      return () => clearInterval(interval);
    }
  }, [organizationId, refresh, caseStatus]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.innerHeight + document.documentElement.scrollTop;
      const bottomPosition = document.documentElement.offsetHeight;

      if (
        scrollPosition >= bottomPosition - 50 &&
        !isFetchingMore &&
        pageNum < totalPages
      ) {
        setPageNum((prevPageNum) => prevPageNum + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetchingMore, pageNum, totalPages]);

  useEffect(() => {
    if (
      caseStatus === DISPUTE_CASE_STATUS_COMPLETED ||
      caseStatus === DISPUTE_CASE_STATUS_RESPONSE_GENERATION_COMPLETE
    ) {
      fetchCaseTimelines(organizationId, caseId, pageNum);
    }
  }, [caseStatus, organizationId, caseId, pageNum]);

  const fetchCaseTimelines = async (organizationId, caseId, pageNum) => {
    let payload = {
      pageNum: pageNum,
      pageSize: PAGE_SIZE,
      filter: {},
    };

    try {
      if (pageNum === 1) {
        setLoading(true);
      } else {
        setIsFetchingMore(true);
      }

      const res = await getCaseTimelines(organizationId, caseId, payload);

      const { caseTimelines, totalCount } = res;

      setCount(totalCount);

      setCaseTimelineData(caseTimelines);
    } catch (error) {
      console.error("Failed to fetch case list:", error);
    } finally {
      setLoading(false);
      setIsFetchingMore(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + "." : ""));
    }, DOT_TIMER);
    return () => clearInterval(interval);
  }, []);

  const fetchConfig = async () => {
    try {
      const res = await configResponse();
      if (res.status === "Success") {
        updateRefreshConfig(res.data.refreshAvailableBefore);
        setRefreshAvailableBefore(res.data.refreshAvailableBefore);
      }
    } catch (e) {
      showNotification("Error occured", "error");
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  useEffect(() => {
    if (globalCaseStatus === DISPUTE_CASE_STATUS_RESPONSE_GENERATION_COMPLETE) {
      const fetchCaseResponseV1 = async () => {
        setIsRegenerateLoader(true);
        try {
          await delay(5);
          const pageNum = 1;
          const pageSize = 20;
          const filters = {};
          const data = await getCaseResponses(
            organizationId,
            caseId,
            pageNum,
            pageSize,
            filters
          );
          setLetterList(data?.caseResponses);
          setLetterData(data?.caseResponses[0]?.letterData);
          setLetterTitle(data?.caseResponses[0]?.title);
          setIsRegenerateLoader(false);
        } catch (error) {
          setIsRegenerateLoader(false);
          console.error("Error fetching Case Response:", error);
        }
      };

      fetchCaseResponseV1();
      // fetchCasesTimelines();
    }
  }, [globalCaseStatus, organizationId, caseId]);

  const handleRefresh = async () => {
    try {
      setLoadingRefresh(true);
      const res = await refreshResponse(case_id, organizationId);
      setLoadingRefresh(false);
      if (res.status === "Success") {
        setRefresh(!refresh);
        resetCaseState();
      }
    } catch (e) {
      showNotification("Not processed", "error");
    }
  };

  const resetCaseState = () => {
    setCaseHighlights([]);
    setCaseHighlightsDate(null);
    setRemedialData([]);
    setCaseTimelineData([]);
    setLetterData("");
    setLetterTitle("");
    setLetterList([]);
    setPageNum(1);
    setCount(0);
  };

  if (isLoadingPage) {
    return (
      <div
        className={"mt-20 flex flex-col items-center text-center space-y-4"}
        data-testid="loader"
      >
        <Loader />
      </div>
    );
  }
  if (caseStatus === DISPUTE_CASE_STATUS_PROGESS) {
    return (
      <div className={"mt-20 flex flex-col items-center text-center space-y-4"}>
        <p className={"text-center"}>
          We are working to study the case and create appropriate responses.
          Please wait!
        </p>
        <p>
          {aiStatus ? (
            <>
              {aiStatus}{" "}
              <span className="inline-block w-8 text-left">{dots}</span>
            </>
          ) : (
            <>
              Thinking{" "}
              <span className="inline-block w-8 text-left">{dots}</span>
            </>
          )}
        </p>

        <Loader />
      </div>
    );
  }
  if (caseStatus === DISPUTE_CASE_STATUS_FAILED) {
    return (
      <div className={"mt-20 "}>
        <div className={"flex flex-col text-center items-center space-y-3"}>
          <div className="flex">
            <p>The AI Processing Failed!. Please retry again!</p>
            <span
              onClick={handleRefresh}
              data-testid="refreshSpan"
              className="ml-2 mt-1"
            >
              <Image
                src={refreshIcon}
                alt="refreshIcon"
                className="text-[#000] cursor-pointer"
                width={15}
              />
            </span>
          </div>
          <p>{aiStatus}</p>
          {loadingRefresh && <Loader className="mt-1" />}
        </div>
      </div>
    );
  }

  const fetchCaseResponse = async () => {
    setIsRegenerateLoader(true);
    try {
      await delay(5);
      const pageNum = 1;
      const pageSize = 20;
      const filters = {};
      const data = await getCaseResponses(
        organizationId,
        caseId,
        pageNum,
        pageSize,
        filters
      );
      setLetterList(data?.caseResponses);
      setLetterData(data?.caseResponses[0]?.letterData);
      setLetterTitle(data?.caseResponses[0]?.title);
      setIsRegenerateLoader(false);
    } catch (error) {
      setIsRegenerateLoader(false);
      console.error("Error fetching Case Response:", error);
    }
  };

  const fetchCaseInformation = async () => {
    try {
      setIsRegenerateLoader(true);
      const data = await getCaseInfo(organizationId, caseId);
      updateAiresponseGenerated(data?.disputeCase?.isAIResponseGenerated);
      updateGlobalCaseStatus(data?.disputeCase?.status);
      if (
        data.disputeCase.status ===
        DISPUTE_CASE_STATUS_RESPONSE_GENERATION_COMPLETE
      ) {   
        fetchCaseResponse();
      }
      return data;
    } catch (error) {
      console.error("Error fetching case info:", error);
    }
  };

  const resetCaseResponseState = () => {
    setLetterList([]);
    setLetterData("");
    setLetterTitle("");
  };

  const handleGenerateResponse = async () => {
    setIsRegenerateLoader(true);
    try {
      resetCaseResponseState();
      let res;
      res = await generateResponse(organizationId, caseId);
      if (res?.status === "Success") {
        await exponentialBackoff(
          fetchCaseInformation,
          2000,
          DISPUTE_CASE_STATUS_RESPONSE_GENERATION_COMPLETE
        );
        showNotification("Generate Response Successfully", "success");
      }
    } catch (error) {
      showNotification("An error occurred in Generate Response", "error");
    }
  };

  return (
    <>
      <Tab.Container defaultActiveKey="Overview">
        {/*header*/}
        <div className="bg-white border-b">
          <div className="text-[#000000] flex font-inter font-medium  lg:text-[22px] md:text-[20px]  px-6 pt-4 ">
            <ReadMore text={title} />
            <div className="flex w-full lg:w-auto lg:flex-shrink-0">
              <div className="self-center ml-4">
                <Switch2_0 initialState="false" date={caseHighlightsDate} />
              </div>
            </div>
          </div>
          <div className="flex justify-between border-b">
            <div className="flex flex-col justify-start px-4 py-3 md:px-8">
              <div className="flex flex-wrap mb-2 gap-x-1">
                <span className="text-[#000000] font-inter whitespace-pre-line font-medium leading-[24px] not-italic lg:text-[22px] md:text-[20px] max-w-[500px] mb-2">
                  {/* <div>
                    {title.substring(0, 30)}...{" "}
                    <span
                      className="text-[#727272] cursor-pointer"
                      onClick={handlModalClose}
                    >
                      more
                    </span>
                  </div> */}
                </span>
                {/* <div className="flex w-full lg:w-auto lg:flex-shrink-0">
                  <Switch
                    checked={"false"}
                    intialrender={
                      <span className="text-[12px] text-gray-500 whitespace-nowrap">
                        {"Edited on"} {"19-05-2000"}
                      </span>
                    }
                    togglerenderitem={
                      <span className="flex items-center gap-2 ml-3 text-[12px] text-blue-500 whitespace-nowrap">
                        <Image src={sparkle} />
                        {"AI Generated"}
                      </span>
                    }
                  />
                </div> */}
              </div>

              <div className="flex gap-4 pb-1">
                <span className="text-[#727272] font-inter text-[16px] font-medium leading-6">
                  {caseData?.createdBy?.contactName}
                </span>
                <div className="self-center ml-4">
                  <Switch2_0 initialState="false" date={caseHighlightsDate} />
                </div>
                <PermissionSwitch
                  shouldHaveRoles={[
                    AGENT_ROLE_SUPERADMIN,
                    AGENT_ROLE_ADMIN,
                    AGENT_ROLE_CONTRIBUTER,
                  ]}
                  hasRole={agent?.role}
                  yes={
                    !isDaysPassed(
                      caseData?.createdAt,
                      refreshAvailabeleBefore
                    ) && (
                      <span
                        className={`flex items-center cursor-pointer justify-center gap-2   opacity-75 font-inter text-[12px] font-medium leading-[140%] `}
                      >
                        {loadingRefresh ? (
                          <Loader size={12} />
                        ) : (
                          <span
                            onClick={handleRefresh}
                            data-testid="refreshSpan"
                          >
                            <Image
                              src={refreshIcon}
                              alt="refreshIcon"
                              className="text-[#000]"
                              width={20}
                            />
                          </span>
                        )}
                      </span>
                    )
                  }
                />
              </div>
            </div>

            <div
              className="flex flex-col justify-end w-[60%] overflow-x-auto"
              style={{ scrollbarWidth: "none" }}
            >
              <Nav className="flex justify-end gap-4 px-4 text-sm md:text-base whitespace-nowrap min-w-max h-full max-h-[83px]">
                {navItems?.map((item) => (
                  <Nav.Item
                    key={item.eventKey}
                    eventKey={item.eventKey}
                    className="group"
                  >
                    <div
                      className={`${
                        item.eventKey === "Response" &&
                        !isConfirmedByAgentForDecision
                          ? `pointer-events-none opacity-50 cursor-not-allowed relative flex items-center h-full px-3`
                          : `relative flex items-center h-full px-3 cursor-pointer`
                      }`}
                    >
                      <span
                        data-tab-toggle
                        data-target={item.eventKey}
                        className="text-gray-500 group-[.active]:text-black transition-all duration-300 ease-linear"
                      >
                        {item.label}
                      </span>
                      <div className="absolute left-0 bottom-0 w-full h-0.5 bg-transparent group-[.active]:bg-black transition-all duration-300 ease-linear"></div>
                    </div>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </div>
        </div>

        {/* Content */}
        <Tab.Content className="overflow-y-auto">
          <Tab.Pane eventKey="Overview" id="Overview">
            <CaseResolveOverview caseData={caseData} />
          </Tab.Pane>
          <Tab.Pane eventKey="Documents" id="Documents">
            <CaseResolveDocuments
              caseId={caseId}
              organizationId={organizationId}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Timeline" id="Timeline">
            <CasesTimeLine
              caseTimelineData={caseTimelineData}
              isFetchingMore={isFetchingMore}
              caseId={caseId}
              organizationId={organizationId}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Claims" id="Claims">
            <Claims
              caseId={caseId}
              organizationId={organizationId}
              setRemedialData={setRemedialData}
              caseStatus={caseStatus}
              resetCaseResponseState={resetCaseResponseState}
              isAIResponseGenerated={isAIResponseGenerated}
              setIsRegenerateLoader={setIsRegenerateLoader}
              setIsAIResponseGeneratedLoader={setIsAIResponseGeneratedLoader}
              fetchCaseResponse={fetchCaseResponse}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Response" id="Response">
            <CaseResponse
              decisionValue={decisionValue}
              caseHighlights={caseHighlights}
              remedialData={remedialData}
              caseHighlightsDate={caseHighlightsDate}
              letterData={letterData}
              letterlist={letterlist}
              letterTitle={letterTitle}
              getLatestUpdatedAt={getLatestUpdatedAt}
              handleGenerateResponse={handleGenerateResponse}
              globalCaseStatus={globalCaseStatus}
              isRegenerateLoader={isRegenerateLoader}
              isAIResponseGenerated={isAIResponseGenerated}
              setIsAIResponseGeneratedLoader={setIsAIResponseGeneratedLoader}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default Cases;
