import callApi from "./apiService";
import { showNotification } from "../components/Toastify/Toast";
import storageService from "./storageService";
import {
  AGENT_ID,
  AUTH_USER,
  CASE_ID,
  ORGANIZATION_ID,
  TOKEN,
} from "../constants/enum";
import { INVALID_EMAIL, INVALID_PASSWORD } from "../constants/authConstants";
import { playbook } from "../constants/settingConstant";

export const attemptLogin = async (creds) => {
  let res;
  try {
    res = await callApi({
      endpoint: `v1/authentication/login`,
      method: "POST",
      body: {
        ...creds,
      },
    });

    if (res?.data?.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    let msg;
    if (e?.response?.data?.data?.message === "Invalid emailId") {
      msg = INVALID_EMAIL;
    } else if (e?.response?.data?.data?.message === "Invalid password") {
      msg = INVALID_PASSWORD;
    } else {
      msg = e?.response?.data?.data?.message;
    }
    showNotification(msg, "error");
  }
};

export const attemptForgotPasword = async (email) => {
  let res;

  try {
    res = await callApi({
      endpoint: `v1/authentication/forget-password`,
      method: "POST",
      body: email,
    });

    if (res?.data?.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const attemptResetPassword = async (creds) => {
  let res;
  try {
    res = await callApi({
      endpoint: `v1/authentication/reset-password`,
      method: "POST",
      body: {
        ...creds,
      },
    });

    if (res?.data?.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const fetchAgentInfo = async (organizationId, agentId) => {
  try {
    const res = await callApi({
      endpoint: `v1/profile/organization/${organizationId}/agent/${agentId}/info`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "success");
    }
  } catch (e) {
    showNotification(
      e.message || "An error occurred while fetching agent info"
    );
  }
};

export const fetchCaseList = async (organizationId, agentId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/agent/${agentId}/case-list`,
      method: "POST",
      body: {
        ...payload,
      },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "success");
    }
  } catch (e) {
    showNotification(
      e.message || "An error occurred while fetching agent info"
    );
  }
};

export const getCaseInfo = async (organizationId, caseId) => {
  try {
    let res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/info`,
      method: "GET",
    });
    if (res?.data?.status === "Success") {
      return res?.data?.data;
    } else {
      showNotification(
        res?.data?.data?.message || "Failed to fetch case info",
        "error"
      );
    }
  } catch (e) {
    showNotification(
      e?.response?.data?.message || "An error occurred",
      "error"
    );
  }
};

export const getCaseDocuments = async (organizationId, caseId, payload) => {
  try {
    const response = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/case-documents`,
      method: "POST",
      body: {
        ...payload,
      },
    });

    if (response?.data?.status === "Success") {
      return response?.data?.data;
    } else {
      showNotification(
        response?.data?.message || "Failed to fetch case documents",
        "error"
      );
    }
  } catch (error) {
    showNotification(
      error?.response?.data?.message ||
      "An error occurred while fetching case documents",
      "error"
    );
    throw error;
  }
};

export const createSignedRequest = async (documentUrl) => {
  try {
    const response = await callApi({
      endpoint: `v1/storage/create-signed-request`,
      method: "POST",
      body: {
        document_url: documentUrl,
      },
    });
    if (response?.data?.status === "Success") {
      return response.data.data;
    } else {
      showNotification(
        response?.data?.message || "Failed to create signed request",
        "error"
      );
    }
  } catch (error) {
    showNotification(
      error?.response?.data?.message ||
      "An error occurred while creating signed request",
      "error"
    );

    throw error;
  }
};

export const createDisputeCaseSignedRequest = async (
  documentUrl,
  caseId,
  organizationId
) => {
  try {
    const response = await callApi({
      endpoint: `v1/dispute-case/storage/organization/${organizationId}/create-signed-request?caseId=${caseId}`,
      method: "POST",
      body: {
        document_url: documentUrl,
      },
    });
    if (response?.data?.status === "Success") {
      return response.data.data;
    } else {
      showNotification(
        response?.data?.message || "Failed to create signed request",
        "error"
      );
    }
  } catch (error) {
    throw error;
  }
};

export const getCaseTimelines = async (organizationId, caseId, payload) => {
  try {
    const response = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/case-timelines`,
      method: "POST",
      body: {
        ...payload,
      },
    });

    if (response?.data?.status === "Success") {
      return response?.data?.data;
    } else {
      showNotification(
        response?.data?.message || "Failed to fetch case timelines",
        "error"
      );
    }
  } catch (error) {
    showNotification(
      error?.response?.data?.message ||
      "An error occurred while fetching case timelines",
      "error"
    );
    throw error;
  }
};

export const getCaseResponses = async (
  organizationId,
  caseId,
  pageNum,
  pageSize,
  filters = {}
) => {
  try {
    const response = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/case-responses`,
      method: "POST",

      body: {
        pageNum,
        pageSize,
        filters,
      },
    });

    if (response?.data?.status === "Success") {
      return response?.data?.data;
    } else {
      showNotification(
        response?.data?.message || "Failed to fetch case responses",
        "error"
      );
    }
  } catch (error) {
    showNotification(
      error?.response?.data?.message ||
      "An error occurred while fetching case responses",
      "error"
    );
    throw error;
  }
};

export const attemptCreateCase = async (
  organizationId,
  agentId,
  disputeCase
) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/agent/${agentId}/new-case`,
      method: "POST",
      body: {
        disputeCase: disputeCase,
      },
    });

    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "success");
    }
  } catch (e) {
    showNotification(
      e.message || "An error occurred while fetching agent info"
    );
  }
};

export const fetchClaimList = async (organizationId, caseId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/case-claims`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const fetchCaseResponse = async (organizationId, caseId) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/case-responses`,
      method: "POST",
      body: {
        pageNum: 1,
        pageSize: 20,
        filters: {},
      },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const fetchCaseClaimValidate = async (
  organizationId,
  claimId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case-claim/${claimId}/validate-claim`,
      method: "POST",
      body: {
        ...payload,
      },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const fetchMonetaryvalidate = async (
  organizationId,
  claimId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case-claim/${claimId}/validate-monetary-value`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const confirmRemedialActionByAgent = async (organizationId, claimId) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case-claim/${claimId}/confirm-remedial-actions`,
      method: "POST",
      body: {},
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const createRemedialAction = async (
  organizationId,
  claimId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/remedial-action/organization/${organizationId}/claim/${claimId}/create-remedial-action`,
      method: "POST",
      body: {
        remedialAction: payload,
      },
    });
    if (res.data.status === "Success") {
      showNotification("Create Succesfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const updateRemedialAction = async (
  organizationId,
  remedialId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/remedial-action/organization/${organizationId}/remedial-action/${remedialId}/edit-remedial-action`,
      method: "POST",
      body: {
        remedialAction: payload,
      },
    });
    if (res.data.status === "Success") {
      showNotification("Update Succesfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const updatesubclaiminfo = async (organizationId, claimId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case-claim/${claimId}/update-info`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const createsubclaim = async (organizationId, agentId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/agent/${agentId}/create-claim`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const fetchremedialactions = async (organizationId, caseId) => {
  try {
    const res = await callApi({
      endpoint: `v1/remedial-action/organization/${organizationId}/case/${caseId}/get-remedial-action`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const attemptTrigger = async (organizationId, caseId) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/trigger-case`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    const errorMessage = e?.response?.data?.data?.message;

    if (
      errorMessage ===
      "Case documents must include at least one for customer_evidence."
    ) {
      showNotification(
        "Case documents must include Primary Complaint",
        "error"
      );
    } else if (
      errorMessage ===
      "Case documents must include at least one for ombudsman_evidence."
    ) {
      showNotification(
        "Case documents must include Ombudsman Evidence",
        "error"
      );
    } else {
      showNotification(errorMessage, "error");
    }
  }
};

export const attemptPlaybook = async (organizationId) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/download-Playbook`,
      method: "POST",
      body: {
        organizationId,
      },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const getPlaybook = async (organizationId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/organization/${organizationId}/get-playbook-list`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {
      return res?.data?.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const getPlaybookCompaints = async (playbookId, organizationId) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/${playbookId}/organization/${organizationId}/get-playbook-complaints`,
      method: "POST",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const createPlaybook = async (organizationId, playbook) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/organization/${organizationId}/create-playbook`,
      method: "POST",
      body: {
        playbook,
      },
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const updatePlaybook = async (
  organizationId,
  playbookId,
  playbookData
) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/organization/${organizationId}/playbook/${playbookId}/update-info`,
      method: "POST",
      body: playbookData,
    });
    if (res.data.status === "Success") {
      showNotification("Playbook Updated Successfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const addPlaybookComplaint = async (
  organizationId,
  playbookId,
  complaintTypePlaybookData
) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/organization/${organizationId}/playbook/${playbookId}/new-playbook-complaint`,
      method: "POST",
      body: complaintTypePlaybookData,
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const updatePlaybookComplaint = async (
  organizationId,
  complaintTypePlaybookId,
  complaintTypePlaybookData
) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/organization/${organizationId}/complaint-type-playbook/${complaintTypePlaybookId}/update-playbook-complaint`,
      method: "POST",
      body: complaintTypePlaybookData,
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");
      return res.data;
    } else {
      showNotification(
        res?.data?.data?.message || "Something went wrong",
        "error"
      );

      return {
        error: true,
        message: res?.data?.data?.message || "Update failed",
      };
    }
  } catch (e) {
    const errorMessage =
      e?.response?.data?.message || e.message || "An error occurred";
    showNotification(errorMessage, "error");

    return { error: true, message: errorMessage };
  }
};

export const updatePlaybookStatus = async (
  organizationId,
  playbookId,
  statusBody
) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/organization/${organizationId}/playbook/${playbookId}/update-playbook-status`,
      method: "POST",
      body: statusBody,
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const fetchMemberList = async (organizationId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/members/organization/${organizationId}/member-list`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const addMember = async (organizationId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/authentication/organization/${organizationId}/create-agent`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      showNotification("Add Member Successfully", "success");
      return res.data;
    } else {
      showNotification("Failed", "error");
      return res.data;
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const updateMember = async (organizationId, id, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/members/organization/${organizationId}/agent/${id}/update-agent`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      showNotification("Updated Member Successfully", "success");
      return res.data;
    } else {
      showNotification("Failed", "error");
      return res.data;
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const deleteMember = async (organizationId, id, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/members/organization/${organizationId}/agent/${id}/remove-agent`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      showNotification("removed Successfully", "success");
      return res.data;
    } else {
      showNotification("Failed", "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const caseAgentGraph = async (organizationId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/report/organization/${organizationId}/cases-date-range`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const fetchagentCaseList = async (organizationId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/report/organization/${organizationId}/agents-case-list`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const deletePlaybook = async (organizationId, playbookId) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/organization/${organizationId}/playbook/${playbookId}/delete-playbook`,
      method: "DELETE",
      body: {},
    });
    if (res.data.status === "Success") {
      showNotification("Playbook Deleted Successfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const refreshResponse = async (caseId, organizationId) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/refresh-ai-response`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const createOrganization = async (payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/admin/create-organization`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res, "error");
    }
  } catch (e) {
    showNotification(e.response.data.data.message, "error");
  }
};

export const updateOrganization = async (organizationId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/admin/organization/${organizationId}/update-info`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const configResponse = async () => {
  try {
    const res = await callApi({
      endpoint: `config`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const attemptAdminLogin = async (creds) => {
  let res;
  try {
    res = await callApi({
      endpoint: `v1/authentication/admin/login`,
      method: "POST",
      body: {
        ...creds,
      },
    });

    if (res?.data?.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    let msg;
    if (e?.response?.data?.data?.message === "Invalid emailId") {
      msg = INVALID_EMAIL;
    } else if (e?.response?.data?.data?.message === "Invalid password") {
      msg = INVALID_PASSWORD;
    } else {
      msg = e?.response?.data?.data?.message;
    }
    showNotification(msg, "error");
  }
};

export const attemptAdminForgotPasword = async (email) => {
  let res;

  try {
    res = await callApi({
      endpoint: `v1/authentication/admin/forget-password`,
      method: "POST",
      body: email,
    });

    if (res?.data?.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const attemptAdminResetPassword = async (creds) => {
  let res;
  try {
    res = await callApi({
      endpoint: `v1/authentication/admin/reset-password`,
      method: "POST",
      body: {
        ...creds,
      },
    });

    if (res?.data?.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const fetchAdminInfo = async (adminId) => {
  try {
    const res = await callApi({
      endpoint: `v1/profile/admin/${adminId}/info`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "success");
    }
  } catch (e) {
    showNotification(
      e.message || "An error occurred while fetching agent info"
    );
  }
};

export const dashBoardStats = async () => {
  try {
    const res = await callApi({
      endpoint: `v1/report/admin/dashboard-stats`,
      method: "POST",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const fetchOrganizationList = async (payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/admin/organization/list`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const removeOrganization = async (id) => {
  try {
    const res = await callApi({
      endpoint: `v1/admin/organization/${id}/remove`,
      method: "POST",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const organizationDetail = async (organizationId) => {
  try {
    const res = await callApi({
      endpoint: `v1/admin/organization/${organizationId}/info`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const activeInactiveOrganizationDetail = async (
  organizationId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/admin/organization/${organizationId}/action`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const copyClonePlaybook = async (organizationId, playbookId) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/organization/${organizationId}/playbook/${playbookId}/clone-playbook`,
      method: "POST",
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const deleteSubclaims = async (organizationId, claimId) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case-claim/${claimId}/delete`,
      method: "DELETE",
      body: {},
    });
    if (res.data.status === "Success") {
      showNotification("Subclaim Deleted Successfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const deleteRemedialAction = async (organizationId, remedialId) => {
  try {
    const res = await callApi({
      endpoint: `v1/remedial-action/organization/${organizationId}/remedial-action/${remedialId}/delete-remedial-action`,
      method: "DELETE",
      body: {},
    });
    if (res.data.status === "Success") {
      showNotification("Remedial Action Deleted Successfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const escalateCase = async (organizationId, caseId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/escalate`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const generateResponse = async (organizationId, caseId) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/generate-response`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const deleteAdditionalRemedialAction = async (
  organizationId,
  remedialId
) => {
  try {
    const res = await callApi({
      endpoint: `v1/remedial-action/organization/${organizationId}/remedial-action/${remedialId}/delete-remedial-action`,
      method: "DELETE",
      body: {},
    });
    if (res.data.status === "Success") {
      showNotification("Remedial Action Deleted Successfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const confirmDisputeCase = async (organizationId, caseId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/dispute-case/${caseId}/confirm-all-actions`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const createAdditionalRemedialAction = async (
  organizationId,
  caseId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/remedial-action/organization/${organizationId}/case/${caseId}/additional-remedial-action`,
      method: "POST",
      body: {
        remedialAction: payload,
      },
    });
    if (res.data.status === "Success") {
      showNotification("Create Succesfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const updateAdditionalRemedialAction = async (
  organizationId,
  remedialId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/remedial-action/organization/${organizationId}/remedial-action/${remedialId}/edit-remedial-action`,
      method: "POST",
      body: {
        remedialAction: payload,
      },
    });
    if (res.data.status === "Success") {
      showNotification("Update Succesfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const confirmFinalDecision = async (organizationId, caseId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/final-decision`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};


export const getTotalMonetaryValue = async (organizationId, caseId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/dispute-case/${caseId}/get-total-monetary-value`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};
