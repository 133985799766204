import { Routes, Route } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./allRoutes";
import AuthProtected from "./protectedRoute";
import { Link } from "react-router-dom";
import Layouts from "./layouts";
import { Helmet } from "react-helmet";
import NotPublicIfAuthenticated from "../components/NotPublicIfAuthenticated";

const RouteIndex = () => {
  return (
    <>
      <Routes>
        {privateRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <>
                <Helmet>
                  <title>{route.title || "CTRL AI"}</title>
                </Helmet>
                <AuthProtected userTypeFromRoute={route?.userType}>
                  <Layouts userTypeFromRoute={route?.userType}>
                    <route.component />
                  </Layouts>
                </AuthProtected>
              </>
            }
          />
        ))}
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <>
                <Helmet>
                  <title>{route.title || "CTRL AI"}</title>
                </Helmet>
                <NotPublicIfAuthenticated>
                  <route.component />
                </NotPublicIfAuthenticated>
              </>
            }
          />
        ))}
        <Route
          path={"*"}
          element={
            <AuthProtected>
              <Layouts>
                <div className="h-[calc(100vh-72px)] mt-6 error-container flex items-center justify-center flex-col">
                  <h1> 404 </h1>
                  <p>Oops! The page you're looking for is not here.</p>
                  <Link to="/case-list">Go Back to Home</Link>
                </div>
              </Layouts>
            </AuthProtected>
          }
        />
      </Routes>
    </>
  );
};

export default RouteIndex;
