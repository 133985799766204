import React, { useEffect, useRef, useState } from "react";
import Icon from "../../../components/Icon/Icon";
import avatar from "../../../Images/Avtar.png";
import Button from "../../../components/Button/Button";
import { showNotification } from "../../../components/Toastify/Toast";
import { uploadProfileImage } from "../../../services/uploaderApi";
import { useOrganization } from "../../../context/OrganizationContext";
import { useUser } from "../../../context/UserContext";
import { createSignedRequest } from "../../../services/coreService";
import Loader from "../../../components/Loaders/Loader"

function Profile({ userInfo, fetchAgentDetails }) {
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false)
  const { agent } = useUser();
  const { organization } = useOrganization();
  const inputRef = useRef(null);

  useEffect(() => {
    if (userInfo?.profileUrl && organization?._id) {
      fetchSignedUrl(userInfo?.profileUrl, organization?._id);
    }
  }, [userInfo?.profileUrl, organization?._id]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleImageClick = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadImage(organization?._id, agent?._id, file);
    }
  };

  const uploadImage = async (organizationId, agentId, file) => {
    try {
      let res;
      res = await uploadProfileImage(organizationId, agentId, file);
      fetchAgentDetails()
      if (res?.status === "Success") {
        showNotification("uploaded successfully", "success");
      }
    } catch (error) {
      showNotification("not upload image", "error");
    }
  };

  const fetchSignedUrl = async (documentUrl) => {
    try {
      setLoading(true)

      const response = await createSignedRequest(documentUrl);
      setLoading(false);
      if (response.status === "Success") {
        const { signed_url } = response;
        setImage(signed_url);
      }
      return null;
    } catch (error) {
      console.error("Error fetching signed URL:", error);
      return null;
    }
  };

  return (
    <div className="flex flex-row gap-4 w-full">
      <div className="flex w-full text-center justify-center mx-5 my-5 ">
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Your Profile Information
          </h2>
          <div className="mt-10 p-6 border-2 border-gray-300 rounded-lg shadow-lg bg-white">

            <div className="grid grid-cols-2 gap-4">

              <div className="flex justify-start">
                <span className="text-md text-gray-800">Profile Image:</span>
              </div>
              {loading ?
                <Loader />
                :
                <div className="flex justify-start">
                  <div
                    className="w-10 h-10 rounded-full absolute overflow-hidden bg-gray-200 flex items-center justify-center"
                    onClick={handleClick}
                  >
                    {image ? (
                      <img
                        src={image}
                        alt="Avatar"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <img
                        src={avatar}
                        alt="Avatar"
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    ref={inputRef}
                    data-testid="file-input"
                    style={{ display: "none" }}
                    onChange={handleImageClick}
                  />
                </div>
              }
              <div className="flex justify-start">
                <span className="text-md text-gray-800">Name:</span>
              </div>
              <div className="flex justify-start">
                <span className="text-md text-gray-700">
                  {userInfo?.contactName}
                </span>
              </div>

              <div className="flex justify-start">
                <span className="text-md text-gray-800">Email:</span>
              </div>
              <div className="flex justify-start">
                <span className="text-md text-gray-700">
                  {userInfo?.contactEmail}
                </span>
              </div>

              <div className="flex justify-start">
                <span className="text-md text-gray-800">Phone:</span>
              </div>
              <div className="flex justify-start">
                <span className="text-md text-gray-700">
                  {userInfo?.contactPhone}
                </span>
              </div>

              <div className="flex justify-start">
                <span className="text-md text-gray-800">Organization ID:</span>
              </div>
              <div className="flex justify-start items-center flex-wrap">
                <p className="text-md text-gray-700 w-full sm:w-auto break-all">
                  {userInfo?.organizationId?._id}
                </p>
                <button
                  onClick={() => handleCopy(userInfo?.organizationId?._id)}
                  className="ml-2 text-gray-500 hover:text-gray-700"
                  aria-label="Click to copy"
                >
                  <Icon type="DocumentCopyIcon" />
                </button>
              </div>

              <div className="flex justify-start">
                <span className="text-md text-gray-800">
                  Organization Name:
                </span>
              </div>
              <div className="flex justify-start">
                <span className="text-md text-gray-700">
                  {userInfo?.organizationId?.registeredName}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
