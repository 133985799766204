import React, { useEffect, useState } from "react";
import Playbook from "./Playbooks/Playbook";
import { settingContent } from "../../../constants/settingConstant";
import { NavLink } from "react-router-dom";
import { Image } from "../../../components/Image/Image";
import Label from "../../../components/Label/Label";
import Rules from "../../../modules/utilities/Settings/rules";
import { AGENT_ID, ORGANIZATION_ID } from "../../../constants/enum";
import storageService from "../../../services/storageService";
import Member from "./Members/Member";
import { fetchAgentInfo, getPlaybook } from "../../../services/coreService";
import PermissionFunction from "../../../components/PermissionLocks/PermissionFunction";
import { AGENT_ROLE_ADMIN, AGENT_ROLE_SUPERADMIN } from "../../../constants/agentEnums";
import Reports from "./Reports/Reports";
import Profile from "./Profile";

import { useOrganization } from "../../../context/OrganizationContext";

const Settings = () => {

  const { settingsRoutesVisible } = useOrganization();
  const [data, setData] = useState(settingContent[0]?.label);
  const [userInfo, setUserInfo] = useState("");
  const [playbookData, setPlayBookData] = useState([]);

  const agentId = storageService.getItem(AGENT_ID);
  const organizationId = storageService.getItem(ORGANIZATION_ID);

  const [pageNum, setPageNum] = useState(1);
  const [count, setCount] = useState(0);


  const PAGE_SIZE = 50;
  const totalPages = Math.ceil(count / PAGE_SIZE);


  useEffect(() => {


    fetchAgentDetails();

  }, [organizationId, agentId, userInfo?.role]);




  const fetchAgentDetails = async () => {

    try {
      const res = await fetchAgentInfo(organizationId, agentId);

      if (res?.status === "Success") {
        const { agent } = res.data;
        setUserInfo(agent);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const fetchPlaybook = async () => {

      try {

        let payload = {
          pageNum: pageNum,
          pageSize: PAGE_SIZE,

        };

        const res = await getPlaybook(organizationId, payload);

        if (res?.message === "Playbook fetched Successfully.") {
          const { list, count } = res.playbookList;

          setPlayBookData((prev) => [...prev, ...list]);
          setCount(count);
        }
      } catch (error) {
        console.log(error);
      }
    }

    PermissionFunction(userInfo?.role, [AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN], fetchPlaybook);
  }, [organizationId, agentId, userInfo?.role, pageNum])

  return (
    <>
      <div className="w-[100%] ">
        <div className="grid grid-cols-[20.81%_calc(100%-20.81%)]">
          <div
            className="border-r border-[#E4E4E4] bg-[#FFF] "
            style={{
              height: "calc(100dvh - 74px)",
            }}
          >
            <div className="flex flex-col px-4 py-5 ">
              <Label
                className="font-inter text-xs font-medium leading-[140%] text-[#727272] pl-2 pb-2 mb-2 "
                htmlFor={"organisation"}
                text={"ORGANISATION SETTINGS"}
              />
              {settingContent.filter(item => settingsRoutesVisible?.includes(item.value)).map((item, i) => (
                <div
                  key={i}
                  className={`flex items-center cursor-pointer gap-3 self-stretch  p-2 h-9  rounded-[4px] ${item.label === data
                    ? "bg-[#F2F2F2] text-[#000]"
                    : "text-[#727272]"
                    }`}
                  onClick={() => setData(item.label)}
                >
                  <Image className={`w-[20px] h-[20px] `} src={item.icon} />
                  <Label
                    className="font-inter text-sm font-medium leading-[150%] cursor-pointer"
                    htmlFor={item.label}
                    text={item.label}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="  bg-[#FFF]">
            {data === settingContent[0].label && <Profile data={data} userInfo={userInfo} fetchAgentDetails={fetchAgentDetails}/>}
            {data === settingContent[1].label && <Member data={data} organizationId={organizationId} agentId={agentId} />}
            {data === settingContent[2].label && <Playbook data={data} playbookData={playbookData} organizationId={organizationId} setPlayBookData={setPlayBookData} setPageNum={setPageNum} pageNum={pageNum} totalPages={totalPages} />}
            {data === settingContent[3].label && <Rules />}
            {data === settingContent[4].label && <Reports organizationId={organizationId} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
