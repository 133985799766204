import {
  updateOrganization,
  createOrganization,
  fetchOrganizationList,
  removeOrganization,
} from "../../../services/coreService.js";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import storageService from "../../../services/storageService.js";
import { AGENT_ID, ORGANIZATION_ID, TOKEN } from "../../../constants/enum.js";
import { useUser } from "../../../context/UserContext.js";
import { showNotification } from "../../../components/Toastify/Toast.js";
import OrganizationTable from "./OrganizationTable.js";
import Overlay from "../../../components/Overlay/Overlay.jsx";
import CreateOrganisation from "../CreateOrganization/CreateOrganisation.js";
import Button from "../../../components/Button/Button.jsx";
import Loader from "../../../components/Loaders/Loader.jsx";
import {
  ACTIVE,
  CREATED_FIRST,
  CREATED_LAST,
  INACTIVE,
  MODIFIED_FIRST,
  MODIFIED_LAST,
} from "../../../constants/admindashboardConstants.js";

const OrganizationList = () => {
  const [isAddUpdateModalState, setAddUpdateModalState] = useState(false);
  const [organization, setOrganization] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [orgList, setOrgList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [count, setCount] = useState(0);

  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortOption, setSortOption] = useState("");

  const { agent } = useUser();

  const isNumeric = /^[0-9]+$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const PAGE_SIZE = 20;
  const totalPages = Math.ceil(count / PAGE_SIZE);

  const handleSortChange = (e) => {
    const newSortOption = e?.target?.value;
    setSortOption(newSortOption);
    setPageNum(1);
  };
  const handleAddUpdateModalOpen = (editMode = false, selectedMember = {}) => {
    setIsEditMode(editMode);
    setAddUpdateModalState(true);
    if (editMode) {
      setOrganization(selectedMember);
    } else {
      setOrganization({});
    }
  };
  const handleAddUpdateModalClose = () => {
    setAddUpdateModalState(false);
    setOrganization({});
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleStatusFilterChange = async (e) => {
    const newStatus = e.target.value;
    setSelectedStatus(newStatus);
    setPageNum(1);
  };

  useEffect(() => {
    fetchData(pageNum);
  }, [pageNum, selectedStatus, sortOption]);

  const fetchData = async (pageNum) => {
    let payload = {
      pageNum: pageNum,
      pageSize: PAGE_SIZE,
      filters: selectedStatus ? { status: selectedStatus } : {},
      sortBy: sortOption || "",
    };

    try {
      setLoading(true);
      const res = await fetchOrganizationList(payload);

      if (res?.status === "Success") {
        const { list, count } = res?.data;
        setCount(count);
        setOrgList(list);
      }
    } catch (error) {
      console.error("Failed to fetch case list:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteOrganization = async (id) => {
    try {
      let res;
      res = await removeOrganization(id);
      if (res?.status === "Success") {
        fetchData(pageNum);
        showNotification("Removed succesfully", "success");
      }
    } catch (error) {
      showNotification("Error occured", "error");
    }
  };

  const handleOrganizationValidation = (organization) => {
    let isValid = true;
    let errors = {};
    if (!!!organization.firstName || organization.firstName === "") {
      errors["firstName"] = "Please Enter First Name";
      isValid = false;
    }
    if (!!!organization.lastName || organization.lastName === "") {
      errors["lastName"] = "Please Enter Last Name";
      isValid = false;
    }
    if (!!!organization.contactName || organization.contactName === "") {
      errors["contactName"] = "Please Enter Contact Name";
      isValid = false;
    }
    if (!!!organization.username || organization.username === "") {
      errors["username"] = "Please Enter Username";
      isValid = false;
    }
    if (!!!organization.country || organization.country === "") {
      errors["country"] = "Please Enter Country";
      isValid = false;
    }
    if (!!!organization.address || organization.address === "") {
      errors["address"] = "Please Enter Address";
      isValid = false;
    }
    if (!!!organization.city || organization.city === "") {
      errors["city"] = "Please Enter City";
      isValid = false;
    }
    if (!emailRegex.test(organization.contactEmail)) {
      errors["contactEmail"] = "Please Enter Valid Email";
      isValid = false;
    }
    if (!organization?.countryCode || organization.countryCode === "") {
      errors["countryCode"] = "Please Enter a valid Country Code.";
      isValid = false;
    } else if (!/^(\+\d+)$/.test(organization.countryCode)) {
      errors["countryCode"] = "Please Enter a valid Country Code.";
      isValid = false;
    }
    if (!isNumeric.test(organization?.contactPhone)) {
      errors["contactPhone"] = "Please Enter Number";
      isValid = false;
    }

    if (!!!organization.employeeId || organization.employeeId === "") {
      errors["employeeId"] = "Please Enter Employee Id";
      isValid = false;
    }
    if (!!!organization.password || organization.password === "") {
      errors["password"] = "Please Enter Password";
      isValid = false;
    }
    if (!!!organization.taxId || organization.taxId === "") {
      errors["taxId"] = "Please Enter Tax Id";
      isValid = false;
    }
    if (!!!organization.registeredName || organization.registeredName === "") {
      errors["registeredName"] = "Please Enter Registered Name";
      isValid = false;
    }
    if (
      !!!organization.organizationCode ||
      organization.organizationCode === ""
    ) {
      errors["organizationCode"] = "Please Enter Organization Code";
      isValid = false;
    }

    return {
      isValid,
      errors,
    };
  };

  const handleOrganizationEditValidation = (organization) => {
    let isValid = true;
    let errors = {};

    if (!organization?.countryCode || organization.countryCode === "") {
      errors["countryCode"] = "Please Enter a valid Country Code.";
      isValid = false;
    } else if (!/^(\+\d+)$/.test(organization.countryCode)) {
      errors["countryCode"] = "Please Enter a valid Country Code.";
      isValid = false;
    }
    if (!isNumeric.test(organization?.contactPhone)) {
      errors["contactPhone"] = "Please Enter Number";
      isValid = false;
    }
    if (!!!organization.country || organization.country === "") {
      errors["country"] = "Please Enter Country";
      isValid = false;
    }
    if (!!!organization.address || organization.address === "") {
      errors["address"] = "Please Enter Address";
      isValid = false;
    }
    if (!!!organization.city || organization.city === "") {
      errors["city"] = "Please Enter City";
      isValid = false;
    }

    if (!!!organization.taxId || organization.taxId === "") {
      errors["taxId"] = "Please Enter Tax Id";
      isValid = false;
    }
    if (!!!organization.registeredName || organization.registeredName === "") {
      errors["registeredName"] = "Please Enter Registered Name";
      isValid = false;
    }
    if (
      !!!organization.organizationCode ||
      organization.organizationCode === ""
    ) {
      errors["organizationCode"] = "Please Enter Organization Code";
      isValid = false;
    }
    return {
      isValid,
      errors,
    };
  };
  const handleErrors = (errors) => {
    let arrayOfErrors = [];
    for (let item in errors) {
      arrayOfErrors.push(errors[item]);
    }
    // showNotification(arrayOfErrors[0], "error");
    setErrors(errors);
  };

  const handleAddOrganization = async () => {
    const updatedOrganization = {
      ...organization,
      countryCode: `+${organization.countryCode || ""}`,
    };

    const payload = { organization: updatedOrganization };
    let check = handleOrganizationValidation(payload.organization);
    if (check.isValid) {
      setIsLoading(true);
      const res = await createOrganization(payload.organization);
      setIsLoading(false);
      if (res?.status === "Success") {
        fetchData();
        handleAddUpdateModalClose();
        showNotification("Organisation created successfully", "success");
      } else {
        const errorMessage = res?.data?.message;
        showNotification(errorMessage, "error");
      }
    } else {
      handleErrors(check.errors);
    }
  };
  const handleUpdateOrganization = async () => {
    const updatedOrganization = {
      ...organization,
      countryCode: `+${organization.countryCode || ""}`,
    };
    try {
      const payload = { organization: updatedOrganization };
      let check = handleOrganizationEditValidation(payload.organization);
      if (check.isValid) {
        const res = await updateOrganization(organization._id, payload);
        if (res?.status === "Success") {
          fetchData();
          handleAddUpdateModalClose();
        } else {
          const errorMessage = res?.data?.message;
          showNotification(errorMessage, "error");
        }
      } else {
        handleErrors(check.errors);
      }
    } catch (error) {
      showNotification("Not added member. An error occurred.", "error");
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const range = 2;
    let start = pageNum - range;
    let end = pageNum + range;
    if (start < 1) {
      start = 1;
      end = Math.min(totalPages, start + 4);
    }
    if (end > totalPages) {
      end = totalPages;
      start = Math.max(1, end - 4);
    }
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const loadMoreCases = (page) => {
    if (page !== pageNum) {
      setPageNum(page);
      window.scrollTo(0, 0);
    }
  };

  const handleOrganisationChange = (keyName, keyValue) => {
    setOrganization((prev) => ({ ...prev, [keyName]: keyValue }));
    setErrors((prev) => ({ ...prev, [keyName]: "" }));
  };

  return (
    <>
      <div className="pt-8 pl-10 pr-6">
        <div>
          <div className="flex flex-row justify-end">
            <Button
              text="Create Organization"
              onClick={() => handleAddUpdateModalOpen(false)}
              className="bg-[#000000] px-3 py-1 text-[22px] text-[700] text-white"
              textSize={"13px"}
            />
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex items-center gap-2">
              <label
                htmlFor="filter-ss"
                className="font-inter text-sm font-medium text-[#727272]"
              >
                Filter By:
              </label>
              <select
                id="filter-ss"
                className="px-4 py-1 border bg-[#F2F2F2] rounded-[8px] font-inter text-sm font-small text-[#727272] cursor-pointer"
                value={selectedStatus}
                onChange={handleStatusFilterChange}
              >
                <option value="" className="cursor-pointer">
                  All Status
                </option>
                <option value={ACTIVE} className="cursor-pointer">
                  Active
                </option>
                <option value={INACTIVE} className="cursor-pointer">
                  InActive
                </option>
              </select>
            </div>

            <div className="flex items-center gap-2">
              <label
                htmlFor="sort-by-select"
                className="font-inter text-sm font-medium text-[#727272]"
              >
                Sort By:
              </label>
              <select
                id="sort-by-select"
                value={sortOption}
                onChange={handleSortChange}
                className="px-4 py-1 border bg-[#F2F2F2] rounded-[8px] font-inter text-sm font-small text-[#727272] cursor-pointer"
              >
                <option value="">All</option>
                <option value={CREATED_FIRST}>Created First</option>
                <option value={CREATED_LAST}>Created Last</option>
                <option value={MODIFIED_FIRST}>Modified First</option>
                <option value={MODIFIED_LAST}>Modified Last</option>
                <option value={ACTIVE}>Active </option>
                <option value={INACTIVE}>InActive </option>
              </select>
            </div>
          </div>
          {loading && pageNum === 1 ? (
            <div
              className="flex items-center justify-center mt-5"
              role="status"
              data-testid="spinner"
            >
              <Loader />
            </div>
          ) : (
            <>
              {orgList.length > 0 ? (
                <div className="mt-5">
                  <OrganizationTable
                    list={orgList}
                    handleUpdateOrganization={(selectedOrganization) =>
                      handleAddUpdateModalOpen(true, selectedOrganization)
                    }
                    deleteOrganization={deleteOrganization}
                    setOrgList={setOrgList}
                  />
                  <div className="flex justify-center mt-6 mb-20">
                    <nav aria-label="Page navigation">
                      <ul className="inline-flex items-center space-x-2">
                        <li>
                          <button
                            onClick={() => loadMoreCases(pageNum - 1)}
                            data-testid="load-more-button"
                            className={`px-4 py-2 text-sm font-medium rounded-full ${pageNum === 1
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-gray-200 hover:bg-black hover:text-white text-black"
                              }`}
                            disabled={pageNum === 1}
                          >
                            &lt;
                          </button>
                        </li>
                        {getPageNumbers().map((page) => (
                          <li key={page}>
                            <button
                              onClick={() => loadMoreCases(page)}
                              data-testid="load-more-button"
                              className={`px-4 py-2 text-sm font-medium rounded-full ${page === pageNum
                                ? "bg-black text-white"
                                : "bg-gray-200 hover:bg-black hover:text-white text-black"
                                }`}
                            >
                              {page}
                            </button>
                          </li>
                        ))}
                        <li>
                          <button
                            onClick={() => loadMoreCases(pageNum + 1)}
                            data-testid="load-more-button"
                            className={`px-4 py-2 text-sm font-medium rounded-full ${pageNum === totalPages
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-gray-200 hover:bg-black hover:text-white text-black"
                              }`}
                            disabled={pageNum === totalPages}
                          >
                            &gt;
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex flex-row justify-center items-center h-full mt-[20%]">
                    <p className="text-[20px] text-black font-semibold">
                      No Data
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Overlay
        isOpen={isAddUpdateModalState}
        onClose={handleAddUpdateModalClose}
        title={isEditMode ? "Edit Organization " : "Add Organization"}
        position="center"
        style={{
          maxHeight: "600px",
          overflow: "hidden",
        }}
      >
        <div className="flex flex-col h-full">
          <div
            style={{
              maxHeight: "400px",
              overflowY: "scroll",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              WebkitOverflowScrolling: "touch",
              WebkitScrollbar: "none",
              flexGrow: 1,
            }}
          >
            <CreateOrganisation
              handleOrganisationChange={handleOrganisationChange}
              errors={errors}
              isEditMode={isEditMode}
              organization={organization}
              handleShowPassword={handleShowPassword}
              showPassword={showPassword}
            />
          </div>

          <div className="flex flex-row justify-between gap-6 px-8 mt-8">
            <Button
              className="w-full bg-[#F2F2F2] px-4 py-2"
              text="Cancel"
              textcolor="#000000"
              onClick={() => handleAddUpdateModalClose()}
            />
            {!isLoading ? (
              <Button
                className="w-full bg-[#000000] text-white px-4 py-2"
                text={isEditMode ? "Update" : "Add"}
                onClick={
                  isEditMode ? handleUpdateOrganization : handleAddOrganization
                }
                data-testid="update-organization-button"
              />
            ) : (
              <div className="w-full bg-[#fff] text-white px-4 py-2">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </Overlay>
    </>
  );
};

export default OrganizationList;
