import { Divider } from "../Divider/Divider";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Image } from "../Image/Image";
import bellIcon from "../../Images/Bell.svg";
import warningIcon from "../../Images/WarningOctagon.svg";
import downarrowIcon from "../../Images/DownArrow.svg";
import { content } from "../../constants/headerConstants";
import {
  AGENT_ID,
  ORGANIZATION_ID,
  AUTH_USER,
  USER_TYPE,
  ADMIN_ID,
} from "../../constants/enum";
import { fetchAgentInfo } from "../../services/coreService";
import { attemptLogout } from "../../services/loginUtility";
import storageService from "../../services/storageService";
import { SIGNIN_ROUTE, ADMIN_SIGNIN_ROUTE } from "../../constants/routeEnums";
import { useOrganization } from "../../context/OrganizationContext";
import { useUser } from "../../context/UserContext";
import { USER_TYPE_ADMIN } from "../../constants/enum";
import { fetchAdminInfo } from "../../services/coreService";

const Header = () => {

  const { updateAdmin, admin, updateAgent, agent } = useUser();
  const [dropdownActive, setDropdownActive] = useState(false);

  const userType = storageService.getItem(USER_TYPE)

  let agentId, organizationId, adminId;

  const [userInfo, setUserInfo] = useState(null);

  const memoryAgentId = agent?._id;
  const { updateOrganization, isFeedbackFormVisibleToUsers } = useOrganization();


  const memoryAdminId = admin?._id;

  const dropdownRef = useRef(null);

  if (userType === USER_TYPE_ADMIN) {
    adminId = storageService.getItem(ADMIN_ID);
  }
  else {
    agentId = storageService.getItem(AGENT_ID);
    organizationId = storageService.getItem(ORGANIZATION_ID);
  }


  useEffect(() => {
    if (agentId && memoryAgentId) {
      const handleVisibilityChange = () => {
        agentId = storageService.getItem(AGENT_ID);

        if (memoryAgentId !== agentId) {
          window.location.reload();
        }
      };
      window.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        window.removeEventListener("visibilitychange", handleVisibilityChange);
      };
    }
  }, [agentId, memoryAgentId]);



  useEffect(() => {
    if (adminId && memoryAdminId) {
      const handleVisibilityChange = () => {
        adminId = storageService.getItem(ADMIN_ID);
        if (memoryAdminId !== adminId) {
          window.location.reload();
        }
      };
      window.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
        window.removeEventListener("visibilitychange", handleVisibilityChange);
      };
    }
  }, [adminId, memoryAdminId]);





  useEffect(() => {
    const fetchData = async (organizationId, agentId) => {
      const res = await fetchAgentInfo(organizationId, agentId);
      if (res?.status === "Success") {
        const { agent } = res.data;
        setUserInfo(agent);
        updateAgent(agent);
        updateOrganization(agent.organizationId);
        storageService.setItem(AUTH_USER, agent);
      }
    };

    if (organizationId && agentId) fetchData(organizationId, agentId);
  }, [organizationId, agentId]);


  useEffect(() => {
    const fetchData = async (adminId) => {
      const res = await fetchAdminInfo(adminId);
      if (res?.status === "Success") {
        const { admin } = res.data;
        setUserInfo(admin);
        updateAdmin(admin);

        storageService.setItem(AUTH_USER, admin);
      }
    };

    if (adminId) fetchData(adminId);
  }, [adminId]);

  useEffect(() => {
    const handleClickOutside = (event) => {

      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest('[data-testid="logout-link"]')
      ) {
        setDropdownActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <>
      <div className="flex w-[100%-17.22%] h-[72px] px-8 justify-end items-center gap-2.5">
        <div className="flex">
        {isFeedbackFormVisibleToUsers ?
          <div className="flex items-center justify-center gap-1 p-1 px-2 rounded-[4px] bg-[#FBF4EC] border-[#FBF4EC]">
            <Image src={warningIcon} alt="Warning Icon" />
            <span className="font-inter text-sm font-normal leading-[150%] text-[#D28E3D]">
              {content.heading}
            </span>
             <a
              className="font-inter text-sm font-normal leading-[150%] text-[#4976F4] underline decoration-solid decoration-auto decoration-[#4976F4] text-underline-offset-auto"
              href={content.target}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="External link for more info"
            >
              {content.link}
            </a>
          </div>
           : 
           null}
          <div
            className="flex w-[39px] p-2 items-center gap-3 self-stretch mr-8 ml-8 cursor-pointer"
            data-testid="bell-icon"
            aria-label="Notifications"
          >
            <Image src={bellIcon} />
          </div>


          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => setDropdownActive(!dropdownActive)}
            aria-haspopup="true"
            aria-expanded={dropdownActive}
            data-testid="user-dropdown"
            ref={dropdownRef}

          >
            {userInfo ? (
              <span >{userInfo.username}</span>
            ) : (
              <span>Loading...</span>
            )}
            <Image src={downarrowIcon} />
          </div>

        </div>
      </div>

      {dropdownActive && (
        <div
          className="fixed top-[40px] right-0 bg-white w-full max-w-max px-8 py-4 m-4 z-20 shadow-md border border-t-gray-100"
          aria-label="User menu"
          data-testid="user-menu"
        >
          <Link
            to={userType === USER_TYPE_ADMIN ? ADMIN_SIGNIN_ROUTE : SIGNIN_ROUTE}
            onClick={() => attemptLogout()}
            data-testid="logout-link"
            aria-label="Logout"
          >
            Logout
          </Link>
        </div>
      )}
    </>
  );
};

export default Header;
