import React, { useState, useEffect, useRef } from "react";
import FileViewer from "../../../../components/FileViewer/FileViewer";
import {
  getCaseDocuments,
  createDisputeCaseSignedRequest,
} from "../../../../services/coreService";
import {
  EVIDENCE_CATEGORY_CUSTOMER_LABEL,
  EVIDENCE_CATEGORY_CUSTOMER,
  EVIDENCE_CATEGORY_INTERNAL,
  EVIDENCE_CATEGORY_INTERNAL_LABEL,
  EVIDENCE_CATEGORY_OMBUDSMAN,
  EVIDENCE_CATEGORY_OMBUDSMAN_LABEL,
} from "../../../../constants/disputeCaseEnums";
import Loader from "../../../../components/Loaders/Loader";
import Switch2_0 from "../../../../components/SwitchV2/SwitchV2";

import { formatDateToDDMMYYYY } from "../../../../utils/dateFormat";

const CaseResolveDocuments = ({ organizationId, caseId }) => {
  const [groupedDocuments, setGroupedDocuments] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [count, setCount] = useState(0);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const PAGE_SIZE = 50;
  const totalPages = Math.ceil(count / PAGE_SIZE);

  const sortCategories = (grouped) => {
    const order = [
      EVIDENCE_CATEGORY_CUSTOMER,
      EVIDENCE_CATEGORY_OMBUDSMAN,
      EVIDENCE_CATEGORY_INTERNAL,
    ];

    return Object.keys(grouped).sort((a, b) => {
      const indexA = order.indexOf(a);
      const indexB = order.indexOf(b);
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
  };

  const fetchDocuments = async () => {
    try {
      let payload = {
        pageNum: pageNum,
        pageSize: PAGE_SIZE,
        filter: {},
      };

      if (pageNum === 1) {
        setLoading(true);
      } else {
        setIsFetchingMore(true);
      }

      const response = await getCaseDocuments(organizationId, caseId, payload);
      const documents = response.caseDocuments || [];
      const totalCount = response.totalCount;

      const grouped = documents.reduce((acc, doc) => {
        const category = doc.evidenceCategory || "Uncategorized";
        if (!acc[category]) acc[category] = [];
        acc[category].push(doc);
        return acc;
      }, {});

      setCount(totalCount);
      setGroupedDocuments((prevGroupedDocuments) => ({
        ...prevGroupedDocuments,
        ...grouped,
      }));

      const sortedCategories = sortCategories({
        ...groupedDocuments,
        ...grouped,
      });

      setCategories(sortedCategories);

      if (pageNum === 1 && documents.length > 0) {
        const firstCategory = EVIDENCE_CATEGORY_CUSTOMER;
        const firstDocument = grouped[firstCategory][0];
        setSelectedDocument(firstDocument);

        const signedUrl = await fetchSignedUrl(firstDocument.documentUrl);
        if (signedUrl) {
          setSelectedDocument((prevDoc) => ({
            ...prevDoc,
            signedUrl: signedUrl,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching Case Documents:", error);
    }

    setLoading(false);
    setIsFetchingMore(false);
  };

  useEffect(() => {
    fetchDocuments();
  }, [pageNum, caseId]);

  const fetchSignedUrl = async (documentUrl) => {
    try {
      const response = await createDisputeCaseSignedRequest(documentUrl, caseId, organizationId);

      if (response.status === "Success") {
        return response.signed_url;
      }
      return null;
    } catch (error) {
      console.error("Error fetching signed URL:", error);
      return null;
    }
  };

  const handleSelectDocument = async (doc) => {
    if (!doc) return;
    setSelectedDocument(doc);
    setDocumentLoading(true);

    const signedUrl = await fetchSignedUrl(doc.documentUrl);

    if (signedUrl) {
      setSelectedDocument((prevDoc) => ({
        ...prevDoc,
        signedUrl: signedUrl,
      }));
    }
    setDocumentLoading(false);
  };

  const handleScroll = (e) => {
    const scrollableElement = e.target;

    if (
      scrollableElement.scrollHeight - scrollableElement.scrollTop <=
      scrollableElement.clientHeight + 20 && pageNum < totalPages
    ) {
      if (!isFetchingMore) {
        setIsFetchingMore(true);

        setPageNum((prevPageNum) => prevPageNum + 1);
      }
    }
  };


  const renderDocuments = () => {
    return (
      <div >
        {categories.map((category, idx) => (
          <div key={idx}>
            <div className="flex px-[32px] pt-[20px] pb-[12px] items-center self-stretch border-b border-[#E4E4E4] ">
              <span className="text-[#AFAFAF] font-inter text-[14px] font-medium leading-[150%]">
                {displayCategoryName(category)}
              </span>
            </div>
            {groupedDocuments[category].map((doc) => (
              <div
                key={doc._id}
                className={`flex flex-col px-[16px] pt-[16px] pb-[16px] gap-[12px] self-stretch border-b border-[#E4E4E4] cursor-pointer ${selectedDocument?._id === doc._id
                  ? "bg-[#F2F2F2]"
                  : "bg-[#F9F9F9]"
                  }`}
                onClick={() => handleSelectDocument(doc)}
              >
                <span className="text-[#000] font-inter text-[16px] font-medium leading-[145%]">
                  {doc.documentName || "NA"}
                </span>
              </div>
            ))}
          </div>
        ))}
        {isFetchingMore && (
          <div className="flex justify-center py-4" data-testid="Loader">
            <Loader />
          </div>
        )}
      </div>
    );
  };


  const displayCategoryName = (category) => {
    switch (category) {
      case EVIDENCE_CATEGORY_CUSTOMER:
        return EVIDENCE_CATEGORY_CUSTOMER_LABEL;
      case EVIDENCE_CATEGORY_INTERNAL:
        return EVIDENCE_CATEGORY_INTERNAL_LABEL;
      case EVIDENCE_CATEGORY_OMBUDSMAN:
        return EVIDENCE_CATEGORY_OMBUDSMAN_LABEL;
      default:
        return category;
    }
  };

  return (
    < div style={{ overflowY: "auto" }}>

      <div className="flex justify-center w-full h-full">

        {loading ? (
          <div className="flex items-center justify-center h-[80vh]" data-testid="Loader">
            <Loader />
          </div>
        ) :
          <div
            className="flex w-full">
            <div onScroll={(e) => handleScroll(e)} className="w-[30%] border-r border-[var(--Color-Tokens-Border-Primary,#E4E4E4)] bg-[var(--Color-Tokens-Background-Primary,#FFF)]  overflow-y-auto h-[650px]" >
              {renderDocuments()}
            </div>

            <div className="w-[70%]">
              {selectedDocument ? (
                <>
                  <div className="flex justify-between items-center pt-[32px] pb-[24px] px-[32px] border-b border-[#E4E4E4]">
                    <h6
                      className="font-inter text-[20px] font-medium leading-[120%] w-[80%] "
                      style={{
                        color: "var(--Color-Tokens-Content-Dark-Primary, #000)",
                      }}
                    >
                      {selectedDocument?.aiSummaryTitle || "NA"}
                    </h6>
                    <div className="flex items-center gap-2">
                      <Switch2_0 initialState="false" date={formatDateToDDMMYYYY(selectedDocument?.updatedAt)} />
                    </div>
                  </div>
                  <div className="w-full pt-[24px] pb-[24px] px-[32px]">
                    <div className="flex items-center justify-between w-full">
                      <h6 className="text-[var(--Color-Tokens-Content-Dark-Primary, #000)] font-inter text-[16px] font-normal leading-[150%]">
                        Summary
                      </h6>
                      <div className="flex items-center gap-2">
                        <Switch2_0 initialState="false" date={formatDateToDDMMYYYY(selectedDocument?.updatedAt)} />
                      </div>
                    </div>
                    <ul className="list-disc pl-5 text-[#727272] font-inter text-[14px] font-normal leading-[150%] mt-4">
                      {Array.isArray(selectedDocument?.aiSummaryDescription)
                        ? selectedDocument?.aiSummaryDescription.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))
                        : <li>{selectedDocument?.aiSummaryDescription || "NA"}</li>}
                    </ul>
                  </div>

                  <div className="flex w-full p-[32px] flex-col items-center gap-[12px] flex-1 bg-[#F2F2F2]">
                    {documentLoading ? (
                      <div className="flex items-center justify-center h-[80vh]" data-testid="Loader">
                        <Loader />
                      </div>
                    ) : selectedDocument.signedUrl && (
                      <FileViewer
                        file={{
                          url: selectedDocument.signedUrl,
                          type: selectedDocument.documentType || "NA",
                        }}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="w-full py-8 text-center">No document selected</div>
              )}
            </div>

          </div>
        }

      </div>
      <div>

      </div>
    </div>

  );
};

export default CaseResolveDocuments;

