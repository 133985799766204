import React, { useState } from "react";
import ComplaintType from "./ComplaintType.js";
import Loader from "../../../../components/Loaders/Loader.jsx";
import Icon from "../../../../components/Icon/Icon.jsx";
import Label from "../../../../components/Label/Label.jsx";
import { Divider } from "../../../../components/Divider/Divider.js";
import { calculateWordCount } from "../../../../utils/countWordsLeft.js";
import {
  APOLOGYLETTERGUIDANCE_MAX_LIMIT,
  COMPLAINTCLASSIFYINGCRITERIA_MAX_LIMIT,
} from "../../../../constants/playBookInputLimit.js";
import { displayComplaintName } from "../../../../utils/displayComplaintName.js";
import {
  AGENT_ROLE_SUPERADMIN,
  AGENT_ROLE_ADMIN,
} from "../../../../constants/agentEnums.js";
import trash from "../../../../Images/trashicon.svg";
import disabledtrash from "../../../../Images/disabledtrashicon.svg";
import { useUser } from "../../../../context/UserContext.js";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch";
import { useOrganization } from "../../../../context/OrganizationContext.js";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { truncate } from "lodash";
import { CLONE_PLAYBOOK_TEXT } from "../../../../constants/playbookComplaintsTypeConstants.js";
import InputForm from "../../../../components/InputForm/InputForm.js";

export default function PlaybookDetails({
  selectedPlaybook,
  setSelectedPlaybook,
  selectedPlaybookStatus,
  handlePlaybookStatus,
  playbookStatusLoader,
  editApologyPlaybookLoading,
  isEditableApology,
  toggleEditableApology,
  handleOpenAddComplaintModal,
  handleOpenEditModal,
  handleUpdatePlaybook,
  editCompaintPlaybookLoading,
  toggleEditableComplaint,
  isEditableComplaint,
  expandedComplaints,
  playbookComplaintsData,
  toggleComplaintDetails,
  handleRemovingPlaybook,
  handleClickCopyClone,
  isLoading,
  setPlaybookTitle,
  playbooktitle,
  editplaybooktitleLoading,
}) {
  const { agent } = useUser();
  const [title, setChangeTitle] = useState(false);
  const { organization } = useOrganization();
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const apologyLetterGuidanceWordCount = calculateWordCount(
    selectedPlaybook?.apologyLetterGuidance || "",
    APOLOGYLETTERGUIDANCE_MAX_LIMIT
  );
  const complaintClassifyingCriteriaWordCount = calculateWordCount(
    selectedPlaybook?.complaintClassifyingCriteria || "",
    COMPLAINTCLASSIFYINGCRITERIA_MAX_LIMIT
  );

  const removePlaybook = (playbookId) => {
    handleRemovingPlaybook(playbookId);
  };
  const handleChangeTitle = () => {
    setChangeTitle(true);
  };

  const handleCancelTitle = () => {
    setPlaybookTitle(selectedPlaybook?.name || "");
    setChangeTitle(false);
  };

  const handleSaveTitle = () => {
    handleUpdatePlaybook("playbooktitlechange");
    setChangeTitle(false);
  };

  return (
    <>
      <div className="flex w-[100%-27.22%] h-[72px] pl-5 pt-5 pb-1 px-8 items-center gap-2.5 text-[#000] justify-between">
        <div className="flex flex-col">
          {title ? (
            <div className="flex flex-row gap-2">
              <input
                value={playbooktitle}
                onChange={(e) => setPlaybookTitle(e.target.value)}
                className="w-full px-2 py-1 text-gray-700 placeholder-gray-400 transition duration-200 ease-in-out border rounded-lg focus:outline-none focus:ring-2 mb-1"
              />
              <span
                onClick={() => handleSaveTitle()}
                className="self-center cursor-pointer text-[14px] text-blue-500"
              >
                Save
              </span>
              <span
                onClick={() => handleCancelTitle()}
                className="self-center cursor-pointer text-[14px] text-blue-500"
              >
                Cancel
              </span>
            </div>
          ) : (
            <div className="flex flex-row gap-2">
              <span className="text-[20px]">
                {truncate(selectedPlaybook?.name, { length: 60 })}
              </span>

              {editplaybooktitleLoading ? (
                <Loader />
              ) : (
                <div
                  onClick={() => handleChangeTitle()}
                  className="self-center cursor-pointer"
                >
                  <Icon type="EditIcon" className="h-3 w-3 self-center ml-1" />
                </div>
              )}
            </div>
          )}

          <p className="font-inter text-[12px] text-[#727272]">
            {formatDate(selectedPlaybook?.updatedAt)}
          </p>
        </div>

        <div className="flex items-center gap-2">
          {isLoading ? (
            <Loader />
          ) : (
            <div
              data-tooltip-id="copy-clone-playbook"
              className="self-center cursor-pointer"
              onClick={() =>
                handleClickCopyClone(organization?._id, selectedPlaybook?._id)
              }
            >
              <Icon type="DocumentCopyIcon" />
            </div>
          )}
          <ReactTooltip
            id="copy-clone-playbook"
            place="bottom"
            content={CLONE_PLAYBOOK_TEXT}
          />
          <PermissionSwitch
            shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN]}
            hasRole={agent?.role}
            yes={
              <>
                <button
                  onClick={() => removePlaybook(selectedPlaybook?._id)}
                  className="p-1.5 text-white bg-transparent border-none cursor-pointer"
                >
                  <img src={trash} alt="Delete" />
                </button>
                <p
                  className={`flex items-center justify-center gap-2 px-2 py-1 rounded-[36px] opacity-75 font-inter text-[12px] font-medium leading-[140%] ${selectedPlaybookStatus === "Active"
                    ? "bg-[#E8F9E6] text-[#4CAF50]"
                    : "bg-[#E4E4E4] text-[#8A8A8A]"
                    }"}`}
                >
                  {selectedPlaybook?.isActive ? "Active" : "Inactive"}
                </p>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    handlePlaybookStatus(
                      selectedPlaybook?._id,
                      selectedPlaybook?.isActive ? "deactivate" : "activate"
                    )
                  }
                >
                  {playbookStatusLoader ? (
                    <Loader />
                  ) : selectedPlaybook?.isActive ? (
                    <Icon
                      type="ToggleOnIcon"
                      className="text-[#4CAF50] h-5 w-6"
                    />
                  ) : (
                    <Icon type="ToggleOffIcon" className="h-5 w-6" />
                  )}
                </div>
              </>
            }
            no={
              <>
                <button
                  className="p-1.5 text-white bg-transparent border-none"
                  disabled={true}
                >
                  <img src={disabledtrash} alt="Delete" />
                </button>
                <p
                  className={`flex items-center justify-center gap-2 px-2 py-1 rounded-[36px] opacity-75 font-inter text-[12px] font-medium leading-[140%] ${selectedPlaybookStatus === "Active"
                    ? "bg-[#E8F9E6] text-[#4CAF50]"
                    : "bg-[#E4E4E4] text-[#8A8A8A]"
                    }"}`}
                >
                  {selectedPlaybook?.isActive ? "Active" : "Inactive"}
                </p>
                <div className="cursor-pointer">
                  {playbookStatusLoader ? (
                    <Loader />
                  ) : selectedPlaybook?.isActive ? (
                    <Icon
                      type="ToggleOnIcon"
                      className="text-[#4CAF50] h-5 w-6"
                    />
                  ) : (
                    <Icon type="ToggleOffIcon" className="h-5 w-6" />
                  )}
                </div>
              </>
            }
          />
        </div>
      </div>
      <Divider />

      <div
        style={{ height: "calc(100dvh - 148px)", overflowY: "auto" }}
        className="p-4"
      >
        {/* Apology and Complaint Classifying Criteria */}
        <div className="flex flex-col">
          <div className="mb-6">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272] mb-2"
              htmlFor="apologyLetterGuidance"
              text="Apology Criteria"
            />
            {editApologyPlaybookLoading ? (
              <button className="ml-2">
                <Loader />{" "}
              </button>
            ) : (
              <button
                type="button"
                className="ml-2 text-blue-500"
                onClick={() => toggleEditableApology(selectedPlaybook?._id)}
              >
                {!isEditableApology[selectedPlaybook?._id] ? (
                  <Icon type="EditIcon" className="h-3 w-3" />
                ) : (
                  <div className="flex gap-2">
                    <span
                      onClick={() =>
                        handleUpdatePlaybook("apologyLetterGuidance")
                      }
                      className="text-[14px]"
                      data-testid="save-button"
                    >
                      Save
                    </span>
                    <span className="text-[14px]" data-testid="cancel-button">Cancel</span>
                  </div>
                )}
              </button>
            )}
            {isEditableApology[selectedPlaybook?._id] && (
              <p className="text-[14px] text-red-500 float-right">
                {apologyLetterGuidanceWordCount > 0
                  ? `${apologyLetterGuidanceWordCount} words left`
                  : `Word limit reached`}
              </p>
            )}
            <textarea
              id="apologyLetterGuidance"
              data-testid="apologyLetterGuidance"
              className="mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none"
              placeholder="Enter Apology Criteria (Limit: 2000 words)"
              value={selectedPlaybook?.apologyLetterGuidance}
              rows={6}
              onChange={(e) => {
                const wordCount = calculateWordCount(
                  e.target.value,
                  APOLOGYLETTERGUIDANCE_MAX_LIMIT
                );
                if (wordCount >= 0) {
                  setSelectedPlaybook({
                    ...selectedPlaybook,
                    apologyLetterGuidance: e.target.value,
                  });
                }
              }}
              disabled={!isEditableApology[selectedPlaybook?._id]}
            />
          </div>

          <div className="mb-6">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272] mb-2"
              htmlFor="complaintClassifyingCriteria"
              text="Complaint Classifying Criteria"
            />
            {editCompaintPlaybookLoading ? (
              <button className="ml-2">
                <Loader />{" "}
              </button>
            ) : (
              <button
                type="button"
                className="ml-2 text-blue-500"
                onClick={() => toggleEditableComplaint(selectedPlaybook?._id)}
              >
                {!isEditableComplaint[selectedPlaybook?._id] ? (
                  <Icon type="EditIcon" className="h-3 w-3" />
                ) : (
                  <div className="flex gap-2">
                    <span
                      onClick={() =>
                        handleUpdatePlaybook("complaintClassifyingCriteria")
                      }
                      className="text-[14px]"
                      data-testid="save-button"
                    >
                      Save
                    </span>
                    <span className="text-[14px]" data-testid="cancel-button">Cancel</span>
                  </div>
                )}
              </button>
            )}
            {isEditableComplaint[selectedPlaybook?._id] && (
              <p className="text-[14px] text-red-500 float-right">
                {complaintClassifyingCriteriaWordCount > 0
                  ? `${complaintClassifyingCriteriaWordCount} words left`
                  : `Word limit reached`}
              </p>
            )}
            <textarea
              id="complaintClassifyingCriteria"
              data-testid="complaintClassifyingCriteria"
              className="mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none"
              placeholder="Enter Complaint Classifying Criteria (Limit: 500 words)"
              value={selectedPlaybook?.complaintClassifyingCriteria}
              rows={6}
              onChange={(e) => {
                const wordCount = calculateWordCount(
                  e.target.value,
                  COMPLAINTCLASSIFYINGCRITERIA_MAX_LIMIT
                );
                if (wordCount >= 0) {
                  setSelectedPlaybook({
                    ...selectedPlaybook,
                    complaintClassifyingCriteria: e.target.value,
                  });
                }
              }}
              disabled={!isEditableComplaint[selectedPlaybook?._id]}
            />
          </div>
        </div>

        {/* Complaint Types */}

        <div className="mb-10">
          <div className="flex items-center mb-4 space-x-2">
            <Label
              className="font-inter text-[16px] font-medium text-[#727272]"
              htmlFor={"complaintTypes"}
              text={"Complaint Types"}
            />
            <Icon
              className="cursor-pointer w-5 h-5"
              type="PlusIcon"
              onClick={handleOpenAddComplaintModal}
            />
          </div>
          <div
            className="flex flex-col gap-3 mt-2"
            data-testid="ExpandedContent"
          >
            {playbookComplaintsData?.map((complaint) => (
              <div key={complaint?._id} className="flex flex-col">
                <div
                  className={`flex items-center justify-between p-3 bg-[#F9F9F9] border border-[#E4E4E4] rounded-[4px] cursor-pointer ${expandedComplaints?.includes(complaint?._id)
                    ? "bg-[#F2F2F2]"
                    : ""
                    }`}
                  onClick={() => toggleComplaintDetails(complaint?._id)}
                >
                  <div className="flex items-center gap-3">
                    <span className="font-inter text-[14px] font-medium text-[#000]">
                      {displayComplaintName(complaint?.typeOfComplaint)}
                    </span>
                    <Icon
                      type="EditIcon"
                      onClick={() => handleOpenEditModal(complaint)}
                      className="h-3 w-3"
                    />
                  </div>

                  {expandedComplaints?.includes(complaint?._id) ? (
                    <Icon type="ArrowUpIcon" className="text-[#727272]" />
                  ) : (
                    <Icon type="ArrowDownIcon" className="text-[#727272]" />
                  )}
                </div>
                {expandedComplaints?.includes(complaint?._id) && (
                  <ComplaintType complaint={complaint} />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
