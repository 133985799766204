import react from "react";
import {
  CRITERIA_INDENTIFY_SUBCLAIMS,
  CRITERIA_ASSESS_EVIDENCE,
  REMEDIAL_ACTION_GUIDANCE,
  MONETARY_VALUE_GUIDANCE,
} from "../../../../constants/complaintTypesSubkeys";

export default function ComplaintType({ complaint }) {
  return (
    <div className="p-3 rounded-[4px] border border-[#E4E4E4] text-[#000] text-[14px] leading-[150%] space-y-2">
      <div>
        <span className="font-medium text-[#727272]">
          {CRITERIA_INDENTIFY_SUBCLAIMS}:{" "}
        </span>
        {complaint?.criteriaToIdentifySubClaims}
      </div>
      <div>
        <span className="font-medium text-[#727272]">
          {CRITERIA_ASSESS_EVIDENCE}:{" "}
        </span>
        {complaint?.criteriaToAssessEvidence}
      </div>
      <div>
        <span className="font-medium text-[#727272]">
          {REMEDIAL_ACTION_GUIDANCE}:{" "}
        </span>
        {complaint?.remedialActionsGuidance}
      </div>
      <div>
        <span className="font-medium text-[#727272]">
          {MONETARY_VALUE_GUIDANCE}:{" "}
        </span>
        {complaint?.monetaryValueGuidance}
      </div>
    </div>
  );
}
