import React from 'react';
import Eye from "../../Images/Eye.jpg";
import Ellipse from "../../Images/Ellipse.jpg";
import FileText from "../../Images/FileText.jpg";
import Sparkle from "../../Images/Sparkle.jpg";
import Warning from "../../Images/Warning.jpg";
import WarningBW from "../../Images/Warning_BW.jpg"
import { FiCopy } from 'react-icons/fi';
import { FaChevronUp, FaChevronDown, FaCheckCircle, FaToggleOn, FaToggleOff } from "react-icons/fa";
import plusIcon from "../../Images/Plus.svg";
import Edit from "../../Images/Edit.svg";



const Icon = ({ type, className, style, ...rest }) => {
        const defaultStyles = 'w-6 h-6 text-blue-500';

        const icons = {
                EyeIcon: <img src={Eye} alt="Eye Icon" className={className || defaultStyles} style={style} {...rest} />,
                EllipseIcon: <img src={Ellipse} alt="Ellipse Icon" className={className || defaultStyles} style={style} {...rest} />,
                FileTextIcon: <img src={FileText} alt="File Text Icon" className={className || defaultStyles} style={style} {...rest} />,
                SparkleIcon: <img src={Sparkle} alt="Sparkle Icon" className={className || defaultStyles} style={style} {...rest} />,
                WarningIcon: <img src={Warning} alt="Warning Icon" className={className || defaultStyles} style={style} {...rest} />,
                WarningBWIcon: <img src={WarningBW} alt="Warning Icon" className={className || defaultStyles} style={style} {...rest} />,
                DocumentCopyIcon: <FiCopy className={className} style={style} {...rest} />,
                ArrowDownIcon: <FaChevronDown className={className} style={style} {...rest} />,
                ArrowUpIcon: <FaChevronUp className={className} style={style} {...rest} />,
                PlusIcon: <img src={plusIcon} alt="Plus Icon" className={className || defaultStyles} style={style} {...rest} />,
                EditIcon: <img src={Edit} alt="Edit Icon" className={className || defaultStyles} style={style} {...rest} />,
                ActivityIcon: <FaCheckCircle className={className} style={style} {...rest} />,
                ToggleOnIcon: <FaToggleOn className={className} style={style} {...rest} />,
                ToggleOffIcon: <FaToggleOff className={className} style={style} {...rest} />,

        };

        return icons[type] || null;
};

export default Icon;
