export const ALLOWED_UPDATABLE_KEYS_FOR_MEMBERS = ["contactName", "firstName", "lastName", "contactPhone", "countryCode", "employeeId", "contactEmail", "role", "isActive"];

export const AGENT_ACTIVATE = "activate";

export const AGENT_DEACTIVATE = "deactivate";

export const AGENT_ROLE_SUPERADMIN = "superadmin";

export const AGENT_ROLE_ADMIN = "admin";

export const AGENT_ROLE_CONTRIBUTER = "contributer";

export const AGENT_ROLE_READER = "reader";

export const ALL_ACTIVE_ROLES = [AGENT_ROLE_SUPERADMIN,AGENT_ROLE_ADMIN, AGENT_ROLE_CONTRIBUTER, AGENT_ROLE_READER];

export const ALL_ROLES_WITH_ORG_CHECK = [AGENT_ROLE_ADMIN, AGENT_ROLE_CONTRIBUTER, AGENT_ROLE_READER];