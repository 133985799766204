import storageService from "./storageService";
import { AGENT_ID, AUTH_USER, CASE_ID, ORGANIZATION_ID, TOKEN, USER_TYPE, ADMIN_ID } from "../constants/enum";

export const attemptLogout = () => {
    storageService.removeItem(USER_TYPE);
    storageService.removeItem(TOKEN);
    storageService.removeItem(ORGANIZATION_ID);
    storageService.removeItem(CASE_ID);
    storageService.removeItem(AGENT_ID);
    storageService.removeItem(AUTH_USER);
    storageService.removeItem(ADMIN_ID);
};