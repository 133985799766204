export const ROOT_ROUTE = "/";

export const RESET_PASSWORD_ROUTE = "/reset-password/:token";

export const FORGOT_PASSWORD_ROUTE = "/forgot-password";

export const CONTACT_US_ROUTE = "/contact-us";

export const LOGIN_ROUTE = "/login";

export const SIGNIN_ROUTE = "/signin";

export const SIGNUP_ROUTE = "/signup";

export const SETTINGS_ROUTE = "/settings";

export const CASELIST_ROUTE = "/case-list";

export const CASES_ROUTE = "/cases/:case_id";

export const CASE_UPLOAD_ROUTE = "/case-upload";

export const ADMIN_SIGNIN_ROUTE = "/admin/login";

export const ADMIN_RESET_PASSWORD_ROUTE = "/admin/reset-password/:token";

export const ADMIN_FORGOT_PASSWORD_ROUTE = "/admin/forgot-password";

export const ORGLIST_ROUTE = "/admin/organization-list";

export const ORG_DASHBOARD_ROUTE = "/admin/dashboard";

export const ORG_DETAILS_ROUTE = "/admin/organization/:organization_id/view";
