export const CLAIM_VALIDATE = "validate";

export const CLAIM_INVALIDATE = "invalidate";

export const EVIDENCE_CATEGORY_CUSTOMER = "customer_evidence";

export const EVIDENCE_CATEGORY_CUSTOMER_LABEL = "Customer Evidence";

export const EVIDENCE_CATEGORY_OMBUDSMAN = "ombudsman_evidence";

export const EVIDENCE_CATEGORY_OMBUDSMAN_LABEL = "Evidence";

export const EVIDENCE_CATEGORY_INTERNAL = "internal_evidence";

export const EVIDENCE_CATEGORY_INTERNAL_LABEL = "Internal Evidence";
