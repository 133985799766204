import { Divider } from "../../../../components/Divider/Divider";
import { Image } from "../../../../components/Image/Image";
import {
  ADD_REMEDIAL_ACTIONS,
  ADDITIONAL_REMEDIAL_ACTION_TEXT,
  AI_GENERATED_TEXT,
  DECISION_TEXT,
  ISSUE_APOLOGY_DESC,
  ISSUE_APOLOGY_TEXT,
  MONETARY_VALUE,
  decisionoptions,
  PLAYBOOK_DESC,
  USER_CONFIRMATION_TEXT,
} from "../../../../constants/casesConstants";
import sparkle from "../../../../Images/Sparkle.svg";
import collapse from "../../../../Images/Collapse.svg";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import EditButton from "../../../../components/EditButton/EditButton";
import { useEffect, useState } from "react";
import SelectDropdown from "../../../../components/DropDown/Dropdown";
import Label from "../../../../components/Label/Label";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch";
import InputForm from "../../../../components/InputForm/InputForm";
import TextAreaForm from "../../../../components/FormTextArea/FormTextArea";
import { useUser } from "../../../../context/UserContext";
import Overlay from "../../../../components/Overlay/Overlay";
import Button from "../../../../components/Button/Button";
import {
  createAdditionalRemedialAction,
  deleteAdditionalRemedialAction,
  getTotalMonetaryValue,
  updateAdditionalRemedialAction,
} from "../../../../services/coreService";
import { useOrganization } from "../../../../context/OrganizationContext";
import { showNotification } from "../../../../components/Toastify/Toast";
import { useResponse } from "../../../../context/ResponseContext";


import { confirmDisputeCase, confirmFinalDecision } from "../../../../services/coreService";
import PermissionFunction from "../../../../components/PermissionLocks/PermissionFunction";
import { truncate } from "lodash";
import Switch2_0 from "../../../../components/SwitchV2/SwitchV2";
import Loader from "../../../../components/Loaders/Loader";

function ClaimResponse({ additionalRemediallist, agentId, DecisionColor, caseId, organizationId, fetchRemedialActions, handleGetTotalMonetaryValue, totalmonetaryValue }) {


  const [editremedialDetails, seteditRemedialDetails] = useState([]);
  const [isadditionalremedialmodal, setIsadditionalRemedialmodal] = useState(false);
  const [additionalRemedialTitle, setAdditionalRemedialTitle] = useState("");
  const [additionaljustification, setisAdditionalJustification] = useState("");
  const [isadditionalremedialLoader, setIsadditionalremedialLoader] = useState(false);
  const [isdecisionselectLoader, setisDecisionSelectLoader] = useState(false);
  const [isadditionalremedialaddLoader, setIsAdditionalRemedialAddLoader] = useState(false);
  const [isadditionalremedialremoveLoader, setIsAdditionalRemedialRemoveLoader] = useState(false);

  const { agent } = useUser();
  const { isConfirmedByAgentForDecision, updateConfirmedByAgentForDecision, fetchTotalMonetaryForAgentConfirm, decisionValue, setDecisionValue } = useResponse();

  let value = decisionValue ? decisionValue : "";

  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    setSelectedOption(value);

  }, [value])


  const handleAdditionalRemedialModalOpen = (action) => {
    if (action) {
      setAdditionalRemedialTitle(action?.title || "");
      setisAdditionalJustification(action?.description || "");
      seteditRemedialDetails(action);
    } else {
      setAdditionalRemedialTitle("");
      setisAdditionalJustification("");
    }
    setIsadditionalRemedialmodal(true);
  };

  const createAdditionalRemedialModalOpen = () => {
    seteditRemedialDetails(null)
    setIsadditionalRemedialmodal(true);
  };


  const handleAdditionalRemedialModalClose = () => {
    seteditRemedialDetails({})
    setAdditionalRemedialTitle("");
    setisAdditionalJustification("");
    setIsadditionalRemedialmodal(false);
  };

  const handleRemedialValidation = (action) => {
    let isValid = true;
    if (!!!action.title || action.title === "") {
      showNotification("Please Enter Title", "error");
      isValid = false;
    } else if (!!!action.description || action.description === "") {
      showNotification("Please Enter Justification", "error");
      isValid = false;
    }

    return isValid;
  };

  const createAdditionalRemedialaction = async (remedialId) => {
    setIsAdditionalRemedialAddLoader(true)
    try {
      const payload = {
        remedialActionType: "additional",
        assignedAgent: agentId,
        caseId: caseId,
        title: additionalRemedialTitle,
        description: additionaljustification,
        createdBy: agentId,
        isEdited: false,
        editTitle: "",
        editDescription: ""
      };

      if (handleRemedialValidation(payload)) {
        let res;

        if (editremedialDetails) {
          res = await updateAdditionalRemedialAction(organizationId, remedialId, payload);
        } else {
          payload.isAiGenerated = false;
          res = await createAdditionalRemedialAction(organizationId, caseId, payload);
        }

        if (res?.status === "Success") {
          const { remedialAction } = res.data;

          fetchRemedialActions(caseId);
          handleAdditionalRemedialModalClose();

          await fetchTotalMonetaryForAgentConfirm(organizationId, caseId)

        }
      }
    } catch (error) {
      showNotification("An error occurred in remedial action", "error");
    }
    setIsAdditionalRemedialAddLoader(false)
  };



  const removeRemedialaction = async (remedialid) => {
    setIsAdditionalRemedialRemoveLoader(true)
    try {
      const response = await deleteAdditionalRemedialAction(organizationId, remedialid);

      if (response?.status === "Success") {
        handleAdditionalRemedialModalClose();

        fetchRemedialActions(caseId);

        await fetchTotalMonetaryForAgentConfirm(organizationId, caseId)

      }
    } catch (error) {
      showNotification("Failed to remove remedial action", "error");
    }
    setIsAdditionalRemedialRemoveLoader(false)
  };


  const handleDisputeCaseConformation = async (data) => {
    setIsadditionalremedialLoader(true)
    try {
      const payload = { action: data }
      let response = await confirmDisputeCase(organizationId, caseId, payload);

      if (response?.status === "Success") {
        showNotification("Dispute Cases confirmed successfully", "success");
        updateConfirmedByAgentForDecision(data === "validate" ? true : false)
      }
    }
    catch (error) {
      console.log(error);
    }
    setIsadditionalremedialLoader(false)
  };


  const handleFinalConfirmDecision = async (data) => {
    setisDecisionSelectLoader(true)
    try {

      const payload = { decision: data }
      let response = await confirmFinalDecision(organizationId, caseId, payload);

      if (response?.status === "Success") {
        setSelectedOption(data);
        setDecisionValue(data);
        handleGetTotalMonetaryValue()
        showNotification("Dispute Cases confirmed successfully", "success");
      }
    }
    catch (error) {
      console.log(error);
    }
    setisDecisionSelectLoader(false)
  };


  return (
    <div>
      <div>
        <span className="text-[22px] font-medium pb-4">
          Response Preparation
        </span>
      </div>
      <div className=" bg-white mt-4 border border-gray-300 rounded-[4px]  ">
        <div className="flex flex-row justify-between px-8 py-4 bg-[#F9F9F9]  ">
          <div>
            <h3 className="text-[16px] font-bold text-black-900">
              {DECISION_TEXT}
            </h3>
            <div className="flex items-center">
              {
                isdecisionselectLoader ?
                <div className="ml-4">
                <Loader />
                </div>
                 :
              <SelectDropdown
                options={decisionoptions}
                value={selectedOption}
                onChange={handleFinalConfirmDecision}
                collapseIcon={collapse}
                placeholder="Select an option..."
                textcolor={DecisionColor(selectedOption)}
              />
            }
              {/* <Image className="w-[15px] h-[15px] ml-2" src={sparkle} />
              <span className="text-[12px] text-[#4976F4] ml-2">
                {AI_GENERATED_TEXT}
              </span> */}
            </div>
            <div className="mb-6 after:"></div>
          </div>
        </div>
        <Divider />

        <div className="flex flex-row justify-between px-8 py-4 bg-[#F9F9F9]  ">
          <div>
            <div className="flex items-center">
              <h3 className="text-[16px] font-bold text-black-900">
                {MONETARY_VALUE}
              </h3>
            </div>
            <div className="mb-6 after:" data-testid="text">
              <p className="mb-2 text-2xl text-gray-700">£{totalmonetaryValue}</p>
              <p className="mb-4 text-sm text-gray-600">{PLAYBOOK_DESC}</p>
            </div>
          </div>
        </div>

        <Divider />
        {
          additionalRemediallist.map((action, index) => (
            <div className="px-8 py-4 w-full" key={index}>
              <div className="flex flex-row w-[100%]">
                <span className="text-[22px] text-black-800">
                  <span className="w-[7px] h-[7px] bg-black rounded-full inline-block mr-2"></span>
                  {truncate(action.title, { length: 60 })}
                </span>
                <div className="self-center ml-4 w-[30%]">
                  {action.isAiGenerated ? <Switch2_0 initialState="false" date={""} /> : null}
                </div>
              </div>

              <div className="w-full flex flex-row justify-between">
                <div className="mb-6">
                  <p className="mb-4 ml-4 text-sm text-gray-600">{action.description}</p>
                </div>
                <div>
                  <div className="w-[120px] flex justify-end">
                    <div>
                      <PermissionSwitch
                        shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN, AGENT_ROLE_CONTRIBUTER]}
                        hasRole={agent?.role}
                        yes={
                          <EditButton
                            onClick={() => handleAdditionalRemedialModalOpen(action)}
                            disabled={false}
                            data-testid="edit-remedial"
                          />
                        }
                        no={<EditButton disabled={true} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        <div className="flex justify-between py-5 px-8 ">
          <div onClick={createAdditionalRemedialModalOpen}>
            <p className="mb-2 text-[15px] text-[#000000] font-normal cursor-pointer">
              {ADD_REMEDIAL_ACTIONS}
            </p>
          </div>
           {
            isadditionalremedialLoader ?
            <Loader /> :
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() =>
              PermissionFunction(agent?.role, [AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN, AGENT_ROLE_CONTRIBUTER], () =>
                handleDisputeCaseConformation(
                  isConfirmedByAgentForDecision ? "invalidate" : "validate"
                ))

            }
          >
            <Label
              text={USER_CONFIRMATION_TEXT}
              htmlFor="valid-confirmation"
              className="text-sm text-gray-600 cursor-pointer"
            />
            <PermissionSwitch
              shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN, AGENT_ROLE_CONTRIBUTER]}
              hasRole={agent?.role}
              yes={
                <div>
                  <Checkbox
                    onChange={() => { }}
                    checked={isConfirmedByAgentForDecision}
                    className="w-3 h-3 bg-black cursor-pointer"
                  />
                </div>
              }
              no={
                <Checkbox
                  disabled={true}
                  className="w-3 h-3 bg-black cursor-pointer"
                />
              }
            />

          </div>
           }
        </div>

      </div>

      <Overlay
        isOpen={isadditionalremedialmodal}
        onClose={handleAdditionalRemedialModalClose}
        title={`${editremedialDetails ? "Edit" : "Create"} Additional Remedial Action`}
        className="custom-class"
        position="center"
      >
        <div>
          <InputForm
            label="Title"
            value={additionalRemedialTitle}
            handleChange={(e) => setAdditionalRemedialTitle(e.target.value)}
            placeholder={"e.g. Wipe customers credit account"}
            isValid={true}
          />
        </div>
        <div className="w-full max-w-md mx-auto pt-5">
          <TextAreaForm
            label="Justification"
            value={additionaljustification}
            handleChange={(e) => setisAdditionalJustification(e.target.value)}
            placeholder={
              "e.g. Based on the evidence provided it is clear that a mistake was made regarding the customers credit status and their credit account should be wiped"
            }
            isValid={true}
          />
        </div>
        <div className="flex flex-row justify-between gap-6 px-8 mt-8">

          {
            editremedialDetails &&

            <PermissionSwitch
              shouldHaveRoles={[
                AGENT_ROLE_SUPERADMIN,
                AGENT_ROLE_ADMIN,
                AGENT_ROLE_CONTRIBUTER,
              ]}
              hasRole={agent?.role}
              yes={
                <>
                {
                  isadditionalremedialremoveLoader?
                  <div className="w-[100%]"> 
                  <Loader/> 
                  </div>
                  :
                  <Button
                    className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
                    text={"Delete"}
                    textcolor="red"
                    onClick={() => removeRemedialaction(editremedialDetails?._id)}
                  />
                }
                </>
              }
              no={
                <>
                  <Button
                    className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
                    text={"Delete"}
                    textcolor="red"
                    onClick={() => { }}
                  />
                </>
              }
            />
          }
          <Button
            onClick={handleAdditionalRemedialModalClose}
            className="w-full bg-[#F2F2F2] px-4 py-2 "
            text={"Cancel"}
            textcolor="#000000"
            data-testid="closeRemedialButton"
          />
          <PermissionSwitch
            shouldHaveRoles={[
              AGENT_ROLE_SUPERADMIN,
              AGENT_ROLE_ADMIN,
              AGENT_ROLE_CONTRIBUTER,
            ]}
            hasRole={agent?.role}
            yes={
              <> 
              {isadditionalremedialaddLoader ?
                 <div className="w-[100%]"> 
                  <Loader/> 
                  </div>:
              <Button
                className="w-full bg-[#000000] text-white px-4 py-2 "
                text={editremedialDetails ? "Save" : "Create"}
                onClick={() => createAdditionalRemedialaction(editremedialDetails?._id)}
              />
            }
              </>
            }
            no={
              <Button
                className="w-full bg-[#000000] text-white px-4 py-2 "
                text={editremedialDetails ? "Save" : "Create"}
                disabled={true}
              />
            }
          />
        </div>
      </Overlay>
    </div>

  );
}

export default ClaimResponse
