import React, { createContext, useContext, useState } from "react";

const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  const [organization, setOrganization] = useState(null);
  const [refreshConfig, setRefreshConfig] = useState(null);
  const [isFeedbackFormVisibleToUsers, setFeedbackFormVisibleState] = useState(null);
  const [settingsRoutesVisible, setSettingsRoutesVisibleState] = useState(null);

  const updateOrganization = (newOrganization) => {
    setOrganization(newOrganization);
  };

  const updateRefreshConfig = (config) => {
    setRefreshConfig(config);
  };

  const updateFeedbackFormState = (isFeedbackFormVisibleToUsers) => {
    setFeedbackFormVisibleState(isFeedbackFormVisibleToUsers);
  };

  const updateSettingsRoute = (settingsRoutesVisible) => {
    setSettingsRoutesVisibleState(settingsRoutesVisible);
  };

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        updateOrganization,
        refreshConfig,
        updateRefreshConfig,
        isFeedbackFormVisibleToUsers,
        settingsRoutesVisible,
        updateFeedbackFormState,
        updateSettingsRoute
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error(
      "useOrganization must be used within an OrganizationProvider"
    );
  }
  return context;
};
