const TextAreaForm = ({
  value,
  handleChange,
  count,
  label,
  id,
  placeholder,
  errorMessage,
  isValid = true,
  maxLength,
  wordLimit,
  isWordLimitEnabled,
  defaultFontForTitle,
}) => {

  const getWordCount = (value) => {

    const words = value?.trim()?.split(/\s+/);
    if (words?.length === 1 && words[0] == "") {
      return 0;
    } else {
      return words?.length;
    }
  };

  const handleTextChange = (e) => {
    if (isWordLimitEnabled && getWordCount(e.target.value) <= wordLimit) {
      handleChange(e);
    } else {
      handleChange(e);
    }
  }

  return (
    <>
      {isWordLimitEnabled ? (
        <>
          <div>
            <span className="font-medium text-[#727272]">
              {label}
            </span>
            <p className="text-[14px] text-red-500 float-right">
              {getWordCount(value) < wordLimit
                ? `${parseInt(wordLimit) - getWordCount(value)} words left`
                : `Word limit reached`}
            </p>
          </div>
        </>
      ) : (
        <label
          htmlFor={id}
          className={`font-inter text-[${defaultFontForTitle ? defaultFontForTitle : '16'}px] font-mediums text-[#727272] mb-2 pb-5`}
        >
          {label}
        </label>
      )}

      <textarea
        id={id}
        placeholder={placeholder}
        className={`"mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none" ${isValid
          ? "border-gray-300 focus:ring-gray-500"
          : "border-red-400 focus:ring-red-500"
          }`}
        value={value}
        onChange={handleTextChange}
        aria-invalid={!isValid}
        rows="4"
        maxLength={maxLength}
      ></textarea>
      {!isValid && <p className="mt-2 text-sm text-red-400">{errorMessage}</p>}
    </>
  );
};

export default TextAreaForm;
