import React, { useEffect, useState } from "react";
import {
  addMember,
  deleteMember,
  fetchMemberList,
  updateMember,
} from "../../../../services/coreService";
import { showNotification } from "../../../../components/Toastify/Toast";
import AddUpdateMember from "./Component/AddUpdateMember";
import Button from "../../../../components/Button/Button";
import MemberCard from "./Component/MemberCard";
import Overlay from "../../../../components/Overlay/Overlay";
import Loader from "../../../../components/Loaders/Loader";
import { useUser } from "../../../../context/UserContext";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import PermissionSegment from "../../../../components/PermissionLocks/PermissionSegment";

function Member({ organizationId, agentId }) {
  const [pageNum, setPageNum] = useState(1);
  const [memberlist, setMemberList] = useState([]);
  const [isAddUpdateModalState, setAddUpdateModalState] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [member, setMember] = useState({});
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [count, setCount] = useState(0);

  const [errors, setErrors] = useState({});

  const { agent } = useUser();

  const PAGE_SIZE = 20;
  const isNumeric = /^[0-9]+$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const totalPages = Math.ceil(count / PAGE_SIZE);

  useEffect(() => {
    fetchMemberData();
  }, [pageNum]);

  const handleAddUpdateModalOpen = (editMode = false, selectedMember = {}) => {
    setIsEditMode(editMode);
    setAddUpdateModalState(true);
    if (editMode) {
      setMember(selectedMember);
      setRole(selectedMember.role);
    } else {
      setMember({});
      setRole(null);
    }
  };

  const handleAddUpdateModalClose = () => {
    setAddUpdateModalState(false);
    setMember({});
    setRole(null);
  };

  const handleMemberValidation = (member) => {
    let isValid = true;
    let errors = {};
    if (!!!member.firstName || member.firstName === "") {
      errors["firstName"] = "Please Enter First Name";
      isValid = false;
    }
    if (!!!member.lastName || member.lastName === "") {
      errors["lastName"] = "Please Enter Last Name";
      isValid = false;
    }
    if (!!!member.contactName || member.contactName === "") {
      errors["contactName"] = "Please Enter Contact Name";
      isValid = false;
    }
    if (!!!member.username || member.username === "") {
      errors["username"] = "Please Enter Username";
      isValid = false;
    }
    if (!emailRegex.test(member.contactEmail)) {
      errors["contactEmail"] = "Please Enter Valid Email";
      isValid = false;
    }
    if (!member?.countryCode || member.countryCode === "") {
      errors["countryCode"] = "Please Enter a valid Country Code.";
      isValid = false;
    } else if (!/^(\+\d+)$/.test(member.countryCode)) {
      errors["countryCode"] = "Please Enter a valid Country Code.";
      isValid = false;
    }
    if (!isNumeric.test(member?.contactPhone)) {
      errors["contactPhone"] = "Please Enter Number";
      isValid = false;
    }

    if (!!!member.employeeId || member.employeeId === "") {
      errors["employeeId"] = "Please Enter Employee Id";
      isValid = false;
    }
    if (!!!member.password || member.password === "") {
      errors["password"] = "Please Enter Password";
      isValid = false;
    }
    if (!!!role || role === "") {
      errors["role"] = "Please Enter Role";
      isValid = false;
    }
    return {
      isValid,
      errors,
    };
  };

  const handleMemberEditValidation = (member) => {
    let isValid = true;
    let errors = {};
    if (!!!member.firstName || member.firstName === "") {
      errors["firstName"] = "Please Enter First Name";
      isValid = false;
    }
    if (!!!member.lastName || member.lastName === "") {
      errors["lastName"] = "Please Enter Last Name";
      isValid = false;
    }
    if (!!!member.contactName || member.contactName === "") {
      errors["contactName"] = "Please Enter Contact Name";
      isValid = false;
    }
    if (!!!member.username || member.username === "") {
      errors["username"] = "Please Enter Username";
      isValid = false;
    }
    if (!emailRegex.test(member.contactEmail)) {
      errors["contactEmail"] = "Please Enter Valid Email";
      isValid = false;
    }
    if (!member?.countryCode || member.countryCode === "") {
      errors["countryCode"] = "Please Enter a valid Country Code.";
      isValid = false;
    } 
    if (!isNumeric.test(member?.contactPhone)) {
      errors["contactPhone"] = "Please Enter Number";
      isValid = false;
    }

    // if (!!!member.employeeId || member.employeeId === "") {
    //   errors["employeeId"] = "Please Enter Employee Id";
    //   isValid = false;
    // }
    return {
      isValid,
      errors,
    };
  };

  const fetchMemberData = async () => {
    try {
      if (pageNum === 1) {
        setLoading(true);
      }
      const payload = { pageNum, pageSize: PAGE_SIZE, filter: {} };
      const res = await fetchMemberList(organizationId, payload);
      if (res?.status === "Success") {
        const { members, count } = res.data;
        setMemberList(members);
        setCount(count);
        setLoading(false);
      }
    } catch (error) {
      showNotification("An error occurred while fetching members", "error");
    }
  };

  const removeMember = async (id) => {
    try {
      let res;
      res = await deleteMember(organizationId, id);
      if (res?.status === "Success") {
        fetchMemberData();
      }
    } catch (error) {
      showNotification("not remove member", "error");
    }
  };

  const handleAddMember = async () => {
    const updatedMember = {
      ...member,
      countryCode: `+${member.countryCode || ""}`,
      isActive: true,
      isEmailVerified: false,
      role,
    };
    try {
      const payload = { agent: updatedMember };
      let check = handleMemberValidation(payload.agent);
      if (check.isValid) {
        const res = await addMember(organizationId, payload);
        if (res?.status === "Success") {
          fetchMemberData();
          handleAddUpdateModalClose();
        } else {
          const errorMessage = res?.data?.message;
          showNotification(errorMessage, "error");
          console.log(res.data.message, errorMessage, "ERRORMESSSAGE");
        }
      } else {
        handleErrors(check.errors);
      }
    } catch (error) {
      showNotification(error, "error");
    }
  };

  const handleErrors = (errors) => {
    let arrayOfErrors = [];
    for (let item in errors) {
      arrayOfErrors.push(errors[item]);
    }
    showNotification(arrayOfErrors[0], "error");
    setErrors(errors);
  };

  const handleUpdateMember = async () => {
    const updatedMember = {
      ...member,
      // countryCode: `+${member.countryCode || ""}`,
      role
    };
    try {
      const payload = { agent: updatedMember };
      let check = handleMemberEditValidation(payload.agent);
      if (check.isValid) {
        const res = await updateMember(organizationId, member._id, payload);
        if (res?.status === "Success") {
          fetchMemberData();
          handleAddUpdateModalClose();
        } else {
          const errorMessage = res?.data?.message;
          showNotification(errorMessage, "error");
        }
      } else {
        handleErrors(check.errors);
      }
    } catch (error) {
      showNotification("Not added member. An error occurred.", "error");
    }
  };

  const handleChange = (keyName, keyValue) => {
    setMember((prev) => ({ ...prev, [keyName]: keyValue }));
    setErrors({});
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const range = 2;
    let start = pageNum - range;
    let end = pageNum + range;
    if (start < 1) {
      start = 1;
      end = Math.min(totalPages, start + 4);
    }
    if (end > totalPages) {
      end = totalPages;
      start = Math.max(1, end - 4);
    }
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };
  const loadMoreCases = (page) => {
    if (page !== pageNum) {
      setPageNum(page);
    }
  };

  return (
    <PermissionSegment
      shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN]}
      hasRole={agent?.role}
    >
      <div className="px-[20px] py-[10px] overflow-y-scroll h-[90vh]" data-testid="Loader">
        {loading ? (
          <Loader />
        ) : (
          <>
            {memberlist.length > 0 ? (
              <div>
                <div className="flex flex-row justify-end py-4" 
                >
                  <PermissionSwitch
                    shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN]}
                    hasRole={agent?.role}
                    yes={
                      <Button
                        text="Add Member"
                        onClick={() => handleAddUpdateModalOpen(false)}
                        className="bg-[#000000] px-4 py-2 text-[22px] text-[700] text-white"
                        textSize={"16px"}
                        data-testid="AddMember"
                      />
                    }
                    no={
                      <Button
                        text="Add Member"
                        className="bg-[#000000] px-4 py-2 text-[22px] text-[700] text-white"
                        textSize={"16px"}
                        disabled={true}
                      />
                    }
                  />
                </div>
                <MemberCard
                  memberlist={memberlist}
                  removeMember={(id) => removeMember(id)}
                  handleUpdateMember={(selectedMember) =>
                    handleAddUpdateModalOpen(true, selectedMember)
                  }
                />
                <div className="flex justify-center mt-6 mb-20">
                  <nav aria-label="Page navigation">
                    <ul className="inline-flex items-center space-x-2">
                      <li>
                        <button
                          onClick={() => loadMoreCases(pageNum - 1)}
                          className={`px-4 py-2 text-sm font-medium rounded-full ${pageNum === 1
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-gray-200 hover:bg-black hover:text-white text-black"
                            }`}
                          disabled={pageNum === 1}
                        >
                          &lt;
                        </button>
                      </li>
                      {getPageNumbers().map((page) => (
                        <li key={page}>
                          <button
                            onClick={() => loadMoreCases(page)}
                            className={`px-4 py-2 text-sm font-medium rounded-full ${page === pageNum
                                ? "bg-black text-white"
                                : "bg-gray-200 hover:bg-black hover:text-white text-black"
                              }`}
                          >
                            {page}
                          </button>
                        </li>
                      ))}
                      <li>
                        <button
                          onClick={() => loadMoreCases(pageNum + 1)}
                          className={`px-4 py-2 text-sm font-medium rounded-full ${pageNum === totalPages
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-gray-200 hover:bg-black hover:text-white text-black"
                            }`}
                          disabled={pageNum === totalPages}
                        >
                          &gt;
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            ) : (
              <div className="flex flex-row justify-center items-center h-full mt-[350px]">
                <p className="text-[20px] text-black font-semibold">
                  No member
                </p>
              </div>
            )}
          </>
        )}
        <Overlay
          isOpen={isAddUpdateModalState}
          onClose={handleAddUpdateModalClose}
          title={isEditMode ? "Edit Member" : "Add Member"}
          position="center"
          style={{
            maxHeight: "600px",
            overflow: "hidden",
          }}
          data-testid="Add Member"
        >
          <div className="flex flex-col h-full">
            <div
              style={{
                maxHeight: "400px",
                overflowY: "scroll",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
                WebkitScrollbar: "none",
                flexGrow: 1,
              }}
            >
              <AddUpdateMember
                member={member}
                role={role}
                setRole={setRole}
                isEditMode={isEditMode}
                handleChange={handleChange}
                handleAddMember={handleAddMember}
                handleUpdateMember={handleUpdateMember}
                handleAddUpdateModalClose={handleAddUpdateModalClose}
                handleShowPassword={handleShowPassword}
                showPassword={showPassword}
                errors={errors}
              />
            </div>

            <div className="flex flex-row justify-between gap-6 px-8 mt-8">
              <Button
                className="w-full bg-[#F2F2F2] px-4 py-2"
                text="Cancel"
                textcolor="#000000"
                onClick={() => handleAddUpdateModalClose()}
              />
              <Button
                className="w-full bg-[#000000] text-white px-4 py-2"
                text={isEditMode ? "Update" : "Add"}
                onClick={isEditMode ? handleUpdateMember : handleAddMember}
              />
            </div>
          </div>
        </Overlay>
      </div>
    </PermissionSegment>
  );
}

export default Member;
