import dayjs from "dayjs";

export const datedifference = (deadlineDate) => {
  if (deadlineDate) {
    const currentDate = new dayjs();
    const targetDate = new dayjs(new Date(deadlineDate));
    return targetDate.diff(currentDate, "day");
  }
  return -10000;
};
