import React, { useEffect } from "react";
import Switch2_0 from "../../../../components/SwitchV2/SwitchV2";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch.jsx";
import Button from "../../../../components/Button/Button";
import Sparkle from "../../../../Images/Sparklewhite.svg";
import Loader from "../../../../components/Loaders/Loader";

import { decisionoptions } from "../../../../constants/casesConstants";
import {
  DISPUTE_CASE_STATUS_RESPONSE_GENERATE_LETTER,
  DISPUTE_CASE_STATUS_RESPONSE_GENERATION_COMPLETE,
} from "../../../../constants/disputeCaseStatusConstants.js";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_READER,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";

const CaseResponse = ({
  decisionValue,
  caseHighlights,
  letterData,
  letterTitle,
  caseHighlightsDate,
  remedialData,
  getLatestUpdatedAt,
  letterlist,
  handleGenerateResponse,
  globalCaseStatus,
  agent,
  isRegenerateLoader,
  isAIResponseGenerated,
}) => {

  return (
    <div class="flex flex-col space-y-4 pt-[24px] mb-3">
      <div>
        <div class="flex flex-row justify-between">
          <div className="w-full pb-[12px] px-[32px]">
            <div className="flex space-x-2 items-center w-full">
              <h6 className="text-[var(--Color-Tokens-Content-Dark-Primary, #000)] font-inter text-[16px] font-normal leading-[150%] whitespace-nowrap">
                Key Case Highlights
              </h6>
              <div className="flex items-center gap-2">
                <Switch2_0 initialState="false" date={caseHighlightsDate} />
              </div>
            </div>
            <ul className="list-disc pl-5 text-[#727272] font-inter text-[14px] font-normal leading-[150%] mt-4 overflow-y-auto max-h-32">
              {typeof caseHighlights === "string" ? (
                <p>{caseHighlights}</p>
              ) : (
                caseHighlights?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              )}
            </ul>
          </div>

          <div className="w-full pt-[8px] pb-[12px] px-[32px]">
            <div className="flex space-x-2 items-center w-full">
              <h6 className="text-[var(--Color-Tokens-Content-Dark-Primary, #000)] font-inter text-[16px] font-normal leading-[150%]">
                Remediations
              </h6>
              <div className="flex items-center gap-2">
                <Switch2_0
                  initialState="false"
                  date={getLatestUpdatedAt(remedialData)}
                />
              </div>
            </div>
            <ul className="list-disc pl-5 text-[#727272] font-inter text-[14px] font-normal leading-[150%] mt-4 overflow-y-auto max-h-32">
              {remedialData?.map((item, index) => (
                <li key={index}>{item.title}</li>
              ))}
            </ul>
          </div>
        </div>

        <div class="pt-[12px] pb-[24px] px-[32px]">
          <div className="flex flex-row justify-between border-t border-b border-[#E4E4E4]">
            {!isRegenerateLoader ? (
              <>
                <div class="pt-[16px] pb-[16px] text-ellipsis overflow-hidden text-black font-inter text-lg font-medium leading-[150%] ">
                  {letterTitle ? letterTitle : ""}
                </div>
                {globalCaseStatus && (
                  <PermissionSwitch
                    shouldHaveRoles={[
                      AGENT_ROLE_SUPERADMIN,
                      AGENT_ROLE_ADMIN,
                      AGENT_ROLE_CONTRIBUTER,
                    ]}
                    hasRole={agent?.role}
                    yes={
                      isRegenerateLoader ? (
                        <div className="flex flex-col justify-center">
                          <Loader />
                        </div>
                      ) : (
                        <Button
                          icon={Sparkle}
                          lefticon={true}
                          disabled={
                            globalCaseStatus ===
                              DISPUTE_CASE_STATUS_RESPONSE_GENERATE_LETTER ||
                            isAIResponseGenerated
                          }
                          className="w-[200px] bg-[#4a6cfd] h-[38px] px-2 py-2 text-[26px] text-white-700 text-white self-center"
                          text={"Regenerate Response"}
                          onClick={handleGenerateResponse}
                        />
                      )
                    }
                    no={
                      <Button
                        icon={Sparkle}
                        lefticon={true}
                        disabled={true}
                        className="w-[200px] bg-[#4a6cfd] h-[38px] px-2 py-2 text-[26px] text-white-700 text-white self-center"
                        text={"Regenerate Response"}
                        onClick={null}
                      />
                    }
                  />
                )}{" "}
              </>
            ) : null}
          </div>{" "}
          {isRegenerateLoader || isAIResponseGenerated ||
          globalCaseStatus === DISPUTE_CASE_STATUS_RESPONSE_GENERATE_LETTER
          ? (
            <div className="flex flex-col items-center justify-center h-[50vh] text-center">
              <p className="mb-5">Generating Response. Please Wait !</p>
              <Loader />
            </div>
          ) : !letterData || letterData?.length === 0 ? (
            <div className="flex items-center justify-center h-[50vh] text-center text-gray-500">
              <p>No response data available at the moment.</p>
            </div>
          ) : (
            <div>
              <div className="pt-[16px] pb-[16px] px-[16px] py-[16px]">
                <div className="flex items-center gap-2">
                  <Switch2_0
                    initialState="false"
                    date={getLatestUpdatedAt(letterlist)}
                  />
                </div>
              </div>

              <div className="text-[#727272] text-xl font-normal leading-[27px] whitespace-pre-line">
                <p> {letterData} </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseResponse;
