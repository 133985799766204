import React from "react";
import { Navigate } from "react-router-dom";
import { ADMIN_SIGNIN_ROUTE, SIGNIN_ROUTE } from "../constants/routeEnums";
import { TOKEN, USER_TYPE_ADMIN } from "../constants/enum";
import storageService from "../services/storageService";

const AuthProtected = ({ children, userTypeFromRoute }) => {
  if (!storageService.getItem(TOKEN)) {
    return <Navigate to={{ pathname: userTypeFromRoute === USER_TYPE_ADMIN ? ADMIN_SIGNIN_ROUTE : SIGNIN_ROUTE }} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;
