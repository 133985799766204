import React, { useEffect } from "react";
import storageService from "../services/storageService";
import { fetchAgentInfo, fetchAdminInfo } from "../services/coreService";
import { ORGANIZATION_ID, AGENT_ID, TOKEN, ADMIN_ID } from "../constants/enum";
import { CASELIST_ROUTE, ORG_DASHBOARD_ROUTE } from "../constants/routeEnums";
import { useNavigate } from "react-router-dom";

export default function NotPublicIfAuthenticated({ children }) {

    let agentId = storageService.getItem(AGENT_ID);
    let organizationId = storageService.getItem(ORGANIZATION_ID);
    const token = storageService.getItem(TOKEN);
    let adminId = storageService.getItem(ADMIN_ID);

    const Navigate = useNavigate();

    useEffect(() => {
        const checkAuthenticationForAgent = async () => {

            if (organizationId && agentId) {
                try {

                    const res = await fetchAgentInfo(organizationId, agentId);
                    if (res?.status === "Success") {
                        Navigate(CASELIST_ROUTE)
                    }
                } catch (error) {
                    console.error("Error fetching agent info:", error);
                }
            }
        };

        if (token) {
            checkAuthenticationForAgent();
        }
    }, [token, organizationId, agentId]);

    useEffect(() => {
        const checkAuthenticationForAdmin = async () => {

            if (adminId) {
                try {
                    const res = await fetchAdminInfo(adminId);

                    if (res?.status === "Success") {
                        Navigate(ORG_DASHBOARD_ROUTE);
                    }
                } catch (error) {
                    console.error("Error fetching agent info:", error);
                }
            }
        };

        if (token) {
            checkAuthenticationForAdmin();
        }
    }, [token, adminId]);

    useEffect(() => {

        const handleVisibilityChange = async () => {

            if (!agentId || !organizationId) {
                agentId = storageService.getItem(AGENT_ID);
                organizationId = storageService.getItem(ORGANIZATION_ID);
            }

            if (agentId && organizationId) {

                const res = await fetchAgentInfo(organizationId, agentId);
                if (res?.status === "Success") {
                    Navigate(CASELIST_ROUTE);
                }
            }
        };

        window.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            window.removeEventListener("visibilitychange", handleVisibilityChange);
        };

    }, [agentId, organizationId]);

    useEffect(() => {

        const handleVisibilityChange = async () => {

            if (!adminId) {
                adminId = storageService.getItem(ADMIN_ID);
            }

            if (adminId) {
                const res = await fetchAdminInfo(adminId);
                if (res?.status === "Success") {
                    Navigate(ORG_DASHBOARD_ROUTE);
                }
            }
        };

        window.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            window.removeEventListener("visibilitychange", handleVisibilityChange);
        };

    }, [adminId]);

    return (
        <>
            {children}
        </>
    )
}
