
import {
    PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES,
    PLAYBOOK_COMPLAINT_TYPE_BILLING,
    PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE,
    PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY,
    PLAYBOOK_COMPLAINT_TYPE_UNKNOWN,
    PLAYBOOK_COMPLAINT_TYPE_PACKAGE,
    PLAYBOOK_COMPLAINT_TYPE_BILLING_LABEL,
    PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES_LABEL,
    PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE_LABEL,
    PLAYBOOK_COMPLAINT_TYPE_PACKAGE_LABEL,
    PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY_LABEL,
    PLAYBOOK_COMPLAINT_TYPE_UNKNOWN_LABEL

} from "../constants/playbookComplaintsTypeConstants";

export const displayComplaintName = (category) => {
    switch (category) {
        case PLAYBOOK_COMPLAINT_TYPE_BILLING:
            return PLAYBOOK_COMPLAINT_TYPE_BILLING_LABEL;
        case PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES:
            return PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES_LABEL;
        case PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE:
            return PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE_LABEL;
        case PLAYBOOK_COMPLAINT_TYPE_PACKAGE:
            return PLAYBOOK_COMPLAINT_TYPE_PACKAGE_LABEL;
        case PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY:
            return PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY_LABEL;
        case PLAYBOOK_COMPLAINT_TYPE_UNKNOWN:
            return PLAYBOOK_COMPLAINT_TYPE_UNKNOWN_LABEL;
        default:
            return category;
    }
};