import React from "react";
import Icon from "../../../../components/Icon/Icon";
import { COMPLAINT_SUMMARY, KEY_CASE_HIGHLIGHTS } from "../../../../constants/CaseResolveOverView";
import Switch2_0 from "../../../../components/SwitchV2/SwitchV2";

import { formatDateToDDMMYYYY } from "../../../../utils/dateFormat";
import Loader from "../../../../components/Loaders/Loader";

const CaseResolveOverview = ({ caseData }) => {

    let formattedDate = formatDateToDDMMYYYY(caseData?.updatedAt)

    const calculateDaysRemaining = (deadlineDate) => {
        const currentDate = new Date();
        const deadline = new Date(deadlineDate);
        const diffTime = deadline - currentDate;

        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const getCurrentDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const sections = [
        {
            title: COMPLAINT_SUMMARY,
            content: Array.isArray(caseData?.caseSummary) ? caseData?.caseSummary : [caseData?.caseSummary],
            listStyle: "none",
            date: formattedDate,
        },
        {
            title: KEY_CASE_HIGHLIGHTS,
            content: Array.isArray(caseData?.caseHighlights) ? caseData?.caseHighlights : [caseData?.caseHighlights],
            listStyle: "disc",
            date: formattedDate,
        },
        {

            title: "Type",
            content: caseData?.caseType,
            type: "tags",
            date: formattedDate,
        },
        {
            title: "Raised",
            content: [
                { text: `${calculateDaysRemaining(caseData?.caseDeadlineDate)} days remaining`, }
            ],
            type: "date",
            date: formattedDate,
        }
    ];


    return (
        <div className="flex flex-col gap-8 flex-1 px-[32px] py-[32px] ">
            {!caseData ? (
                <div data-testid="loader" className="flex items-center justify-center h-[50vh]">
                    <Loader />
                </div>
            ) :
                <div className="flex flex-col gap-8 flex-1" >
                    {sections.map((section, index) => (

                        <div key={index} className={`w-full ${section.title === "Raised" || section.title === "Type" ? "border-b border-[#E4E4E4] pb-[16px]" : ""
                            }`}>
                            <div className="flex justify-between items-center ">
                                <h6 className="text-black font-inter font-medium text-lg leading-[120%]">
                                    {section.title}
                                </h6>
                                {section.type !== "date" && (
                                    <div className="flex items-center gap-2">
                                        <Switch2_0 initialState="false" date={section.date} />

                                        {section.type === "tags" && (
                                            <div className="flex gap-2">

                                                <div
                                                    key={index}
                                                    className="flex py-[6px] px-2 justify-center items-center gap-2 rounded-[36px]"
                                                    style={{ backgroundColor: "#FBF4EC" }}
                                                >
                                                    <p
                                                        className="font-inter text-sm font-medium leading-[150%]"
                                                        style={{ color: "#D28E3D" }}
                                                    >
                                                        {section?.content}
                                                    </p>
                                                </div>

                                            </div>
                                        )}
                                    </div>
                                )}

                                {section.type === "date" && (
                                    <div className="flex justify-between items-center ">
                                        {section.content.map((item, index) => (
                                            <div key={index} className="flex items-center gap-2">
                                                <Icon type="WarningIcon" className="h-4 w-4" />
                                                <span className="text-[#AF4B4B] text-right font-inter text-base font-normal leading-[150%]">
                                                    {item.text}
                                                </span>
                                                <p className="text-[#727272] text-right font-inter text-base font-normal leading-[150%]">
                                                    {getCurrentDate()}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {section.listStyle ? (
                                <ul className={`list-${section.listStyle} pl-5 text-[#727272] font-inter text-[18px] leading-[150%] mt-4`}>
                                    {section.content.map((item, index) => (
                                        <li key={index}>
                                            <p className="m-0">{item}</p>
                                        </li>

                                    ))}
                                </ul>
                            ) : null}
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

export default CaseResolveOverview;
