import React from "react";
import PermissionSwitch from "./PermissionSwitch";

const PermissionSegment = ({
  className,
  text,
  children,
  hasRole,
  shouldHaveRoles,
}) => {
  return (
    <PermissionSwitch
      shouldHaveRoles={shouldHaveRoles}
      hasRole={hasRole}
      yes={children}
      no={
        <div
          className={
            className
              ? className
              : "flex items-center justify-center h-[50vh] text-center text-gray-500"
          }
          data-testid="no-permission-message"
        >
          <p data-testid="no-permission-text">{text ? text : "You Don't Have Permission To This Page"}</p>
        </div>
      }
    />
  );
};

export default PermissionSegment;
