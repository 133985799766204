import { useEffect, useState } from "react";
import { organizationDetail } from "../../../services/coreService";
import { orgAdminUploader } from "../../../services/uploaderApi";
import { useParams } from "react-router-dom";
import { ORG_DETAILS_ROUTE } from "../../../constants/routeEnums";
import Icon from "../../../components/Icon/Icon";
import { showNotification } from "../../../components/Toastify/Toast";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Loaders/Loader";
import InputForm from "../../../components/InputForm/InputForm";
import {
  updateOrganization,
  createSignedRequest,
} from "../../../services/coreService";

const OrganizationDetail = () => {
  const { organization_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgDetail, setOrgDetail] = useState({});
  const [readOnly, setReadOnly] = useState(true);
  const [image, setImage] = useState("");
  const [errors, setErrors] = useState({});
  const isNumeric = /^[0-9]+$/;

  const handleOrganisationChange = (keyName, keyValue) => {
    setOrgDetail((prev) => ({ ...prev, [keyName]: keyValue }));
    setErrors((prev) => ({ ...prev, [keyName]: "" }));
  };

  const fetchOrganisationDetail = async (organization_id) => {
    try {
      setIsLoading(true);
      const res = await organizationDetail(organization_id);
      setIsLoading(false);
      if (res.status === "Success") {
        setOrgDetail(res.data.organization);
      }
    } catch (e) {
      showNotification("Error fetching organization detail", "error");
    }
  };
  useEffect(() => {
    if (orgDetail.organizationLogoUrl && orgDetail.organizationLogoUrl !== "") {
      fetchSignedUrl(orgDetail.organizationLogoUrl);
    }
  }, [orgDetail.organizationLogoUrl]);
  const handleOrganizationEditValidation = (organization) => {
    let isValid = true;
    let errors = {};

    if (!!!organization.country || organization.country === "") {
      errors["country"] = "Please Enter Country";
      isValid = false;
    }
    if (!!!organization.address || organization.address === "") {
      errors["address"] = "Please Enter Address";
      isValid = false;
    }
    if (!!!organization.city || organization.city === "") {
      errors["city"] = "Please Enter City";
      isValid = false;
    }

    if (!!!organization?.countryCode || organization.countryCode === "") {
      errors["countryCode"] = "Please Enter a valid Country Code.";
      isValid = false;
    }
    // } else if (!/^(\+\d+)$/.test(organization.countryCode)) {
    //   errors["countryCode"] = "Please Enter a valid Country Code.";
    //   isValid = false;
    // }
    if (!isNumeric.test(organization?.contactPhone)) {
      errors["contactPhone"] = "Please Enter Number";
      isValid = false;
    }

    if (!!!organization.taxId || organization.taxId === "") {
      errors["taxId"] = "Please Enter Tax Id";
      isValid = false;
    }
    if (!!!organization.registeredName || organization.registeredName === "") {
      errors["registeredName"] = "Please Enter Registered Name";
      isValid = false;
    }
    if (
      !!!organization.organizationCode ||
      organization.organizationCode === ""
    ) {
      errors["organizationCode"] = "Please Enter Organization Code";
      isValid = false;
    }
    return {
      isValid,
      errors,
    };
  };
  const handleErrors = (errors) => {
    let arrayOfErrors = [];
    for (let item in errors) {
      arrayOfErrors.push(errors[item]);
    }
    // showNotification(arrayOfErrors[0], "error");
    setErrors(errors);
  };
  const showError = (keyName) => {
    return errors && errors[keyName] ? (
      <p className="text-red-500 text-[14px] pl-8 pt-2">{errors[keyName]}</p>
    ) : null;
  };

  const fetchSignedUrl = async (documentUrl) => {
    try {
      const response = await createSignedRequest(documentUrl);

      if (response.status === "Success") {
        const { signed_url } = response;
        setImage(signed_url);
      }
    } catch (error) {
      showNotification("Error fetching signed URL:", "error");
    }
  };

  const handleUpdateOrganization = async () => {
    const updatedOrganization = {
      ...orgDetail,
      countryCode: `${orgDetail.countryCode || ""}`,
    };
    try {
      const payload = { organization: updatedOrganization };
      let check = handleOrganizationEditValidation(payload.organization);

      if (check.isValid) {
        setLoading(true);
        const res = await updateOrganization(organization_id, payload);
        setLoading(false);
        if (res?.status === "Success") {
          showNotification("Updated successfully", "success");
          fetchOrganisationDetail(organization_id);
          setReadOnly(true);
        } else {
          const errorMessage = res?.data?.message;
          showNotification(errorMessage, "error");
        }
      } else {
        handleErrors(check.errors);
      }
    } catch (error) {
      showNotification("Not added member. An error occurred.", "error");
    }
  };

  useEffect(() => {
    if (organization_id) fetchOrganisationDetail(organization_id);
  }, [organization_id]);
  const handleFileInput = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      handleFile(file);
    } else {
      alert("Please select a valid image file.");
    }
  };
  const handleFile = async (file) => {
    try {
      setLoading(true);
      const res = await orgAdminUploader(organization_id, file);
      setLoading(false);
      if (res.status === "Success") {
        // showNotification("Uploaded successfully", "success");

        setReadOnly(true);

        fetchOrganisationDetail(organization_id);
      }
    } catch (e) {
      showNotification("Error", "error");
    }
  };
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  return (
    <>
      <div className="flex flex-col items-center  my-5 text-center w-[80%] mx-auto">
        <h2 className="mb-3 text-2xl font-semibold text-gray-800 ">
          Organization Information
        </h2>

        <div className="flex items-center h-4 mx-auto ">
          {isLoading ? <Loader /> : null}
        </div>

        <div className="mt-5 p-6 border-2 border-gray-300 rounded-lg shadow-lg bg-white w-[70%]">
          <div className="flex justify-end">
            <Button
              onClick={() => handleUpdateOrganization()}
              className={`text-white px-4 py-2 mb-4 mr-3 ${!readOnly ? "bg-black" : ""
                }`}
              text={!readOnly ? "Save" : ""}
            />

            <Button
              onClick={() => setReadOnly(!readOnly)}
              className={`text-white cursor-pointer px-4 py-2 mb-4 ${readOnly ? "bg-black" : "bg-blue-700"
                }`}
              text={!readOnly ? "Cancel" : "Edit"}
            />
          </div>
          {!readOnly ? (
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center justify-start">
                <span className="text-gray-800 text-md">
                  Organization Logo:
                </span>
              </div>
              {!loading ? (
                <label
                  htmlFor="fileInput"
                  className="flex items-center justify-center w-12 h-12 overflow-hidden bg-gray-200 rounded-full cursor-pointer"
                >
                  {image ? (
                    <img
                      src={image}
                      alt="Avatar"
                      className="object-cover h-full w-f"
                    />
                  ) : (
                    <span className="text-gray-500 ">No Avatar</span>
                  )}
                </label>
              ) : (
                <div className="flex items-center justify-start">
                  <Loader />
                </div>
              )}

              {!readOnly ? (
                <input
                  id="fileInput"
                  data-testid="fileInput"
                  type="file"
                  accept="image/*"
                  hidden={"hidden"}
                  onChange={handleFileInput}
                />
              ) : null}

              <div className="flex justify-start">
                <span className="text-gray-800 text-md">Phone:</span>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row justify-start gap-4">
                  <div
                    style={{
                      width: "120px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="flex items-center h-10 border rounded-lg focus:outline-none focus:ring-1">
                      <span className="text-gray-700 pointer-events-none text-[18px] ml-1 mr-1"></span>

                      <input
                        value={`${orgDetail.countryCode ? orgDetail.countryCode : ""
                          }`}
                        onChange={(e) =>
                          handleOrganisationChange(
                            "countryCode",
                            e.target.value
                          )
                        }
                        placeholder="Code"
                        isValid={true}
                        disabled={readOnly}
                        maxLength={4}
                        className="w-[80%] py-1 text-gray-700 placeholder-gray-400 outline-none"
                      />
                    </div>
                  </div>
                  <InputForm
                    value={orgDetail?.contactPhone}
                    handleChange={(e) =>
                      handleOrganisationChange("contactPhone", e.target.value)
                    }
                    placeholder={"Enter Contact Phone "}
                    isValid={true}
                    disabled={readOnly}
                  />
                </div>
                {showError("countryCode")}
                {showError("contactPhone")}
              </div>

              <div className="flex justify-start">
                <span className="text-gray-800 text-md">
                  Organization Code:
                </span>
              </div>
              <div className="flex flex-col justify-start">
                <InputForm
                  value={orgDetail?.organizationCode}
                  handleChange={(e) =>
                    handleOrganisationChange("organizationCode", e.target.value)
                  }
                  placeholder={"Enter Organization Code"}
                  disabled={readOnly}
                  isValid={true}
                />
                {showError("organizationCode")}
              </div>

              <div className="flex justify-start">
                <span className="text-gray-800 text-md">
                  Organization Name:
                </span>
              </div>
              <div className="flex flex-col justify-start">
                <InputForm
                  value={orgDetail?.registeredName}
                  handleChange={(e) =>
                    handleOrganisationChange("registeredName", e.target.value)
                  }
                  placeholder={"Enter Registered Name"}
                  disabled={readOnly}
                  isValid={true}
                />
                {showError("registeredName")}
              </div>
              <div className="flex justify-start">
                <span className="text-gray-800 text-md">Address:</span>
              </div>
              <div className="flex flex-col justify-start">
                <InputForm
                  value={orgDetail?.address}
                  handleChange={(e) =>
                    handleOrganisationChange("address", e.target.value)
                  }
                  placeholder={"Enter Address"}
                  disabled={readOnly}
                  isValid={true}
                />
                {showError("address")}
              </div>
              <div className="flex justify-start">
                <span className="text-gray-800 text-md">City:</span>
              </div>
              <div className="flex flex-col justify-start">
                <InputForm
                  value={orgDetail?.city}
                  handleChange={(e) =>
                    handleOrganisationChange("city", e.target.value)
                  }
                  placeholder={"Enter City"}
                  disabled={readOnly}
                  isValid={true}
                />
                {showError("city")}
              </div>
              <div className="flex justify-start">
                <span className="text-gray-800 text-md">Country:</span>
              </div>
              <div className="flex flex-col justify-start">
                <InputForm
                  value={orgDetail?.country}
                  handleChange={(e) =>
                    handleOrganisationChange("country", e.target.value)
                  }
                  placeholder={"Enter Country"}
                  disabled={readOnly}
                  isValid={true}
                />
                {showError("country")}
              </div>
              <div className="flex justify-start">
                <span className="text-gray-800 text-md">Tax Id:</span>
              </div>
              <div className="flex flex-col justify-start">
                <InputForm
                  value={orgDetail?.taxId}
                  handleChange={(e) =>
                    handleOrganisationChange("taxId", e.target.value)
                  }
                  placeholder={"Enter Tax Id"}
                  disabled={readOnly}
                  isValid={true}
                />
                {showError("taxId")}
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center justify-start">
                <span className="text-gray-800 text-md">
                  Organization Logo:
                </span>
              </div>
              <label
                htmlFor="fileInput"
                className="flex items-center justify-center w-12 h-12 overflow-hidden bg-gray-200 rounded-full cursor-pointer"
              >
                {!loading ? (
                  image ? (
                    <img
                      src={image}
                      alt="Avatar"
                      className="object-cover h-full w-f"
                    />
                  ) : (
                    <span className="text-gray-500 ">No Avatar</span>
                  )
                ) : (
                  <Loader />
                )}
              </label>

              {/* {!readOnly ? (
                <input
                  id="fileInput"
                  data-testid="fileInput"
                  type="file"
                  accept="image/*"
                  hidden={"hidden"}
                  onChange={handleFileInput}
                />
              ) : null} */}

              <div className="flex justify-start">
                <span className="text-gray-800 text-md">Phone:</span>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row justify-start gap-4">
                  <div
                    style={{
                      width: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span className="text-gray-700 text-md">
                      {orgDetail?.countryCode}
                    </span>
                  </div>
                  <span className="text-gray-700 text-md">
                    {orgDetail?.contactPhone}
                  </span>
                </div>
              </div>

              <div className="flex justify-start">
                <span className="text-gray-800 text-md">
                  Organization Code:
                </span>
              </div>
              <div className="flex justify-start">
                <span className="text-gray-700 text-md">
                  {orgDetail?.organizationCode}
                </span>
              </div>

              <div className="flex justify-start">
                <span className="text-gray-800 text-md">
                  Organization Name:
                </span>
              </div>
              <div className="flex justify-start">
                <span className="text-gray-700 text-md">
                  {orgDetail?.registeredName}
                </span>
              </div>
              <div className="flex justify-start">
                <span className="text-gray-800 text-md">Address:</span>
              </div>
              <div className="flex justify-start">
                <span className="text-gray-700 text-md">
                  {orgDetail?.address}
                </span>
              </div>
              <div className="flex justify-start">
                <span className="text-gray-800 text-md">City:</span>
              </div>
              <div className="flex justify-start">
                <span className="text-gray-700 text-md">{orgDetail?.city}</span>
              </div>
              <div className="flex justify-start">
                <span className="text-gray-800 text-md">Country:</span>
              </div>
              <div className="flex justify-start">
                <span className="text-gray-700 text-md">
                  {orgDetail?.country}
                </span>
              </div>
              <div className="flex justify-start">
                <span className="text-gray-800 text-md">Tax Id:</span>
              </div>
              <div className="flex justify-start">
                <span className="text-gray-700 text-md">
                  {orgDetail?.taxId}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrganizationDetail;
