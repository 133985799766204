const InputForm = ({
  value,
  handleChange,
  label,
  id,
  placeholder,
  errorMessage,
  disabled,
  isValid,
  type,
  readOnly,
}) => {
  return (
    <>
      <div className="w-full max-w-md mx-auto">
        {label && (
          <label
            for={id}
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        <input
          type={type ? type : "text"}
          id={id}
          placeholder={placeholder}
          className={`w-full px-4 py-2 text-gray-700 placeholder-gray-400 transition duration-200 ease-in-out border rounded-lg focus:outline-none focus:ring-2 ${
            isValid
              ? "border-gray-300 focus:ring-gray-500"
              : "border-red-400 focus:ring-red-500"
          }`}
          value={value}
          aria-invalid={!isValid}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete="new-password"
          data-testid={id}
        />

        {!isValid && <p className="text-red-400">{errorMessage}</p>}
      </div>
    </>
  );
};

export default InputForm;
