import React, { useEffect, useState } from "react";
import { Image } from "../Image/Image";
import image from "../../Images/Image.svg";
import checkIcon from "../../Images/Checks.svg";
import { attemptUploader } from "../../services/uploaderApi";
import { showNotification } from "../Toastify/Toast";

class PromiseQueue {
  constructor() {
    this.queue = [];
    this.running = false;
  }

  add(promiseFn) {
    this.queue.push(promiseFn);
    this.run();
  }

  async run() {
    if (this.running) return;
    this.running = true;

    while (this.queue.length) {
      const promiseFn = this.queue.shift();
      try {
        await promiseFn();
      } catch (err) {
        showNotification("Promise in queue failed", "error");
      }
    }

    this.running = false;
  }
}

const MultiFileUploader = ({
  onFilesSelected,
  accept = ".pdf, image/*, .doc, .docx",
  id,
  tags,
  organizationId,
  caseId,
  countComplain,
  setCountComplain,
  alreadyUploadedDocs,
}) => {
  const [files, setFiles] = useState([]);
  const [completedFiles, setCompletedFiles] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const queue = new PromiseQueue();

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      setFiles(selectedFiles);
      setProgress(0);
      setIsUploading(true);
      onFilesSelected(selectedFiles);
      enqueueFilesForUpload(selectedFiles);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length > 0) {
      setFiles(droppedFiles);
      setProgress(0);
      setIsUploading(true);
      onFilesSelected(droppedFiles);
      enqueueFilesForUpload(droppedFiles);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const enqueueFilesForUpload = (selectedFiles) => {
    const totalFiles = selectedFiles.length;
    let uploadedFiles = 0;

    selectedFiles.forEach((file) => {
      queue.add(async () => {
        try {
          const res = await attemptUploader(organizationId, caseId, tags, file);
          setIsUploading(false);
          if (res?.data?.status === "Success") {
            uploadedFiles += 1;
            setProgress((uploadedFiles / totalFiles) * 100);
            if (uploadedFiles === totalFiles) {
              setTimeout(() => {
                setProgress(0);
                setFiles([]);
                setCompletedFiles(completedFiles + totalFiles);
                clearFileInput(id);
              }, 1000);
            }
          } else {
            setTimeout(() => {
              setProgress(0);
              setFiles([]);
              clearFileInput(id);
            }, 500);
          }
        } catch (error) {
          showNotification("Document uploading failed", "error");
        }
      });
    });
  };
  useEffect(() => {
    if (progress === 100) {
      setCountComplain(Number(countComplain) + Number(files.length));
    }
  }, [files, progress]);

  const getTotalFileCount = () => {
    return (
      (alreadyUploadedDocs?.length ? alreadyUploadedDocs?.length : 0) +
      completedFiles
    );
  };

  const handleDivClick = (id) => {
    let fileElement = document.getElementById(id);
    if (fileElement) {
      fileElement.click();
    }
  };

  const clearFileInput = (id) => {
    let fileElement = document.getElementById(id);
    if (fileElement) {
      fileElement.value = null;
    }
  };

  return (
    <>
      <div className="mt-4 w-[626px]">
        <div
          onClick={(e) => handleDivClick(id)}
          className="cursor-pointer flex flex-col items-center justify-center px-0 py-8 text-[#4B4B4B] border-2 border-[#4B4B4B] border-dashed rounded-lg bg-[#FFF] hover:bg-[#FFF]"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {countComplain > 0 ? (
            <div className="pb-2 text-sm font-semibold">
              {countComplain > 0 ? countComplain : ""}{" "}
              {countComplain === 1 ? "Document Uploaded" : "Documents Uploaded"}{" "}
            </div>
          ) : null}
          {progress < 100 ? (
            !isUploading ? (
              <>
                <label>
                  <Image
                    src={image}
                    alt="uploadImage"
                    className="flex items-center justify-center cursor-pointer"
                  />
                </label>
                <p className="mt-2 mb-2 text-sm">
                  Drag files here, or click to browse
                </p>
              </>
            ) : (
              <div className="flex items-center justify-center space-x-2">
                <svg
                  className="w-5 h-5 text-gray-500 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="progressbar"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l1.457-1.457A7.956 7.956 0 014 12H0c0 2.28.79 4.373 2.107 6.027L6 17.291z"
                  ></path>
                </svg>
              </div>
            )
          ) : (
            <Image src={checkIcon} alt="checkIcon" />
          )}
          {getTotalFileCount() > 0 && (
            <div className="w-10/12 mt-4">
              <div className="flex items-center justify-center">
                <span>
                  {!isUploading ? "Uploaded Total" : "uploading"}{" "}
                  {getTotalFileCount()}{" "}
                  {getTotalFileCount() === 1 ? "document" : "documents"}
                </span>
              </div>
              {isUploading ? (
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                  <div
                    className="bg-gray-600 h-2.5 rounded-full"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <input
        hidden={"hidden"}
        data-testid="fileInput"
        type="file"
        accept={accept}
        className="hidden"
        multiple
        onChange={handleFileSelect}
        id={id}
      />
    </>
  );
};

export default MultiFileUploader;
