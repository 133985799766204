import react from "react";
import FormTextArea from "../../../../components/FormTextArea/FormTextArea";

export default function EditPlaybookContent({
  title,
  id,
  placeholder,
  value,
  handleChange,
  wordLimit
}) {

  return (
    <>
      <FormTextArea
        label={title}
        id={id}
        placeholder={placeholder}
        value={value}
        handleChange={handleChange}
        wordLimit={wordLimit}
        isWordLimitEnabled={true}
      />
    </>
  );
}
