import Overlay from "../../../../components/Overlay/Overlay";
import Button from "../../../../components/Button/Button";
import InputForm from "../../../../components/InputForm/InputForm";
import Loader from "../../../../components/Loaders/Loader";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch";

function EditClaim({
    agent,
    issubclaimmodal,
    handlSubClaimModalClose,
    editClaimDetails,
    iscreatesubclaimloader,
    subclaimtitle,
    setSubClaimTitle,
    isremovesubclaimloder,
    removeSubclaim,
    handleSubClaim
}) {
    return (
        <Overlay
        isOpen={issubclaimmodal}
        onClose={handlSubClaimModalClose}
        title={editClaimDetails ? "Edit Subclaim" : "Create Subclaim"}
        className="custom-class"
        position="center"
      >
        <div>
          <InputForm
            label="Title"
            value={subclaimtitle}
            handleChange={(e) => setSubClaimTitle(e.target.value)}
            placeholder={"e.g. Wipe customers credit account"}
            isValid={true}
            data-testid="details"
          />
        </div>
        <div className="flex flex-row justify-between gap-6 px-8 mt-8">
          <PermissionSwitch
            shouldHaveRoles={[
              AGENT_ROLE_SUPERADMIN,
              AGENT_ROLE_ADMIN,
              AGENT_ROLE_CONTRIBUTER,
            ]}
            hasRole={agent?.role}
            yes={
              <>
                {editClaimDetails && (
                  <>
                    {isremovesubclaimloder ? (
                      <div className="w-[100%]">
                        <Loader />
                      </div>
                    ) : (
                      <Button
                        className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
                        text={"Delete"}
                        textcolor="red"
                        onClick={() =>
                          removeSubclaim(editClaimDetails?._id)
                        }
                      />
                    )}
                  </>
                )}
              </>
            }
            no={
              <>
                {editClaimDetails && (
                  <Button
                    className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
                    text={"Delete"}
                    textcolor="red"
                    onClick={() => {}}
                  />
                )}
              </>
            }
          />
          <Button
            className="w-full bg-[#F2F2F2] px-4 py-2 "
            text={"Cancel"}
            textcolor="#000000"
            onClick={handlSubClaimModalClose}
          />
          <PermissionSwitch
            shouldHaveRoles={[
              AGENT_ROLE_SUPERADMIN,
              AGENT_ROLE_ADMIN,
              AGENT_ROLE_CONTRIBUTER,
            ]}
            hasRole={agent?.role}
            yes={
              <>
                {iscreatesubclaimloader ? (
                  <div className="w-[100%]">
                    <Loader />
                  </div>
                ) : (
                  <Button
                    className="w-full bg-[#000000] text-white px-4 py-2 "
                    text={editClaimDetails ? "Save" : "Create"}
                    onClick={() => handleSubClaim(editClaimDetails?._id)}
                    data-testid="Submit"
                  />
                )}
              </>
            }
            no={
              <Button
                className="w-full bg-[#000000] text-white px-4 py-2 "
                text={editClaimDetails ? "Save" : "Create"}
                disabled={true}
                data-testid="Submit"
              />
            }
          />
        </div>
      </Overlay>
    )
}

export default EditClaim;