import axios from "axios";
import storageService from "./storageService";
import { attemptLogout } from "./loginUtility";
import { SIGNIN_ROUTE, ADMIN_SIGNIN_ROUTE } from "../constants/routeEnums";
import { ENV_LOCAL, USER_TYPE, USER_TYPE_AGENT, USER_TYPE_ADMIN } from "../constants/enum";

const API_URL = process.env.REACT_APP_STAGE === ENV_LOCAL ? process.env.REACT_APP_CORE_API : `/api`;

export default async function callApi({
  endpoint,
  method,
  body,
  service = "core",
}) {
  try {
    let authUser = storageService.getItem("token")
      ? storageService.getItem("token")
      : null;

    let headers = {
      "content-type": "application/json",
    };

    if (authUser) {
      let token = authUser;
      if (token && token !== "") {
        // @ts-ignore
        headers.Authorization = `Bearer ${token}`;
      }
    }
    let response = await axios({
      url: `${API_URL}/${endpoint}`,
      method,
      data: body,
      headers,
      timeout: 300000,
    });
    return response;
  } catch (e) {
    if (e.status === 401) {
      let userType = storageService.getItem(USER_TYPE)
        ? storageService.getItem(USER_TYPE)
        : null;
      attemptLogout()
      if (userType && userType === USER_TYPE_AGENT) {
        window.location = SIGNIN_ROUTE;
      } else if (userType === USER_TYPE_ADMIN) {
        window.location = ADMIN_SIGNIN_ROUTE;
      }
    }

    throw e instanceof Error ? e : new Error(e?.response?.data?.data?.message || "API CALL Failed");

  }
}
