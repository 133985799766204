import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import {
  getPlaybookCompaints,
  createPlaybook,
  updatePlaybook,
  updatePlaybookStatus,
  updatePlaybookComplaint,
  deletePlaybook,
  copyClonePlaybook,
} from "../../../../services/coreService";
import { showNotification } from "../../../../components/Toastify/Toast";
import PermissionSegment from "../../../../components/PermissionLocks/PermissionSegment";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import { useUser } from "../../../../context/UserContext";
import AddComplaintTypeModal from "./AddComplaintTypeModal";

import {
  PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES,
  PLAYBOOK_COMPLAINT_TYPE_BILLING,
  PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE,
  PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY,
  PLAYBOOK_COMPLAINT_TYPE_UNKNOWN,
  PLAYBOOK_COMPLAINT_TYPE_PACKAGE,
} from "../../../../constants/playbookComplaintsTypeConstants";
import EditComplaintModal from "./EditComplaintModal";
import FormTextArea from "../../../../components/FormTextArea/FormTextArea";
import playbookComplaints from "../../../../constants/playbookComplaintsData.json";
import PlaybookModalContent from "./PlaybookModalContent";
import PlaybookSideBar from "./PlaybookSideBar";
import PlaybookDetails from "./PlaybookDetails";

const Playbook = ({
  playbookData,
  organizationId,
  setPlayBookData,
  setPageNum,
  pageNum,
  totalPages,
}) => {
  const [isLabelClicked, setIsLabelClicked] = useState(false);
  const [expandedComplaints, setExpandedComplaints] = useState([]);
  const [playbookComplaintsData, setplaybookComplaintsData] = useState([]);
  const [selectedPlaybook, setSelectedPlaybook] = useState("");
  const [selectedPlaybookStatus, setSelectedPlaybookStatus] = useState("");

  const [playbookStatusLoader, setPlaybookStatusLoader] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [isAddComplaintModalOpen, setIsAddComplaintModalOpen] = useState(false);

  const [editApologyPlaybookLoading, setEditApologyPlaybookLoading] =
    useState(false);
  const [editCompaintPlaybookLoading, setEditCompaintPlaybookLoading] =
    useState(false);

    const [editplaybooktitleLoading, seteditplaybooktitleLoading] =
    useState(false);  

  const [isEditableApology, setIsEditableApology] = useState({});
  const [isEditableComplaint, setIsEditableComplaint] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [playbooktitle, setPlaybookTitle] = useState("")


  const filteredComplaintTypes = [
    PLAYBOOK_COMPLAINT_TYPE_BILLING,
    PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES,
    PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE,
    PLAYBOOK_COMPLAINT_TYPE_PACKAGE,
    PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY,
    PLAYBOOK_COMPLAINT_TYPE_UNKNOWN,
  ].filter(
    (type) =>
      !playbookComplaintsData?.some(
        (complaint) => complaint?.typeOfComplaint === type
      )
  );

  const { agent } = useUser();

  const loadMore = () => {
    const nextPage = pageNum + 1;
    setPageNum(nextPage);
  };

  const toggleEditableApology = (itemId) => {
    setIsEditableApology((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const toggleEditableComplaint = (itemId) => {
    setIsEditableComplaint((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const handleOpenEditModal = (complaint) => {
    setSelectedComplaint(complaint);
    setIsEditModalOpen(true);
  };

  const handleOpenAddComplaintModal = () => {
    if (filteredComplaintTypes?.length < 1) {
      showNotification("Complaint types have already been filled", "error");
      return;
    }
    setIsAddComplaintModalOpen(true);
  };

  useEffect(() => {
    if (playbookData?.length > 0) {
      if (isEmpty(selectedPlaybook)) {
        const firstPlaybook = playbookData[0];
        setSelectedPlaybook(firstPlaybook);

        const status = firstPlaybook?.isActive ? "Active" : "Inactive";
        setSelectedPlaybookStatus(status);

        setIsLabelClicked(true);
        fetchPlaybook(firstPlaybook._id);
      }
    }
  }, [playbookData]);

  useEffect(() => {
    if (selectedPlaybook?.name) {
      setPlaybookTitle(selectedPlaybook.name);
    }
  }, [selectedPlaybook]);

  const handleClickCopyClone = async (organizationId,id)=> {
    try {
      setIsLoading(true)
      const res = await copyClonePlaybook(organizationId, id);
      if (res?.status === "Success") {
      setIsLoading(false)
       const { playbook } = res.data;
       setPlayBookData((prev) => [...prev, playbook]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handlePlaybookStatus = async (playbookId, status) => {
    setPlaybookStatusLoader(true);
    try {
      const statusBody = {
        action: status,
      };

      const data = await updatePlaybookStatus(
        organizationId,
        playbookId,
        statusBody
      );

      if (data.error) {
        return;
      }

      setSelectedPlaybook((prev) => ({
        ...prev,
        isActive: !prev.isActive,
      }));

      setPlayBookData((prevData) =>
        prevData.map((playbook) => {
          if (playbook._id === playbookId) {
            return { ...playbook, isActive: !playbook.isActive };
          } else {
            return {
              ...playbook,
              isActive:
                playbook.isActive && playbookId === playbook._id
                  ? !playbook.isActive
                  : false,
            };
          }
        })
      );
    } catch (error) {
      console.error("Error Updating Playbook Status", error);
    }

    setPlaybookStatusLoader(false);
  };

  const handleCreatePlaybook = async (input) => {
    if (!input.playbookName) {
      showNotification("Please Enter playbook name", "error");
      return;
    }
    if (!input.apologyCriteria) {
      showNotification("Please Enter apology criteria", "error");
      return;
    }
    if (!input.complainCriteria) {
      showNotification("Please Enter complain criteria", "error");
      return;
    }
    if (input.complaintTypes.length === 0) {
      showNotification("Please add atleast one complain type", "error");
      return;
    }
    try {
      const playbook = {
        playbookName: input.playbookName,
        complaintClassifyingCriteria: input.complainCriteria,
        apologyLetterGuidance: input.apologyCriteria,
        complaintTypes: input.complaintTypes,
      };
      const res = await createPlaybook(organizationId, playbook);

      if (res?.status === "Success") {
        const newPlaybook = res.data.playbook;
        setPlayBookData((prev) => [...prev, newPlaybook]);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPlaybook = async (playbookId) => {
    try {
      const res = await getPlaybookCompaints(playbookId, organizationId);

      if (res?.status === "Success") {
        const { playbookComplaints } = res.data;

        setplaybookComplaintsData(playbookComplaints);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePlaybookClick = (data) => {
    setIsLabelClicked(true);
    setSelectedPlaybook(data);
    const status = data?.isActive ? "Active" : "Inactive";
    setSelectedPlaybookStatus(status);
    fetchPlaybook(data._id);
  };

  const toggleComplaintDetails = (complaintId) => {
    setExpandedComplaints((prev) =>
      prev.includes(complaintId)
        ? prev.filter((id) => id !== complaintId)
        : [...prev, complaintId]
    );
  };

  const handleUpdatePlaybook = async (id) => {
    if (id === "apologyLetterGuidance") setEditApologyPlaybookLoading(true);
    if (id === "playbooktitlechange") seteditplaybooktitleLoading(true);
    else setEditCompaintPlaybookLoading(true);

    try {
      const payload = {
        playbook: {
          name: playbooktitle , // Keep the same name if not updating
          complaintClassifyingCriteria:
            selectedPlaybook.complaintClassifyingCriteria,
          apologyLetterGuidance: selectedPlaybook.apologyLetterGuidance,
        },
      };

      const response = await updatePlaybook(
        organizationId,
        selectedPlaybook._id,
        payload
      );

      if (response?.status === "Success") {
        const status = response?.data?.playbook?.isActive
          ? "Active"
          : "Inactive";
        setSelectedPlaybookStatus(status);

        setPlayBookData((prevData) =>
          prevData.map((playbook) =>
            playbook._id === response?.data?.playbook._id
              ? { ...playbook, ...response?.data?.playbook }
              : playbook
          )
        );
        setSelectedPlaybook((prevPlaybook) => ({
          ...prevPlaybook,
          ...response.data.playbook,
        }));
      }
    } catch (error) {
      console.error(error);
    }
    if (id === "apologyLetterGuidance") setEditApologyPlaybookLoading(false);
    if (id === "playbooktitlechange") seteditplaybooktitleLoading(false);
    else setEditCompaintPlaybookLoading(false);



  };

  const handleRemovingPlaybook = async (id) => {
    setEditCompaintPlaybookLoading(true);
    try {
      const response = await deletePlaybook(organizationId, id);
      setEditCompaintPlaybookLoading(false);
      if (response?.status === "Success") {
        setPlayBookData((prevData) =>
          prevData.filter((playbook) => playbook._id !== id)
        );
        setSelectedPlaybook({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateComplaint = async () => {
    setIsLoading(true);
    try {
      const payload = {
        complaintTypePlaybook: {
          typeOfComplaint: selectedComplaint.typeOfComplaint,
          criteriaToIdentifySubClaims:
            selectedComplaint.criteriaToIdentifySubClaims,
          criteriaToAssessEvidence: selectedComplaint.criteriaToAssessEvidence,
          remedialActionsGuidance: selectedComplaint.remedialActionsGuidance,
          monetaryValueGuidance: selectedComplaint.monetaryValueGuidance,
        },
      };

      await updatePlaybookComplaint(
        organizationId,
        selectedComplaint?._id,
        payload
      );
      fetchPlaybook(selectedPlaybook._id);

      setIsEditModalOpen(false);
    } catch (error) {
      console.error(error);
      showNotification("Failed to update complaint", "error");
    }
    setIsLoading(false);
  };

  return (
    <PermissionSegment
      shouldHaveRoles={[AGENT_ROLE_SUPERADMIN, AGENT_ROLE_ADMIN]}
      hasRole={agent?.role}
    >
      <div className="w-[100%]">
        <div className="grid grid-cols-[26.27%_calc(100%-26.27%)]">
          {/* Left Sidebar */}
          <PlaybookSideBar
            playbookData={playbookData}
            pageNum={pageNum}
            loadMore={loadMore}
            totalPages={totalPages}
            handlePlaybookClick={handlePlaybookClick}
            setIsModalOpen={setIsModalOpen}
            selectedPlaybook={selectedPlaybook}
          />
          {/* Right Content */}
          <div>
            {isLabelClicked && (
              <PlaybookDetails
                selectedPlaybook={selectedPlaybook}
                setSelectedPlaybook={setSelectedPlaybook}
                selectedPlaybookStatus={selectedPlaybookStatus}
                handlePlaybookStatus={handlePlaybookStatus}
                playbookStatusLoader={playbookStatusLoader}
                editApologyPlaybookLoading={editApologyPlaybookLoading}
                isEditableApology={isEditableApology}
                toggleEditableApology={toggleEditableApology}
                handleOpenAddComplaintModal={handleOpenAddComplaintModal}
                handleOpenEditModal={handleOpenEditModal}
                handleUpdatePlaybook={handleUpdatePlaybook}
                editCompaintPlaybookLoading={editCompaintPlaybookLoading}
                toggleEditableComplaint={toggleEditableComplaint}
                isEditableComplaint={isEditableComplaint}
                expandedComplaints={expandedComplaints}
                playbookComplaintsData={playbookComplaintsData}
                toggleComplaintDetails={toggleComplaintDetails}
                handleRemovingPlaybook={handleRemovingPlaybook}
                handleClickCopyClone={handleClickCopyClone}
                isLoading={isLoading}
                setPlaybookTitle={setPlaybookTitle}
                playbooktitle={playbooktitle}
                editplaybooktitleLoading={editplaybooktitleLoading}
              />
            )}
          </div>

          {/*Create Playbook Modal*/}
          {isModalOpen && (
            <PlaybookModalContent
              playbookComplaints={playbookComplaints}
              handleCreatePlaybook={handleCreatePlaybook}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </div>

        {isEditModalOpen && (
          <EditComplaintModal
            selectedComplaint={selectedComplaint}
            setIsEditModalOpen={setIsEditModalOpen}
            handleUpdateComplaint={handleUpdateComplaint}
            organizationId={organizationId}
            selectedPlaybook={selectedPlaybook}
            fetchPlaybook={fetchPlaybook}
          />
        )}

        {isAddComplaintModalOpen && (
          <AddComplaintTypeModal
            organizationId={organizationId}
            playbookComplaintsData={playbookComplaintsData}
            selectedPlaybook={selectedPlaybook}
            setIsAddComplaintModalOpen={setIsAddComplaintModalOpen}
            setplaybookComplaintsData={setplaybookComplaintsData}
          />
        )}
      </div>
    </PermissionSegment>
  );
};

export default Playbook;
