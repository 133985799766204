export const DOCUMENT_UPLOAD_PENDING = "document_upload_pending"
export const PROCESSING_AI_FAILED = "processing_ai_fail"
export const PROCESSING_AI = "processing_ai"
export const DOCUMENT_AI_COMPLETED = "document_ai_completed"
export const DOCUMENT_AI_FAILED = "document_ai_failed"
export const PROCESSING_AI_COMPLETED = "processing_ai_complete"

export const CREATED_FIRST = "created_first"
export const CREATED_LAST = "created_last"
export const MODIFIED_FIRST = "modified_first"
export const MODIFIED_LAST = "modified_last"
export const ACTIVE = "active"
export const INACTIVE = "inactive"

