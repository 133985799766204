import dayjs from "dayjs";

export function isDaysPassed(startTime, refreshAvailabeleBefore) {
  /**
   * Check if two days have passed since the given start time.
   *
   * @param {Date | string} startTime - The starting time (as a Date object or ISO string).
   * @returns {boolean} - True if two days have passed, False otherwise.
   */
  const now = dayjs();
  const start = dayjs(startTime);
  const twoDaysLater = start.add(refreshAvailabeleBefore, "day");

  return now.isAfter(twoDaysLater);
}
