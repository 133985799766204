import { Divider } from "../../../../components/Divider/Divider";
import { Image } from "../../../../components/Image/Image";
import { Switch } from "../../../../components/Switch/Switch";
import { useUser } from "../../../../context/UserContext";
import {
  ADD_REMEDIAL_ACTIONS,
  AI_GENERATED_TEXT,
  MONETARY_VALUE,
  REMEDIAL_ACTION,
  SUBCLAIM_VALID_DESC,
  USER_CONFIRMATION_TEXT,
} from "../../../../constants/casesConstants";
import Valid from "../../../../Images/CheckCircle.svg";
import inValid from "../../../../Images/XCircle.svg";
import collapse from "../../../../Images/Collapse.svg";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import EditButton from "../../../../components/EditButton/EditButton";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch";
import PermissionFunction from "../../../../components/PermissionLocks/PermissionFunction";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_READER,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import Switch2_0 from "../../../../components/SwitchV2/SwitchV2";
import { formatDateToDDMMYYYY } from "../../../../utils/dateFormat";
import truncate from "lodash/truncate";
import Label from "../../../../components/Label/Label";
import Loader from "../../../../components/Loaders/Loader";

function SubClaims({
  handleshowClaim,
  handlRemedialModalOpen,
  handleSubClaimModalOpen,
  handleRemedialConformation,
  isshowclaim,
  claim,
  handleMonetaryConformation,
  handlEditMonetaryModalOpen,
  handlEditValidityModalOpen,
  handleCaseClaimValidate,
  remedialactionlist,
  monetaryValueState,
  selectedIndex,
  index,
  selectedClaim,
  handlcreateRemedialModalOpen,
  isuserconfirmationloader,
}) {
  const { agent } = useUser();

  const getValidityState = (item) => {
    const validity = item === undefined || item === null ? "Invalid" : item;

    if (validity === "Valid") {
      return (
        <div className="flex items-center gap-2 mb-2">
          <Image className="w-[25px] h-[25px]" src={Valid} />
          <span className="font-normal text-[22px] text-[#589E67]">
            {"Valid"}
          </span>
        </div>
      );
    } else if (validity === "Invalid") {
      return (
        <div className="flex items-center gap-2 mb-2">
          <Image className="w-[25px] h-[25px]" src={inValid} />
          <span className="font-normal text-[22px] text-[#AF4B4B]">
            {"Invalid"}
          </span>
        </div>
      );
    }
  };

  const getSelectedIndex = (selectedClaim, index) => {
    return selectedClaim.some((item) => item === index);
  };

  return (
    <div>
      <div className=" bg-white border border-gray-300 rounded-[4px] mt-2">
        <div>
          <div className="flex items-center justify-between pr-5 m-3">
            <div className="flex gap-3">
              <div onClick={handleshowClaim} className="cursor-pointer">
                <Image
                  src={collapse}
                  className={`ml-2 transform transition-transform duration-300 ${
                    getSelectedIndex(selectedClaim, index) ? "" : "rotate-180"
                  }`}
                  alt="collapse icon"
                  data-testid="collapse-icon"
                />
              </div>

              <h2 className="text-[18px] font-normal text-[#000000]">
                {claim?.title}
              </h2>
              {claim.isAiGenerated ? (
                <Switch2_0
                  initialState="false"
                  date={formatDateToDDMMYYYY(claim?.updatedAt)}
                />
              ) : null}
            </div>
            <PermissionSwitch
              shouldHaveRoles={[
                AGENT_ROLE_SUPERADMIN,
                AGENT_ROLE_ADMIN,
                AGENT_ROLE_CONTRIBUTER,
              ]}
              hasRole={agent?.role}
              yes={
                <EditButton
                  onClick={() => handleSubClaimModalOpen(claim)}
                  disabled={false}
                  data-testid="edit-button"
                />
              }
              no={<EditButton disabled={true} />}
            />
          </div>
          <Divider />
        </div>
        {getSelectedIndex(selectedClaim, index) && (
          <div>
            <div className="flex flex-row justify-between px-8 py-4 bg-[#F9F9F9] w-[100%]">
              <div>
                <div className="flex items-center">
                  <h3 className="text-[16px] font-bold text-black-900">
                    Validity
                  </h3>
                  <div className="ml-4">
                    {claim.isAiGenerated ? (
                      <Switch2_0
                        initialState="false"
                        date={formatDateToDDMMYYYY(claim?.updatedAt)}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="" data-testid="validity-state">
                  {getValidityState(
                    claim?.editValidity ? claim?.editValidity : claim?.validity
                  )}
                  <p className="mb-4 text-sm text-gray-600">
                    {claim?.editValidityText ? claim.editValidityText : claim?.validityText}
                  </p>
                </div>
              </div>
              <div className="w-[40%] flex justify-end">
                <div className="flex flex-col self-end gap-4">
                  <PermissionSwitch
                    shouldHaveRoles={[
                      AGENT_ROLE_SUPERADMIN,
                      AGENT_ROLE_ADMIN,
                      AGENT_ROLE_CONTRIBUTER,
                    ]}
                    hasRole={agent?.role}
                    yes={
                      <div
                        className="flex justify-end"
                        onClick={() => handlEditValidityModalOpen(claim)}
                        data-testid="edit-button"
                      >
                        <EditButton disabled={false} />
                      </div>
                    }
                    no={
                      <div className="flex justify-end">
                        <EditButton disabled={true} />
                      </div>
                    }
                  />
                  {isuserconfirmationloader[claim?._id]
                    ?.isValidityConfirmedByAgent ? (
                    <Loader />
                  ) : (
                    <div
                      className="flex items-center gap-2 cursor-pointer"
                      onClick={() =>
                        PermissionFunction(
                          agent?.role,
                          [
                            AGENT_ROLE_SUPERADMIN,
                            AGENT_ROLE_ADMIN,
                            AGENT_ROLE_CONTRIBUTER,
                          ],
                          () => handleCaseClaimValidate(claim?._id)
                        )
                      }
                    >
                      <Label
                        text={USER_CONFIRMATION_TEXT}
                        htmlFor="valid-confirmation"
                        className="text-sm text-gray-600 cursor-pointer"
                      />
                      <PermissionSwitch
                        shouldHaveRoles={[
                          AGENT_ROLE_SUPERADMIN,
                          AGENT_ROLE_ADMIN,
                          AGENT_ROLE_CONTRIBUTER,
                        ]}
                        hasRole={agent?.role}
                        yes={
                          <div data-testid="edit-validity">
                            <Checkbox
                              onChange={() => {}}
                              checked={claim?.isValidityConfirmedByAgent}
                              className="w-3 h-3 cursor-pointer"
                            />
                          </div>
                        }
                        no={
                          <Checkbox
                            className="w-3 h-3 cursor-pointer"
                            disabled={true}
                          />
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Divider />

            <div className="flex flex-row justify-between px-8 py-4 bg-[#F9F9F9] w-[100%] ">
              <div>
                <div className="flex items-center">
                  <h3 className="text-[16px] font-bold text-black-900">
                    {MONETARY_VALUE}
                  </h3>
                  <div className="ml-4">
                    {claim.isAiGenerated ? (
                      <Switch2_0
                        initialState="false"
                        date={formatDateToDDMMYYYY(claim?.updatedAt)}
                      />
                    ) : null}
                  </div>
                </div>
                {claim?.editMonetaryValue && claim?.editMonetaryValueReason ? (
                  <div className="mb-6">
                    <p className="mb-2 text-2xl text-gray-700">
                      {"£"}
                      {claim?.editMonetaryValue ? claim?.editMonetaryValue : 0}
                    </p>
                    <p className="mb-4 text-sm text-gray-600">
                      {claim?.editMonetaryValueReason}
                    </p>
                  </div>
                ) : (
                  <div className="mb-6">
                    <p className="mb-2 text-2xl text-gray-700">
                      {"£"}
                      {claim?.monetaryValue ? claim?.monetaryValue : 0}
                    </p>
                    <p className="mb-4 text-sm text-gray-600">
                      {claim?.monetaryValueReason}
                    </p>
                  </div>
                )}
              </div>

              <div className="w-[40%] flex justify-end">
                <div className="flex flex-col self-end gap-4" role="button">
                  <PermissionSwitch
                    shouldHaveRoles={[
                      AGENT_ROLE_SUPERADMIN,
                      AGENT_ROLE_ADMIN,
                      AGENT_ROLE_CONTRIBUTER,
                    ]}
                    hasRole={agent?.role}
                    yes={
                      <div
                        className="flex justify-end"
                        onClick={() => handlEditMonetaryModalOpen(claim)}
                        data-testid="edit-monetary-value"
                      >
                        <EditButton disabled={false} />
                      </div>
                    }
                    no={
                      <div className="flex justify-end">
                        <EditButton disabled={true} />
                      </div>
                    }
                  />
                  {isuserconfirmationloader[claim?._id]
                    ?.isMonetaryValidityConfirmedByAgent ? (
                    <Loader />
                  ) : (
                    <div
                      className="flex items-center gap-2"
                      onClick={() =>
                        PermissionFunction(
                          agent?.role,
                          [
                            AGENT_ROLE_SUPERADMIN,
                            AGENT_ROLE_ADMIN,
                            AGENT_ROLE_CONTRIBUTER,
                          ],
                          () =>
                            handleMonetaryConformation(
                              claim,
                              claim?._id,
                              claim?.isMonetaryValidityConfirmedByAgent
                            )
                        )
                      }
                      data-testid="monetary-checkbox"
                    >
                      <label
                        htmlFor="valid-confirmation"
                        className="text-sm text-gray-600 cursor-pointer"
                      >
                        {USER_CONFIRMATION_TEXT}
                      </label>
                      <PermissionSwitch
                        shouldHaveRoles={[
                          AGENT_ROLE_SUPERADMIN,
                          AGENT_ROLE_ADMIN,
                          AGENT_ROLE_CONTRIBUTER,
                        ]}
                        hasRole={agent?.role}
                        yes={
                          <div>
                            <Checkbox
                              onChange={() => {}}
                              checked={
                                claim?.isMonetaryValidityConfirmedByAgent
                              }
                              className="w-3 h-3 bg-black cursor-pointer"
                            />
                          </div>
                        }
                        no={
                          <Checkbox
                            disabled={true}
                            className="w-3 h-3 bg-black cursor-pointer"
                          />
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <Divider />
            <div data-testid="remedialaction">
              {remedialactionlist.length > 0 && (
                <div className="bg-[#F9F9F9]">
                  <h3 className="text-[16px] font-bold text-black-900 px-8 py-4">
                    {REMEDIAL_ACTION}
                  </h3>
                  {remedialactionlist?.map((action, index) => (
                    <div className="px-8 py-4 w-full" key={index}>
                      <div className="flex flex-row w-[100%]">
                        <span className="text-[22px] text-black-800">
                          <span className="w-[7px] h-[7px] bg-black rounded-full inline-block mr-2"></span>
                          {truncate(action.title, { length: 60 })}
                        </span>
                        <div className="self-center ml-4 w-[30%]">
                          {action.isAiGenerated ? (
                            <Switch2_0
                              initialState="false"
                              date={formatDateToDDMMYYYY(action?.updatedAt)}
                            />
                          ) : null}
                        </div>
                      </div>

                      <div className="w-full flex flex-row justify-between">
                        <div className="mb-6">
                          <p className="mb-4 ml-4 text-sm text-gray-600">
                            {action.description}
                          </p>
                        </div>
                        <div>
                          <div className="w-[120px] flex justify-end">
                            <div>
                              <PermissionSwitch
                                shouldHaveRoles={[
                                  AGENT_ROLE_SUPERADMIN,
                                  AGENT_ROLE_ADMIN,
                                  AGENT_ROLE_CONTRIBUTER,
                                ]}
                                hasRole={agent?.role}
                                yes={
                                  <EditButton
                                    onClick={() =>
                                      handlRemedialModalOpen(action)
                                    }
                                    disabled={false}
                                    data-testid="edit-remedial"
                                  />
                                }
                                no={<EditButton disabled={true} />}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="bg-[#F9F9F9]">
              <div className="flex justify-between px-8 py-5">
                <div
                  className={"cursor-pointer"}
                  onClick={() => handlcreateRemedialModalOpen(claim?._id)}
                >
                  <p className="mb-2 text-[15px] text-[#000000] font-normal">
                    {ADD_REMEDIAL_ACTIONS}
                  </p>
                </div>
                <>
                  {isuserconfirmationloader[claim?._id]
                    ?.isRemedialActionConfirmedByAgent ? (
                    <Loader />
                  ) : (
                    <div
                      className="flex items-center gap-2"
                      onClick={() =>
                        PermissionFunction(
                          agent?.role,
                          [
                            AGENT_ROLE_SUPERADMIN,
                            AGENT_ROLE_ADMIN,
                            AGENT_ROLE_CONTRIBUTER,
                          ],
                          () =>
                            handleRemedialConformation(
                              claim,
                              claim?._id,
                              monetaryValueState[claim._id] !== "validate"
                            )
                        )
                      }
                    >
                      <label
                        htmlFor="valid-confirmation"
                        className="text-sm text-gray-600 cursor-pointer"
                      >
                        {USER_CONFIRMATION_TEXT}
                      </label>
                      <PermissionSwitch
                        shouldHaveRoles={[
                          AGENT_ROLE_SUPERADMIN,
                          AGENT_ROLE_ADMIN,
                          AGENT_ROLE_CONTRIBUTER,
                        ]}
                        hasRole={agent?.role}
                        yes={
                          <Checkbox
                            onChange={() => {}}
                            checked={claim.isRemedialActionConfirmedByAgent}
                            className="w-3 h-3 cursor-pointer"
                            data-testid="remedial-checkbox"
                          />
                        }
                        no={
                          <Checkbox
                            disabled={true}
                            className="w-3 h-3 cursor-pointer"
                          />
                        }
                      />
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubClaims;
