
export const PLAYBOOK_COMPLAINT_TYPE_BILLING = 'billing';
export const PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES = 'contract_disputes';
export const PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE = 'customer_service';
export const PLAYBOOK_COMPLAINT_TYPE_PACKAGE = 'package';
export const PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY = 'service_availability';
export const PLAYBOOK_COMPLAINT_TYPE_UNKNOWN = 'unknown';

export const PLAYBOOK_COMPLAINT_TYPE_BILLING_LABEL = 'Billing';
export const PLAYBOOK_COMPLAINT_TYPE_CONTRACT_DISPUTES_LABEL = 'Contract Disputes';
export const PLAYBOOK_COMPLAINT_TYPE_CUSTOMER_SERVICE_LABEL = 'Customer Service';
export const PLAYBOOK_COMPLAINT_TYPE_PACKAGE_LABEL = 'Package';
export const PLAYBOOK_COMPLAINT_TYPE_SERVICE_AVAILABILITY_LABEL = 'Service Availability';
export const PLAYBOOK_COMPLAINT_TYPE_UNKNOWN_LABEL = 'Unknown';

export const CLONE_PLAYBOOK_TEXT = "Use this to create a copy of this playbook"