import React, { createContext, useContext, useState } from 'react';
import { getTotalMonetaryValue } from '../services/coreService';

const ResponseContext = createContext();

export const ResponseProvider = ({ children }) => {
    const [isAIResponseGenerated, setisAIResponseGenerated] = useState(false);
    const [isConfirmedByAgentForDecision, setIsConfirmedByAgentForDecision] = useState(false);
    const [globalCaseStatus, setGlobalCaseStatus] = useState("");
    const [totalmonetaryValue, setTotalMonetaryValue] = useState(0);
    const [decisionValue, setDecisionValue] = useState("");


    const updateAiresponseGenerated = (status) => {
        setisAIResponseGenerated(status);
    };

    const updateTotalMonetaryValue = (status) => {
        setTotalMonetaryValue(status);
    };

    const updateGlobalCaseStatus = (status) => {
        setGlobalCaseStatus(status);
    };

    const updateConfirmedByAgentForDecision = (status) => {
        setIsConfirmedByAgentForDecision(status);
    };


    const fetchTotalMonetaryForAgentConfirm = async (organizationId, caseId) => {
        try {
            let response = await getTotalMonetaryValue(organizationId, caseId);
            if (response?.status === "Success") {
                setIsConfirmedByAgentForDecision(response?.data?.caseDecision?.isConfirmedByAgentForDecision);
                setTotalMonetaryValue(response?.data?.totalMonetaryValue);
                setDecisionValue(response?.data?.caseDecision?.decision);
            }
        } catch (error) {
            console.error("Error fetching isConfirmedByAgentForDecision:", error);
        }
    };

    return (
        <ResponseContext.Provider
            value={{
                isAIResponseGenerated,
                updateGlobalCaseStatus,
                globalCaseStatus,
                isConfirmedByAgentForDecision,
                updateAiresponseGenerated,
                updateConfirmedByAgentForDecision,
                totalmonetaryValue,
                updateTotalMonetaryValue,
                fetchTotalMonetaryForAgentConfirm,
                decisionValue,
                setDecisionValue

            }}>
            {children}
        </ResponseContext.Provider>
    );
};

export const useResponse = () => {
    const context = useContext(ResponseContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};
