import React, { useEffect, useState } from "react";
import DashboardCard from "./Component/DashboardCard";
import { dashBoardStats } from "../../../services/coreService";
import { showNotification } from "../../../components/Toastify/Toast";
import DashBoradTable from "./Component/DashBoradTable";
import Loader from "../../../components/Loaders/Loader";
function Dashboard() {
  const [dashboardstats, setDashboardStats] = useState([]);
  const [loading, setLoading] = useState(false)

let stats = [
  {header:"Total Organization Count",
    count: dashboardstats?.totalOrganizationCount
  },
  {header:"Total Active Organization Count",
    count: dashboardstats?.totalActiveOrganizationCount
  },
  {header:"Total Disabled Organization Count",
    count: dashboardstats?.totalDisabledOrganizationCount
  },
]

  useEffect(() => {
    fetchDashboardStats();
  }, []);
  const fetchDashboardStats = async () => {
    setLoading(true)
    try {
      let res;
      res = await dashBoardStats();
      if (res?.status === "Success") {
        setDashboardStats(res.data);
        setLoading(false)
      }
    } catch (error) {
      showNotification("not fetch stats", "error");
    }
  };
  return (
    <div className="m-8" data-testid="dashboard">
      {
        loading ?
        <Loader  />
        :
        <> 
      <div className="flex flex-row flex-wrap gap-12 mt-4">
        {
          stats.map((stats,index) => (
            <div key={index} data-testid="stats">
        <DashboardCard
          header={stats.header}
          count={stats.count ? stats.count : 0}
        />
              </div>
          ))
        }
      </div>
      <DashBoradTable dashboardstats={dashboardstats?.caseStats} />
      </>
}
    </div>
  );
}
export default Dashboard;
