export const ENV_PRODUCTION = "production";

export const ENV_STAGING = "staging";

export const ENV_LOCAL = "local";

export const TOKEN = "token";

export const AUTH_USER = "authUser";

export const ORGANIZATION_ID = "organization_id";

export const AGENT_ID = "agent_id";

export const CASE_ID = "case_id";

export const USER_TYPE = "user_type";

export const USER_TYPE_ADMIN = "admin";

export const USER_TYPE_AGENT = "agent";

export const ADMIN_ID = "admin_id"
