import React from "react";

const PermissionSwitch = ({ yes, no, hasRole, shouldHaveRoles }) => {
  if (shouldHaveRoles && hasRole && !shouldHaveRoles.includes(hasRole)) {
    return (
      <>{no}</>
    );
  } else {
    return <>{yes}</>;
  }
};

export default PermissionSwitch;
