import Overlay from "../../../../components/Overlay/Overlay";
import Button from "../../../../components/Button/Button";
import InputForm from "../../../../components/InputForm/InputForm";
import TextAreaForm from "../../../../components/FormTextArea/FormTextArea";
import Loader from "../../../../components/Loaders/Loader";
import {
  AGENT_ROLE_ADMIN,
  AGENT_ROLE_CONTRIBUTER,
  AGENT_ROLE_READER,
  AGENT_ROLE_SUPERADMIN,
} from "../../../../constants/agentEnums";
import PermissionSwitch from "../../../../components/PermissionLocks/PermissionSwitch";

function EditMonetaryValue({
    agent,
    iseditmonetarymodal,
    monetarytitle,
    setMonetaryTitle,
    monetaryeditreason,
    setMonetaryEditReason,
    handlEditMonetaryModalClose,
    isEditModalSaveLoader,
    editmonetaryDetails,
    editMonetaryValue
}) {
    return (
        <Overlay
            isOpen={iseditmonetarymodal}
            onClose={handlEditMonetaryModalClose}
            title="Edit Monetary Value"
            className="custom-class"
            position="center"
          >
            <div>
              <InputForm
                value={monetarytitle}
                handleChange={(e) => setMonetaryTitle(e.target.value)}
                label="Value"
                placeholder={"e.g. Wipe customers credit account"}
                isValid={true}
              />
            </div>
            <div>
              <div className="w-full max-w-md mx-auto pt-5">
                <TextAreaForm
                  defaultFontForTitle={"15"}
                  value={monetaryeditreason}
                  handleChange={(e) => setMonetaryEditReason(e.target.value)}
                  label="Justification"
                  placeholder={
                    "e.g. Based on the evidence provided it is clear that a mistake was made regarding the customers credit status and their credit account should be wiped"
                  }
                  isValid={true}
                  data-testid="editmonetary"
                />
              </div>
            </div>
            <div className="flex flex-row justify-between gap-6 px-8 mt-8">
              {/* {editclaim && (
            <Button
              className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
              text={"Cancel"}
              textcolor="red"
              onClick={handlEditMonetaryModalClose}
            />
          )} */}
              <Button
                className="w-full bg-[#F2F2F2] px-4 py-2 "
                text={"Cancel"}
                textcolor="#000000"
                onClick={handlEditMonetaryModalClose}
              />
              <PermissionSwitch
                shouldHaveRoles={[
                  AGENT_ROLE_SUPERADMIN,
                  AGENT_ROLE_ADMIN,
                  AGENT_ROLE_CONTRIBUTER,
                ]}
                hasRole={agent?.role}
                yes={
                  <Button
                    disabled={isEditModalSaveLoader}
                    className="w-full bg-[#000000] text-white px-4 py-2 "
                    text={isEditModalSaveLoader ? "Saving..." : "Save"}
                    onClick={() => editMonetaryValue(editmonetaryDetails?._id)}
                  />
                }
                no={
                  <Button
                    className="w-full bg-[#000000] text-white px-4 py-2 "
                    text={"Save"}
                    disabled={true}
                  />
                }
              />
            </div>
          </Overlay>
    );

}

export default EditMonetaryValue;