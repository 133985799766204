import React, { useState } from "react";
import Label from "../../../components/Label/Label";
import Logo from "../../../Images/mainlogo.png";
import InputPassword from "../../../components/InputPassword/InputPassword";
import { bannerText } from "../../../constants/bannerConstants";
import Button from "../../../components/Button/Button";
import BannerComponent from "../../../components/Banner/Banner";
import { attemptAdminLogin } from "../../../services/coreService";
import { TOKEN, USER_TYPE, USER_TYPE_ADMIN, ADMIN_ID, ORGANIZATION_ID } from "../../../constants/enum";
import { useNavigate } from "react-router-dom";
import { ORG_DASHBOARD_ROUTE } from "../../../constants/routeEnums";
import Loader from "../../../components/Loaders/Loader";
import storageService from "../../../services/storageService";

const AdminLoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      alert("Please fill in all fields.");
      return;
    }

    setLoading(true);

    const decryptedPassword = btoa(password);

    const requestBody = {
      admin: {
        email,
        password: decryptedPassword,
      },
    };

    const response = await attemptAdminLogin(requestBody);

    if (response?.status === "Success") {
      storageService.setItem(USER_TYPE, USER_TYPE_ADMIN);
      storageService.setItem(TOKEN, response?.data?.token);
      storageService.setItem(ORGANIZATION_ID, response?.data?.organizationId);
      storageService.setItem(ADMIN_ID, response?.data?.adminId)

      navigate(ORG_DASHBOARD_ROUTE);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen">
      <BannerComponent />

      <div className="w-1/2 bg-[#f5f5f5f5] flex flex-col items-center p-8 ">

        <div className="flex items-center mt-[62px] ml-12">
          <img
            src={Logo}
            alt={bannerText.logoAlt}
            className="h-[70px] w-full"
          />

        </div>

        <div className="mt-1 text-center ">
          <h3
            className="text-[28px] font-medium leading-[120%]"
            style={{ color: "var(--Color-Tokens-Content-Dark-Primary, #000)" }}
          >
            Sign in
          </h3>
          <p
            className="mt-2 text-[16px] font-normal leading-[150%]"
            style={{
              color: "var(--Color-Tokens-Content-Dark-Secondary, #727272)",
            }}
          >
            {bannerText.signInDescription}
          </p>
        </div>
        <form
          className="flex flex-col items-center w-full max-w-md mt-6 space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="w-full">
            <Label
              htmlFor="email"
              className="block text-[14px] font-normal  leading-[150%]"
              style={{
                color: "var(--Color-Tokens-Content-Dark-Primary, #000)",
              }}
              text="Email Address"
            />

            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              className="h-[42px] mt-2 w-full p-3 text-[14px] outline-none  leading-[150%] text-[#000] border border-gray-300 rounded bg-white"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="w-full">
            <Label
              htmlFor="password"
              className="block text-[14px] font-normal  leading-[150%]"
              style={{
                color: "var(--Color-Tokens-Content-Dark-Primary, #000)",
              }}
              text="Password"
            />
            <InputPassword
              id="password"
              label="Password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          {loading ? (
            <Loader />
          ) : (
            <Button
              text="Sign In"
              type="submit"
              className="flex items-center justify-center gap-[8px] w-full py-[9px] px-[18px] align-self-stretch border border-[#FFFFFF33] bg-[#000] text-white rounded-[4px] shadow-[0px_10px_12px_0px_rgba(255,255,255,0.26)_inset,0px_0px_0px_1px_#000,0px_0px_0px_4px_rgba(0,0,0,0.04)]"
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default AdminLoginPage;
