import {
  ROOT_ROUTE,
  RESET_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  CONTACT_US_ROUTE,
  LOGIN_ROUTE,
  SIGNIN_ROUTE,
  SETTINGS_ROUTE,
  CASELIST_ROUTE,
  CASES_ROUTE,
  CASE_UPLOAD_ROUTE,
  SIGNUP_ROUTE,
  ADMIN_SIGNIN_ROUTE,
  ADMIN_RESET_PASSWORD_ROUTE,
  ADMIN_FORGOT_PASSWORD_ROUTE,
  ORGLIST_ROUTE,
  ORG_DASHBOARD_ROUTE,
  ORG_DETAILS_ROUTE,
} from "../constants/routeEnums";
import ContactUsPage from "../modules/utilities/ContactUsPage/ContactUsPage";
import SignupPage from "../modules/authentication/SignupPage/SignupPage";
import ForgotPasswordPage from "../modules/authentication/ForgotPassword/ForgotPassword";
import ResetPasswordPage from "../modules/authentication/ResetPassword/ResetPassword";
import Settings from "../modules/utilities/Settings/Settings";
import CaseList from "../modules/utilities/CaseList/CaseList";
import Cases from "../modules/utilities/Cases/Cases";
import Caseupload from "../modules/utilities/Caseupload/Caseupload";
import Dashboard from "../modules/admin/Dashboard/Dashboard";
import OrganizationDetail from "../modules/admin/OrganizationDetail/OrganizationDetail";
import AdminLoginPage from "../modules/authentication/AdminLoginPage/AdminLoginPage";
import AdminForgotPasswordPage from "../modules/authentication/AdminForgotPassword/AdminForgotPassword";
import AdminResetPasswordPage from "../modules/authentication/AdminResetPassword/AdminResetPassword";
import OrganizationList from "../modules/admin/OrganizationList/OrganizationList";

import {
  HOME_PAGE_TITLE,
  CASELIST_TITLE,
  SETTINGS_TITLE,
  CONTACT_US_TITLE,
  CASES_TITLE,
  RESET_PASSWORD_TITLE,
  FORGOT_PASSWORD_TITLE,
  SIGNIN_TITLE,
  CASE_UPLOAD_TITLE,
  ADMIN_ORG_LIST_TITLE,
  ADMIN_SIGNIN_TITLE,
  ADMIN_FORGOT_PASSWORD_TITLE,
  ADMIN_RESET_PASSWORD_TITLE,
  ADMIN_ORG_DETAIL_TITLE,
} from "../constants/pageTitleConstants";
import { USER_TYPE_ADMIN, USER_TYPE_AGENT } from "../constants/enum";

const privateRoutes = [
  { path: ROOT_ROUTE, component: CaseList, title: HOME_PAGE_TITLE, userType: USER_TYPE_AGENT },
  { path: CASELIST_ROUTE, component: CaseList, title: CASELIST_TITLE, userType: USER_TYPE_AGENT },
  { path: SETTINGS_ROUTE, component: Settings, title: SETTINGS_TITLE, userType: USER_TYPE_AGENT },
  { path: CONTACT_US_ROUTE, component: ContactUsPage, title: CONTACT_US_TITLE, userType: USER_TYPE_AGENT },
  { path: CASES_ROUTE, component: Cases, title: CASES_TITLE, userType: USER_TYPE_AGENT },
  { path: CASE_UPLOAD_ROUTE, component: Caseupload, title: CASE_UPLOAD_TITLE, userType: USER_TYPE_AGENT },
  {
    path: ORGLIST_ROUTE,
    component: OrganizationList,
    title: ADMIN_ORG_LIST_TITLE,
    userType: USER_TYPE_ADMIN
  },
  {
    path: ORG_DASHBOARD_ROUTE,
    component: Dashboard,
    title: ADMIN_ORG_LIST_TITLE,
    userType: USER_TYPE_ADMIN
  },
  {
    path: ORG_DETAILS_ROUTE,
    component: OrganizationDetail,
    title: ADMIN_ORG_DETAIL_TITLE,
    userType: USER_TYPE_ADMIN
  },
];

const publicRoutes = [
  {
    path: RESET_PASSWORD_ROUTE,
    component: ResetPasswordPage,
    title: RESET_PASSWORD_TITLE,
  },
  {
    path: FORGOT_PASSWORD_ROUTE,
    component: ForgotPasswordPage,
    title: FORGOT_PASSWORD_TITLE,
  },
  { path: SIGNIN_ROUTE, component: SignupPage, title: SIGNIN_TITLE },
  {
    path: ADMIN_RESET_PASSWORD_ROUTE,
    component: AdminResetPasswordPage,
    title: ADMIN_RESET_PASSWORD_TITLE,
  },
  {
    path: ADMIN_FORGOT_PASSWORD_ROUTE,
    component: AdminForgotPasswordPage,
    title: ADMIN_FORGOT_PASSWORD_TITLE,
  },
  {
    path: ADMIN_SIGNIN_ROUTE,
    component: AdminLoginPage,
    title: ADMIN_SIGNIN_TITLE,
  },
];

export { privateRoutes, publicRoutes };
