import React from "react";

function DashboardCard({ header, count }) {
  return (
    <div>
      <div className="flex flex-col p-5 w-full max-w-[400px] min-w-[280px] min-h-[100px] rounded-md shadow-sm shadow-black">
        <div className="my-1">
          <div className="flex items-start">
            <span className="text-black-600 text-[16px] text-black">
              {header}
            </span>
          </div>
        </div>
        <div className="py-1">
          <div className="flex items-start">
            <span className="text-[18px] text-black-600 text-black">
              {count}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard;
