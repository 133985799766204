import React from "react";
import ReactApexChart from "react-apexcharts";

const BarGraph = ({ caselist }) => {

  if (!caselist || caselist.length === 0) {
    return (
      <div style={{ textAlign: "center", padding: "50px", fontSize: "16px", color: "#777" }}>
        No Data Available
      </div>
    );
  }

  const categories = caselist.map((item) => `${item._id.day}`);
  const counts = caselist.map((item) => item.count);

  const series = [
    {
      name: "Case Count",
      data: counts,
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      stacked: true,
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    colors: ["#000000"],
    plotOptions: {
      bar: {
        borderRadius: 8,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  return (
    <div style={{ overflow: "scroll", width: '100%' }} role="apexchart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={300}
        width={caselist.length >= 28 ? 1800 : "100%"}
      />
    </div>
  );
};

export default BarGraph;
